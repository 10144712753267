.student_prof{
  .indicator-page {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .indicator-block {
    width: 100%;
    display: flex;
    gap: 24px;
    flex-direction: column;
  }

  .indicator-blk {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .indicator-blk h1 {margin-bottom: 0;}
  .indicator-blk h1:after {content: none;}

  .indicator-blk h1 span {
    background: url(./img/question-icon.svg) center no-repeat;
    width: 13px;
    height: 14px;
    margin-left: 4px;
    cursor: pointer;
  }

  .indicator-blk .tabs li {
    padding: 6px 16px;
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .indicator-blk .tabsmenu li.active {background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);}

  .indicator-blk .tabsmenu {
    border-bottom: none;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .indicator-blk .tabsmenu li.active:after {content: none;}

  /* Стили для переключателя */
  .switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #49A9BF;
    transition: .4s;
    border-radius: 16px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {background-color: #49A9BF;}
  input:checked + .slider:before {transform: translateX(18px);}

  .swich-blk {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 24px;
  }

  .swich-blk span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .chart-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .chart-blk {width: 50%;}

  .chart-blk span {
    color: var(--Grayscale-Main-text, #333);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    display: block;
    text-align: center;
    margin-bottom: 24px;
  }

  .chart-description {
    width: 100%;
  }

  .chart-description > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-radius: 16px;
    padding: 16px 24px;
    background: var(--Acents-White-Hover, linear-gradient(0deg, rgba(73, 169, 191, 0.20) 0%, rgba(73, 169, 191, 0.20) 100%), #FFF);
  }

  .chart-description #label, .chart-description #label2, .chart-description #label-right, .chart-description #label-right2 {
    color: var(--Grayscale-Dark-BG, #555);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }

  .chart-description #value, .chart-description #value2, .chart-description #value-right, .chart-description #value-right2 {
    color: var(--Grayscale-Dark-BG, #555);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: right;
  }

  .chart-description #value:after, .chart-description #value2:after, .chart-description #value-right:after, .chart-description #value-right2:after {
    content: "баллов";
    display: block;
    color: var(--Grayscale-Dark-BG, #555);
    font-size: 12px;
    letter-spacing: -0.078px;
  }
  .chblk3 .primary-description #value2:after{
    content:"балл"
  }

  .indicator-table h3 {
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 16px;
  }

  .indicator-table .tables th {
    padding: 9px 16px;
    height: auto;
  }

  .indicator-table .tables:not(:last-child) {margin-bottom: 24px;}
  .indicator-table .tables td:last-child {text-align: right;}

  .indicator-user {
    width: 37%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
  }

  .indicator-user-blk {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  .indicator-user-img {width: 100%;}

  .indicator-user-img img {
    border-radius: 8px;
    width: 100%;
  }

  .indicator-btn {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .indicator-user-adds {position: relative;}

  .indicator-user-adds a:before {
    content: "";
    background: var(--Grayscale-Main-text, #333) url(./img/indicator-add.svg) center no-repeat;
    border-radius: 8px;
    width: 56px;
    height: 56px;
    display: block;
  }

  .indicator-user-adds input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
    margin: 0;
  }

  .indicator-btn button {
    border-radius: 8px;
    background: var(--Acents-Main-acent, #49A9BF) url(./img/indicator-icon.svg) center no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    border: none;
  }

  .indicator-user-info {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .indicator-user-info .user-title {
    display: block;
    white-space: pre-line;
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin-bottom: 0;
  }

  .indicator-user-info ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .indicator-user-info ul li {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .indicator-user-info ul li span {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.374px;
  }

  .indicator-user-info .filter-blk ul {flex-direction: row;}
  .indicator-user-info .search-blk .filter-blk {gap: 12px;}

  .indicator-user-info .search-blk .filter-blk .tag li {
    color: var(--Acents-Main-acent, #49A9BF);
    border-radius: 8px;
    border: 1px solid var(--Acents-Main-acent, #49A9BF);
    background: none;
    padding: 6px 16px;
  }

  .indicator-user-info .filter-blk  .btn-close {background: url(./img/btn-close2.svg) center no-repeat;}

  .wallet-block {
    display: flex;
    flex-direction: column;
    color: var(--Grayscale-White, #FFF);
    border-radius: 16px;
    padding: 16px 24px;
    min-height: 200px;
    background: var(--Grayscale-Main-text, #333);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .wallet-block p {
    color: var(--Grayscale-White, #FFF);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  /*.wallet-block p span {
    background: url(./img/question-icon2.svg) center no-repeat;
    width: 13px;
    height: 14px;
    cursor: pointer;
  }*/

  .wallet-block a {
    color: var(--Grayscale-White, #FFF);
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
  }

  .wallet-block a:after {
    content: "";
    background: url(./img/wallet-icon.svg) no-repeat;
    width: 10px;
    height: 10px;
  }

  .wallet-block ul {
    margin-top: auto;
    align-items: flex-end;
  }

  .wallet-block ul li span {display: block;}

  .wallet-block ul li:first-child {
    font-size: 12px;
    line-height: normal;
    letter-spacing: -0.078px;
  }

  .wallet-block ul li:first-child span {
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
  }

  .wallet-block ul li:last-child {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.078px;
  }

  .wallet-block ul li:last-child span {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .career-icon6:before {background: url(./img/career-icon4.svg) center no-repeat;}
  .indicator-page .create-blk .title {margin-bottom: 0;}
  .career-title a {padding: 0;}

  #progress-bars-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .progress-label {
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .progress-bar {
    width: 100%;
    padding: 5px 0;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .progress {
    height: 8px;
    border-radius: 4px;
    background: var(--Grayscale-Main-text, #333);
    text-align: right;
    color: white;
    padding-right: 8px;
    box-sizing: border-box;
  }

  .progress-value {
    min-width: 35px;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .indicator-achievement .tables thead {display: none;}

  .indicator-achievement .tables td {padding: 8px 0;}

  .indicator-achievement .tables td:last-child {
    text-align: right;
    font-size: 32px;
    font-weight: 700;
  }

  .indicator-achievement .tables td div {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .indicator-achievement .tables td div span {
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #49A8BF;
  }

  .indicator-achievement .tables td div span img {max-width: 100%;}

  .indicator-achievement .tables td div p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .indicator-achievement .swich-blk {display: none;}




  .indicator-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .indicator-chart .chart-block {
    display: flex;
    width: 100%;
    position: relative;
    padding-left: 120px;
    justify-content: center;
  }

  .chart-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 67px;
    position: relative;
    z-index: 2;
    flex-wrap: wrap;
  }

  .chart-container .chart-blk span {
    margin: 20px 0 0;
  }

  .y-axis {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 200px;
    margin-top: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .level {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--Grayscale-Main-text, #333);
    font-family: Ubuntu;
    font-size: 12px;
    letter-spacing: -0.078px;
    height: 1px;
    width: 100%;
  }

  .level:after {
    content: '';
    position: absolute;
    left: 130px;
    width: calc(100% - 130px);
    border-bottom: 1px solid #E9ECEF;
  }

  .indicator-chart .chart-blk {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 42%;
  }

  .bar {
    width: 16px;
    border-radius: 10px;
    background-color: #49A9BF;
    cursor: pointer;
    margin-top: auto;
  }

  .barme.active .bar {
    background-color: #49A9BF66 !important;
  }

  .indicator-chart .chart-description {
    margin-top: 20px;
    width: calc(100% + 120px);
    margin-left: -120px;
  }

  .indicator-chart .chart-description > div#value2 {flex-direction: column;}

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    gap: 16px;
  }

  .bar-progress {
    border-radius: 10px;
    background: var(--Grayscale-Bg, #E9ECEF);
    height: 200px;
    display: flex;
    &:hover .bar{
      background: #49A9BF66 !important;
    }
  }

  .y-axis2 {
    display: none;
    bottom: calc(0px + 134px);
  }



  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (max-width: 1399px) {
    .chart {gap: 10px;}
    .chart-container {gap: 30px;}
  }

  @media (max-width: 1199px) {
    .indicator-block {width: 100%;}
    .indicator-user {width: 40%;}
    .wallet-block {padding: 16px;}
    .indicator-achievement .tables td div p {max-width: 60%;}
    .indicator-blk .tabs li:nth-child(1) {order: 1;}
    .indicator-blk .tabs li:nth-child(2) {order: 2;}
    .indicator-blk .tabs li:nth-child(3) {order: 4;}
    .indicator-blk .tabs li:nth-child(4) {order: 3;}
    .indicator-blk .tabs li:nth-child(5) {order: 5;}
    .chblk1 {order: 1;}
    .chblk2 {order: 3;}
    .chblk3 {order: 2;}
    .chblk4 {order: 4; display: flex !important;}
    .y-axis2 {display: flex;}
    .indicator-chart .chart-block {padding-left: 130px;}
    .chart {gap: 16px;}
    .indicator-chart .chart-blk {width: calc(100% - 150px); margin: 0 auto;}
    .indicator-chart .chart-description {margin-top: 0; width: calc(100% + 150px); margin-left: -150px;}
  }

  @media (max-width: 991px) {
    .indicator-page {flex-direction: column-reverse;}
    .indicator-user, .indicator-block {width: 100%;}
    .user-title br {display: none;}
    .indicator-user-info .search-blk .filter-blk {display: flex;}
    .indicator-achievement .tables td div p {max-width: 100%;}
  }

  @media (max-width: 767px) {
    .indicator-achievement .swich-blk {display: flex;}
    .indicator-achievement.active .tables thead {display: table-header-group;}
    .indicator-achievement.active .tables thead tr th:last-child {text-align: right;}
    .indicator-achievement.active .tables td {padding: 16px 0;}
    .indicator-achievement.active .tables thead tr th:last-child, .indicator-achievement.active .tables tbody tr td:last-child {padding-right: 8px;}
    .indicator-achievement.active .tables thead tr th:first-child, .indicator-achievement.active .tables tbody tr td:first-child {padding-left: 8px;}
    .indicator-achievement.active .tables td div span {display: none;}
    .indicator-achievement.active .tables td:last-child {font-size: 12px; font-weight: 400;}
  }

  @media (max-width: 575px) {
    .indic {margin-top: 56px;}
    .indicator-user {padding: 0; box-shadow: none;}
    .indicator-user-img {margin-top: -76px;}
    .indicator-user-blk {gap: 20px;}
    .indicator-blk h1 {text-transform: none;}
    .indicator-blk .tabsmenu {gap: 4px;}
    .indicator-blk .tabs li {padding: 4px 12px; font-size: 12px;}
    .chart-description #label, .chart-description #label2, .chart-description #label-right, .chart-description #label-right2 {font-size: 14px;}
    .indicator-user-adds a:before, .indicator-btn button {width: 48px; height: 48px;}
    .indicator-page .career-tag {display: none;}
    .indicator-page .career-block {padding: 16px 25px;}
    .indicator-page .career-title a {font-size: 12px;}
    .indicator-page .career-title .btn-arrow:after {width: 16px; height: 16px;}
    .swich-blk span {font-size: 14px;}
    .indicator-page .tables td {font-size: 12px;}
    .indicator-table .tables th {padding: 9px 8px;}
    .indicator-table .tables thead tr th:last-child, .indicator-table .tables tbody tr td:last-child {padding-right: 8px;}
    .indicator-table .tables thead tr th:first-child, .indicator-table .tables tbody tr td:first-child {padding-left: 8px;}
    .indicator-table .tables th span, .indicator-achievement.active .tables thead th span {display: block; margin-left: -100px; text-align: right;}
    #progress-bars-container {gap: 16px;}
    .indicator-achievement .tables td div span {width: 50px; min-width: 50px; height: 51px;}
    .indicator-achievement .tables td div p {font-size: 12px; max-width: 60%;}
    .indicator-achievement.active .tables td div p {max-width: 100%;}
    .chart-block {flex-direction: column;}
    .chart-blk {width: 100%;}
    .secondary-description {display: flex !important;}
    .blk1 {order: 1;}
    .blk2 {order: 3;}
    .blk3 {order: 2; margin-bottom: 24px;}
    .blk4 {order: 4;}
    .chart-blk span {text-align: left;}
    .chart {gap: 10px;}
    .indicator-chart .chart-block {padding-left: 120px;}
    .indicator-chart .chart-description {width: calc(100% + 120px); margin-left: -120px;}
  }

  @media (max-width: 374px) {
    .chart-description > div {padding: 16px;}
    .level span {font-size: 8px;}
    .indicator-chart .chart-block {padding-left: 75px;}
    .indicator-chart .chart-description {width: calc(100% + 75px); margin-left: -75px;}
    .level:after {width: calc(100% - 70px); left: 70px;}
  }
  .btn-close{
    width: 12px;
    height: 12px;
  }
  .wallet-block{
    min-height: auto;
    ul{
      flex-direction: row;
      margin-top: 30px;
    }
  }
}