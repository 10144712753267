@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.search {
  h2 {
    font-family: "Unbounded", sans-serif;
    font-size: 24px;
    margin: 0;
  }

  .top_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    cursor: pointer;

  }

  .search_block {
    border: 1px solid #DDDDDD;
    border-radius: 24px;
    padding: 32px;
    overflow: hidden;
  }

  .search_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;

    @media (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .field {
    margin-bottom: 20px;
  }

  .form_field {
    height: 40px;
  }

  .bottom_block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    & > * {
      cursor: pointer;
    }

    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  .collapse_block {
    .top_block {
      margin: 0;
    }
    .search_content, .bottom_block {
      display: none;
    }
  }
}
