@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
  color: $white;
  font-size: 17px;
  overflow: hidden;

  .header {
    position: fixed;
    z-index: 2;

    background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
    @include mediaMaxWidth(900px) {
      background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -146.99%, rgba(17, 124, 193, 0.8) -97.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid $white;

    &.collapsed_header {
      .logo {
        justify-content: center;
        width: 80px;
      }

      .logo span {
        display: none;
      }

      p::before {
        margin: 0;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    width: 320px;
    padding: 16px;
    border-right: 1px solid $white;
    text-transform: uppercase;
    user-select: none;
    transition: 0.2s all ease;

    p {
      margin: 0;
      cursor: pointer;

      span {
        font-family: 'Unbounded', sans-serif;
        font-weight: 500;
      }
    }

    p::before {
      @include pseudoIcon(inline-block, 2em, 2em, url("../../images/logo.svg"), contain, 0);
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  .exit {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    border-left: 1px solid $white;
    user-select: none;

    span {
      font-weight: 700;
      cursor: pointer;
    }

    .i-up_right {
      font-size: 0.7em;
      margin-left: 5px;
    }
  }

  .burger_menu {
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    border-left: 1px solid $white;
    cursor: pointer;
    display: none;

    span {
      position: relative;
      display: block;
      width: 2em;
      height: 2px;
      background-color: transparent;
      transition: 0.2s all ease;

      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        transition: 0.2s all ease;
      }

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }

  .collapsed_burger_menu {
    span {
      background-color: $white;
    }

    span::before {
      top: -0.6em;
      transform: rotate(0deg);
    }

    span::after {
      top: 0.6em;
      transform: rotate(0deg);
    }
  }

  .layout_body {
    position: relative;
    display: flex;
    flex-grow: 1;

    padding-top: 80px;
    @media screen and (max-width: 700px) {
      padding-top: 60px;
    }
  }

  .menu_block {
    position: relative;
    min-width: 320px;
    max-width: 320px;
    padding: 100px 20px 100px 0;
    transition: 0.2s all ease;
  }

  .menu_block.collapsed_menu {
    min-width: 80px;
    max-width: 80px;
    transition: 0.2s all ease;

    .menu_link {
      align-items: center;
    }

    .menu_link .icon {
      margin: 0;
    }

    .collapse .collapse_text {
      display: none;
    }

    .collapse::before {
      background-position: -6em 0;
    }

    .menu_title {
      display: none;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .menu_link {
    display: flex;
    position: relative;
    padding: 20px;
    border-radius: 0 8px 8px 0;
    transition: 0.2s all ease;

    .icon {
      margin-right: 20px;
      font-size: 1.2em;
    }

    .menu_title {
      font-weight: 700;
    }

    &.active {
      background-color: #333333;
    }
  }

  .profile_link::before {
    background-position: -4.8em 0;
  }

  .messenger_link::before {
    background-position: -3.6em 0;
  }

  .rating_link::before {
    background-position: -2.4em 0;
  }

  .collapse {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 70px;
    cursor: pointer;
    user-select: none;

    .icon {
      display: flex;
      margin-right: 20px;
    }
  }

  .exit_from_menu {
    padding: 20px 20px 0 20px;
    border: none;
    display: none;
  }

  .content_block {
    flex-grow: 1;
    background-color: $white;
    border-top-left-radius: 16px;
    padding: 48px;
  /*  margin-top: 32px;*/
    color: $dark_gray;
    z-index: 1;

    overflow: hidden;
  }


  @include mediaMaxWidth(1300px) {
    .logo {
      width: 250px
    }

    .menu_block {
      min-width: 250px;
      max-width: 250px;
    }
  }

  @include mediaMaxWidth(1200px) {
    font-size: 14px;

    .content_block {
      padding: 40px;
    }
  }

  @include mediaMaxWidth(1100px) {
    font-size: 12px;

    .logo {
      width: 200px
    }
    .menu_block {
      min-width: 200px;
      max-width: 200px;
    }
    .menu {
      font-size: 12px;
    }
  }

  @include mediaMaxWidth(1000px) {
    .content_block {
      padding: 32px;
    }
  }

  @include mediaMaxWidth(900px) {
    font-size: 15px;
    background: linear-gradient(168.12deg, rgba(40, 176, 154, 0.8) 5.7%, rgba(17, 124, 193, 0.8) 27.75%, rgba(25, 67, 118, 0.8) 48.45%), #FFFFFF;

    .logo {
      justify-content: center;
      width: 80px;

      span {
        display: none;
      }

      p::before {
        margin: 0;
      }
    }

    .exit_from_header {
      display: none;
    }

    .burger_menu {
      display: flex;
    }

    .layout_body {
      flex-direction: column;
    }

    .menu_block {
      min-width: 100%;
      padding: 20px 20px 0 0;
      max-height: 700px;
      padding-bottom: 25px;
    }

    .menu_block.collapsed_menu {
      min-width: 100%;
      max-width: 100%;
      max-height: 0;
      padding-top: 0;
      overflow: hidden;
      padding-bottom: 0;
      .menu_link {
        align-items: center;
      }

      .menu_link .icon {
        margin-right: 20px;
      }

      span {
        display: inline-block;
      }
    }

    .menu {
      font-size: 15px;
    }

    .collapse {
      display: none;
    }

    .exit_from_menu {
      display: block;
    }

    .content_block {
      border-top-right-radius: 16px;
    }
  }

  @include mediaMaxWidth(700px) {
    .header {
      height: 60px;
    }

    .logo, .header.collapsed_header .logo {
      width: 60px;
    }

    .burger_menu {
      width: 60px;
    }
  }

  @include mediaMaxWidth(600px) {
    .content_block {
      padding: 24px;
    }
  }

  @include mediaMaxWidth(400px) {
    font-size: 13px;

    .content_block {
      padding: 24px;
    }

    .menu {
      font-size: 13px;
    }
  }
}


