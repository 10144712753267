@import "../variables";

.bubble_button {
  display: block;

  .bubble_input {
    display: none;
  }

  .bubble_input:checked + .bubble_label {
    background-color: $turquoise;
    color: $white;
  }

  .bubble_label {
    position: relative;
    display: flex;
    background-color: $gray_background;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: $radius;
    cursor: pointer;
    box-sizing: border-box;
  }
}
