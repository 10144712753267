@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-family}.eot?y7946t');
  src:  url('#{$icomoon-font-family}.eot?y7946t#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-family}.ttf?y7946t') format('truetype'),
    url('#{$icomoon-font-family}.woff?y7946t') format('woff'),
    url('#{$icomoon-font-family}.svg?y7946t##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"]::before, [class*=" i-"]::before, [class^="i-"]::after, [class*=" i-"]::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-add-circle {
  &:before {
    content: $i-add-circle;
  }
}
.i-add {
  &:before {
    content: $i-add;
  }
}
.i-add_after {
  &:after {
    content: $i-add;
  }
}
.i-like {
  &:before {
    content: $i-like;
  }
}
.i-delete {
  &:before {
    content: $i-delete;
  }
}
.i-rating {
  &:before {
    content: $i-rating;
  }
}
.i-basket {
  &:before {
    content: $i-basket;
  }
}
.i-logo {
  &:before {
    content: $i-logo;
  }
}
.i-logo_after {
  &:after {
    content: $i-logo;
  }
}
.i-right_xs {
  &:before {
    content: $i-right_xs;
  }
}
.i-right_xs_after {
  &:after {
    content: $i-right_xs;
  }
}
.i-left_xs {
  &:before {
    content: $i-left_xs;
  }
}
.i-left_xs_after {
  &:after {
    content: $i-left_xs;
  }
}
.i-up_xs {
  &:before {
    content: $i-up_xs;
  }
}
.i-up_xs_after {
  &:after {
    content: $i-up_xs;
  }
}
.i-down_xs {
  &:before {
    content: $i-down_xs;
  }
}
.i-down_xs_after {
  &:after {
    content: $i-down_xs;
  }
}
.i-up {
  &:before {
    content: $i-up;
  }
}
.i-down {
  &:before {
    content: $i-down;
  }
}
.i-left {
  &:before {
    content: $i-left;
  }
}
.i-right {
  &:before {
    content: $i-right;
  }
}
.i-up_right {
  &:before {
    content: $i-up_right;
  }
}
.i-up_right_after {
  &:after {
    content: $i-up_right;
  }
}
.i-vk {
  &:before {
    content: $i-vk;
  }
}
.i-email {
  &:before {
    content: $i-email;
  }
}
.i-smile_ok {
  &:before {
    content: $i-smile_ok;
  }
}
.i-smile_sad {
  &:before {
    content: $i-smile_sad;
  }
}
.i-smile_joy {
  &:before {
    content: $i-smile_joy;
  }
}
.i-hidden_menu {
  &:before {
    content: $i-hidden_menu;
  }
}
.i-sort {
  &:before {
    content: $i-sort;
  }
}
.i-sort_after {
  &:after {
    content: $i-sort;
  }
}
.i-auction {
  &:before {
    content: $i-auction;
  }
}
.i-rates {
  &:before {
    content: $i-rates;
  }
}
.i-person {
  &:before {
    content: $i-person;
  }
}
.i-messenger {
  &:before {
    content: $i-messenger;
  }
}
.i-eye {
  background-image: url("../../icons/eye.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.i-cloud {
  width: 20px;
  height: 20px;
  background-image: url("../../icons/cloud.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.i-eye-slash {
  background-image: url("../../icons/eye-slash.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.i-star {
  &:before {
    content: $i-star;
  }
}
.i-hint {
  &:before {
    content: $i-hint;
  }
}
.i-filter {
  &:before {
    content: $i-filter;
  }
}
.i-file {
  &:before {
    content: $i-file;
  }
}
.i-emoji {
  &:before {
    content: $i-emoji;
  }
}
.i-add_files {
  &:before {
    content: $i-add_files;
  }
}
.i-telegram {
  &:before {
    content: $i-telegram;
  }
}
.i-users{
  &:before{
    content: url(../../icons/user.svg);
  }
}
.i-companies{
  width: 20px !important;
  &:before{
    content: url(../../icons/companies.svg);
  }
}
.i-career{
  &:before{
    content: url(../../icons/career.svg);
  }
}
.i-response-viewed{
  &:before{
    content: url(../../newpages/img/icon-types2.svg);
  }
}
.i-response-approved{
  &:before{
    content: url(../../newpages/img/icon-types1.svg);
  }
}
.i-response-consideration{
  &:before{
    content: url(../../newpages/img/response-consideration.svg);
  }
}
.i-edit {
  &:before {
    content: $i-edit;
  }
}
.i-menu {
  &:before {
    content: $i-menu;
  }
}
.i-search {
  &:before {
    content: $i-search;
  }
}
.i-close {
  &:before {
    content: $i-close;
  }
}
.i-download {
  &:before {
    content: $i-download;
  }
}
.i-upload {
  &:before {
    content: $i-upload;
  }
}
