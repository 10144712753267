@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.login {
  position: relative;
  display: flex;

  min-height: 100vh;
  background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
  font-size: 17px;

  .content_blocks {
    display: flex;
    position: relative;
    flex: 1;
    overflow-x: hidden;
    //display: flex;
    //flex-direction: column;
  }

  .header_block {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 80px;
    height: 80px;
    border-bottom: 1px solid $white;

    color: $white;
    //position: fixed;
    //top: 0;
    //background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
    //z-index: 100;


    .logo {
      width: 80px;
      height: 100%;
      border-right: 1px solid $white;
      background-image: url("../../images/logo.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
    }

    .test_label {
      padding-left: 10px;
    }

    .company_link {
      display: flex;
      align-items: center;
      height: 100%;
      border-left: 1px solid $white;
      padding: 0 32px;

      span {
        cursor: pointer;
      }

      .i-up_right {
        font-size: 0.7em;
        margin-left: 5px;
      }
    }
  }

  .promo_block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    min-height: 100%;
    color: $white;

    .header_block {
      display: flex;
    }

    .slider_news {
      max-width: 100%;
      padding: 50px 80px;
      height: 100%;
      word-wrap: break-word;
    }

    .carousel_item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //font-size: 72px;
      //font-weight: 700;
      text-transform: uppercase;
    }

    .news_item {
      h1, h2, h1 > *, h2 > * {
        font-family: "Unbounded", sans-serif;
        font-weight: 700;
      }
      h1 {
        font-size: 55px;
      }

      h2 {
        font-size: 35px;
      }
      @media screen and (max-width: 500px){
        h2{
          font-size: 6vw;
          white-space: break-space;
          margin-left: auto;
          margin-right: auto;
        }
        h1{
          font-size: 8vw;
          white-space: break-space;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .news_image {
        width: 100%;
        height: 30vh;
        position: relative;

        img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }

      & > *:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &.shrink {
      animation: shrink 0.5s ease;
    }
  }

  .promo_block {
    .links_from_form {
      display: none;
      color: $white;
      border-top: 1px solid $white;

      p {
        width: 100%;
        margin: 0;
        padding: 10px;
        text-align: center;
        align-self: center;
        font-size: 15px;
      }
    }

    .links_from_form > *:not(:last-child) {
      border-right: 1px solid $white;
    }
  }

  .forms_block {
    width: 580px;
  }

  .right_block {
    position: relative;
    display: flex;
    min-height: 100%;
    width: 100%;
  }

  .right_block > * {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    right: 0;
    overflow: hidden;
  }

  .for_students {
    &.hide .login_block {
      opacity: 0;
      animation: hide 0.5s ease;
    }
  }

  .for_companies {
    &.show {
      //right: 100%;
      z-index: 1;
      //animation: show_company_form_right 0.5s ease;
    }
  }

  @include mediaMaxWidth(1300px) {
    .promo_block {
      .carousel_item {
        //font-size: 65px;
      }
    }
  }

  @include mediaMaxWidth(1200px) {
    .promo_block {
      .slider_news {
        padding: 50px;
      }

      .carousel_item {
        //font-size: 55px;
      }
    }
  }

  @include mediaMaxWidth(1000px) {
    .promo_block {
      .carousel_item {
        //font-size: 50px;
      }
    }
  }

  @include mediaMaxWidth(900px) {
    flex-direction: column;

    padding-top: 80px;

    .content_blocks {
      flex-direction: column-reverse;
    }

    .header_block {
      display: flex;
      position: fixed;
      top: 0;
      background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
      z-index: 100;
    }

    .promo_block {
      width: 100%;

      &.shrink {
        animation: shrink_from_bottom 0.5s ease;
      }

      .links_from_form {
        display: flex;
      }
    }

    .forms_block {
      width: 100%;
    }

    .right_block > * {
      border-top-left-radius: 0;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .for_companies {
      bottom: 0;
      z-index: 1;

      &.show {
        animation: show_from_bottom 0.5s ease;
      }
    }
  }

  @include mediaMaxWidthAndPortrait(900px) {
    .content_blocks {
      flex-direction: column-reverse;
    }

    .promo_block {
      min-height: 45vh;
      max-height: 45vh;
      overflow-y: scroll;
    }

    .forms_block {
      position: relative;
      min-height: 55%;
      max-height: 55%;
      bottom: 0;
      animation: show_from_bottom 0.5s ease;
    }

    .right_block {
      height: 100vh;
    }

    .for_students {
      &.show {
        animation: show_from_bottom 0.5s ease;
      }
    }
  }

  @include mediaMaxHeightAndPortrait(900px) {
    overflow: hidden;

    .promo_block {
      .header_block {
        display: none;
      }
    }

    .header_block {
      display: flex;
      position: fixed;
      top: 0;
      background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
      z-index: 100;

      .test_label {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
      }
    }

    .promo_block {
      min-height: initial;
      max-height: initial;
      overflow-y: visible;

      animation: none !important;
    }

    .forms_block {
      position: relative;
      //height: 450px;
    }

    .right_block {
      height: auto;
    }

    .for_students {
      &.show {
        animation: show_from_bottom 0.5s ease;
      }
    }
  }

  @include mediaMaxWidth(800px) {
    font-size: 14px;

    padding-top: 60px;

    .header_block {
      min-height: 60px;
      height: 60px;

      .logo {
        width: 60px;
      }
    }

    .promo_block {
      .carousel_item {
        //font-size: 40px;
      }
    }
  }

  @include mediaMaxWidth(600px) {
    .promo_block {
      .slider_news {
        padding: 30px;
      }

      .carousel_item {
        //font-size: 35px;
      }

      .links_from_form p {
        font-size: 12px;
      }
    }
  }

  @include mediaMaxWidth(500px) {
    .promo_block {
      .slider_news {
        padding: 20px;
      }

      .carousel {

        .bottom_block {
          justify-content: center;
        }

        .dot {
          width: 7px;
          height: 7px;
        }

        .next_button {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &-content-title{
      margin-top: 50px !important;
      .btn-back{
        padding: 0 !important;
      }
      h1{
        align-self: center;
      }
    }
  }
}

@keyframes show_company_form_right {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}

@keyframes show_from_bottom {
  0% {
    bottom: -55%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 60%;
  }
}

@keyframes shrink_from_bottom {
  0% {
    min-height: 100vh;
    height: 100vh;
  }
  100% {
    min-height: 45vh;
    height: 45vh;
  }
}


