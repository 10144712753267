@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.start {
  color: $white;
  background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;
  overflow: hidden;

  .header {
    position: fixed;
    width: 100%;
    z-index: 2;

    background: linear-gradient(90.26deg, rgba(25, 67, 118, 0.8) -6.99%, rgba(17, 124, 193, 0.8) 27.27%, rgba(40, 176, 154, 0.8) 99.79%), #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid $white;

    .exit {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 24px;
      font-size: 16px;
      font-weight: 700;
      border-left: 1px solid $white;

      .i-up_right {
        font-size: 0.8em;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  .logo {
    display: flex;
    padding: 24px 50px 24px 24px;
    border-right: 1px solid $white;
    font-size: 18px;
    height: 100%;

    .logo_icon {
      display: inline-block;
      min-width: 1.7em;
      max-width: 1.7em;
      min-height: 1.7em;
      max-height: 1.7em;
      background-image: url("../../assets/images/logo.svg");
      background-size: contain;
      background-position: 0;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    p {
      display: flex;
      align-items: center;
      font-family: 'Unbounded', sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .container {
    width: 80%;
    margin: 0 auto;

    padding-top: 80px;

    & > * {
      margin-top: 96px;
    }
  }

  .present_block {
    display: flex;
    align-items: center;
    gap: 100px;

    .present_text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h1 {
        font-family: "Unbounded", sans-serif;
        font-size: 48px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      p {
        height: 100%;
        font-size: 22px;
        margin: 0;
      }

      .open_video_button {

        margin-top: 30px;

      }
    }

    .present_picture {
      flex-shrink: 0;
      width: 530px;
      height: 380px;
      background-image: url("./assets/svg/robot.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .users_block {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .user_item {
      width: 100%;
      font-size: 14px;
      border: 1px solid $white;
      border-radius: 16px;
      padding: 24px 40px;
      transition: 0.3s all ease;

      &:hover {
        background-color: $white;
        color: $dark_gray;

        .main_button {
          background-color: $turquoise;
          color: $white;
          &:hover{
            background-color: $turquoise_hover;
          }
        }
      }

      .full_text {
        transition: max-height 0.5s;
        max-height: 300px;

        height: 100%;

        overflow: hidden;
      }

      .full_text.hidden_text {
        max-height: 0;
      }
    }

    .header_item {
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .student_header::before {
      @include pseudoIcon(block, 120px, 100px, url("assets/svg/student_robot.svg"), cover, 0);
    }

    .company_header::before {
      @include pseudoIcon(block, 120px, 100px, url("assets/svg/company_robot.svg"), cover, 0);
    }

    p {
      margin-top: 8px;
      margin-bottom: 0;
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: 700;
      margin-top: 4px;
      cursor: pointer;

      &::before {
        font-size: 0.7em;
        margin-right: 6px;
      }
    }

    .main_button {
      margin-top: 16px;
      transition: 0.3s all ease;
    }
  }

  .partners {
    display: grid;
    /*grid-template-columns: 1fr 2fr;*/
    border-bottom: 1px solid $white;
    text-align: center;

    .grid_item {
      border-top: 1px solid $white;
      display: flex;
      justify-content: space-around;
      padding: 24px 0;
      gap: 24px;
    }

    .grid_item:nth-child(2n + 1) {
      border-right: 1px solid $white;
    }

    .icon_cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .partner_icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 200px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .platform_info {

    h2 {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 64px;
    }

    .tab_switches {
      display: flex;
      gap: 8px;

      span {
        display: inline-block;
        padding: 16px 40px 14px 32px;
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        border-right: 1px solid $white;
        border-radius: 16px 16px 0 0;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.2s all ease;

        &::before {
          @include pseudoIcon(inline-block, 2em, 2em, url("./assets/svg/start_page_icons.svg"), cover, 0 0);
          vertical-align: middle;
          margin-right: 10px;
        }

        &.active_tab {
          background-color: $white;
          color: $dark_gray;
        }

        &.coin::before {
          background-position: -2em 0;
        }

        &.rating::before {
          background-position: -4em 0;
        }

        &.messenger::before {
          background-position: -6em 0;
        }
      }
    }

    .tab_content {
      display: flex;
      gap: 64px;
      justify-content: space-between;
      padding: 40px;
      background-color: $white;
      color: $dark_gray;
      border-radius: 0 16px 16px 16px;

      .text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 20px;

        h3 {
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .picture {
        position: relative;
        min-width: 500px;
        width: 500px;
        height: 400px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

      }
    }

    .carousel {
      display: none;
    }
  }

  .partners_system {
    .line {
      border-top: 1px solid $white;
    }

    .line:last-child {
      border-bottom: 1px solid $white;
    }

    .partners_title {
      text-align: center;
      font-weight: 500;
      padding: 24px 0;
    }

    .partners_carousel {
      padding: 24px 0;

      .carousel {
        overflow: visible;

        .carousel_items_block {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .carousel_item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            max-width: 400px;


            img {
              width: 90%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .control-dots {
          bottom: -45px;
        }
      }
    }

    .child_item {
      max-height: 100px;
      padding: 0 15px;
    }

  }

  .feedback_block {
    display: flex;
    gap: 80px;
    background-color: $white;
    color: $dark_gray;
    padding: 64px;
    border-radius: 16px;

    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }

    .turquoise_link {
      color: $turquoise;
    }

    .feedback_hint {
      h2 {
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .feedback_picture {
        max-width: 100%;
        height: 400px;
        background-image: url("./assets/images/feedback_picture.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 32px;
      }
    }

    .feedback_form {
      padding-top: 32px;

      & > *:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .start_use_block {
    position: relative;
    background-color: $white;
    color: $dark_gray;
    padding: 40px;
    margin-bottom: 150px;
    border-radius: 16px;
    text-align: center;

    h2 {
      font-size: 32px;
      text-transform: uppercase;
      width: 500px;
      margin: 0 auto 24px auto;
    }

    .white_block {
      display: none;
      position: absolute;
      top: 0;
      right: 14px;
      z-index: 2;
      width: 50px;
      height: 30px;
      background-color: $white;
    }

    &::before, &::after {
      content: "";
      width: 300px;
      height: 300px;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      background-image: url("./assets/svg/left_image_start.svg");
      left: 0;
      bottom: -147px;
    }

    &::after {
      background-image: url("./assets/svg/right_image_start.svg");
      right: -48px;
      top: -50px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    height: 80px;
    margin-top: 50px;
    border-top: 1px solid $white;

    .footer_content {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      padding: 24px 32px;

      p, a {
        font-size: 14px;
        opacity: 0.7;
      }
    }

    .policy_column {
      display: none;
    }

    .policy {
      cursor: pointer;
    }

    .social {

      display: flex;
      gap: 16px;

      a {
        display: flex;
        align-items: center;
        background-color: $white;
        color: $turquoise;
        font-size: 1.5em;
        padding: 8px;
        border-radius: $radius;
        cursor: pointer;
      }
    }
  }

  @include mediaMaxWidth(1400px) {
    .present_block {
      gap: 75px;

      .present_text {
        h1 {
          font-size: 40px;
        }
      }

      .present_picture {
        width: 450px;
        height: 330px;
      }
    }
    .feedback_block {
      .feedback_hint {
        .feedback_picture {
          height: 300px;
        }
      }
    }
  }

  @include mediaMaxWidth(1200px) {
    .present_block {
      gap: 50px;
      margin-top: 75px;

      .present_text {
        h1 {
          font-size: 35px;
        }
      }

      .present_picture {
        width: 400px;
        height: 330px;
      }
    }
    .users_block {
      margin-top: 75px;

      .header_item {
        gap: 15px;
        font-size: 18px;

        &::before {
          min-width: 90px;
          max-width: 90px;
          min-height: 75px;
          max-height: 75px;
        }
      }
    }
    .platform_info {
      margin-top: 75px;

      h2 {
        font-size: 30px;
        margin-bottom: 50px;
      }

      .tab_switches {
        font-size: 14px;

        span {
          padding: 12px 25px 10px 20px;
        }
      }

      .tab_content {
        .picture {
          min-width: 400px;
          width: 400px;
          height: 320px;
        }
      }
    }
    .start_use_block {
      h2 {
        font-size: 30px;
      }

      &::before, &::after {
        width: 220px;
        height: 220px;
      }

      &::before {
        bottom: -108px;
      }

      &::after {
        top: -40px;
        right: -35px;
      }
    }
  }

  @include mediaMaxWidth(1000px) {
    .container {
      width: 90%;
    }
    .present_block {
      gap: 30px;
      margin-top: 50px;

      .present_text {
        h1 {
          font-size: 30px;
        }

        p {
          font-size: 18px;
        }
      }

      .present_picture {
        width: 330px;
        height: 240px;
      }
    }
    .users_block {
      .user_item {
        padding: 20px;
      }
    }
    .partners {
      .partner_icon {
        width: 160px;
        height: 40px;
      }
    }
    .platform_info {
      h2 {
        font-size: 25px;
        margin-bottom: 30px;
      }

      .tab_switches {
        font-size: 12px;
      }

      .tab_content {
        .text {
          span {
            font-size: 12px;
          }
        }

        .picture {
          min-width: 350px;
          width: 350px;
          height: 280px;
        }
      }
    }
    .feedback_block {
      gap: 25px;
      padding: 40px;
      font-size: 14px;

      .feedback_hint {
        h2 {
          font-size: 25px;
        }

        .feedback_picture {
          height: 270px;
        }
      }
    }
  }

  @include mediaMaxWidth(800px) {
    .start_use_block {
      h2 {
        font-size: 20px;
        max-width: 300px;
      }

      .white_block {
        display: block;
      }

      &::before, &::after {
        width: 180px;
        height: 180px;
      }

      &::before {
        bottom: -89px;
      }

      &::after {
        transform: rotate(-21deg);
        top: -20px;
        right: -15px;
      }
    }
    .platform_info {
      .tab_switches {
        span::before {
          margin-right: 4px;
        }
      }
    }
  }

  @include mediaMaxWidth(750px) {
    .logo {
      padding: 24px 32px 24px 24px;
    }
    .present_block {
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;

      .present_picture {
        width: 400px;
        height: 330px;
      }
    }
    .users_block {
      margin-top: 20px;

      .header_item {
        gap: 5px;
        font-size: 14px;

        &::before {
          min-width: 75px;
          max-width: 75px;
          min-height: 62px;
          max-height: 62px;
        }
      }

      .user_item {
        padding: 18px;

        p {
          font-size: 12px;
        }

        button {
          font-size: 16px;
        }
      }
    }
    .partners {
      margin-top: 75px;

      .grid_item {
        padding: 20px 0;
      }

      .partner_icon {
        width: 140px;
        height: 35px;
      }
    }
    .platform_info {
      .tab_switches, .tab_content_with_switches {
        display: none;

      }

      .carousel {
        display: block;
      }

      .tab_content {
        border-radius: 16px;
        gap: 20px;

        .picture {
          min-width: 250px;
          width: 250px;
          height: 200px;
        }
      }

      .window {
        background-color: $white;
        border-radius: 16px;
      }

      .child_item {
        justify-content: flex-start;
      }
    }
    .partners_system {
      margin-top: 75px;
    }
    .feedback_block {
      flex-direction: column;
      margin-top: 75px;

      .feedback_hint {
        .feedback_picture {
          height: 270px;
        }
      }

      .feedback_form {
        padding-top: 0;

        .secondary_button {
          width: 100%;
        }
      }
    }
    .footer {
      .policy_column {
        display: block;
      }

      .row {
        display: none;
      }
    }
  }

  @include mediaMaxWidth(600px) {
    background: linear-gradient(135deg, rgba(40, 176, 154, 0.80) 5.31%, rgba(17, 124, 193, 0.80) 68.54%, rgba(25, 67, 118, 0.80) 100%);
    .header {
      height: 66px;
      background: linear-gradient(135deg, rgba(40, 176, 154, 0.80) 5.31%, rgba(17, 124, 193, 0.80) 68.54%, rgba(25, 67, 118, 0.80) 100%);

      .exit {
        padding: 18px 24px;
      }
    }
    .logo {
      padding: 18px;
      align-items: center;

      .logo_icon {
        margin: 0;
      }

      p {
        display: none;
      }
    }
    .users_block {
      flex-direction: column;
      margin-top: 10px;

      .header_item {
        gap: 20px;
        font-size: 18px;

        &::before {
          min-width: 90px;
          max-width: 90px;
          min-height: 75px;
          max-height: 75px;
        }
      }

      .user_item {
        padding: 24px;

        p {
          font-size: 14px;
        }
      }
    }
    .partners {
      grid-template-columns: 1fr;
      grid-template-areas: "created_by" "polytech_cell" "in_partnership" "partners_cell";
      border-bottom: none;

      .created_by {
        grid-area: created_by;
      }

      .polytech_cell {
        grid-area: polytech_cell;
        padding: 30px 0;
      }

      .in_partnership {
        grid-area: in_partnership;
      }

      .partners_cell {
        grid-area: partners_cell;
        flex-direction: column;
        padding: 30px 0;
      }

      .grid_item:nth-child(2n + 1) {
        border-right: none;
      }

      .partner_icon {
        width: 240px;
        height: 60px;
      }
    }
    .platform_info {
      .tab_content {
        flex-direction: column;
      }
    }
    .partners_system {
      .line {
        padding: 20px 0;
      }
    }
    .feedback_block {
      .feedback_hint {
        .feedback_picture {
          height: 230px;
        }
      }

      .feedback_form > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .start_use_block {
      padding: 100px 20px;
      margin-bottom: 80px;

      h2 {
        width: auto;
      }

      &::before, &::after {
        width: 150px;
        height: 150px;
      }

      &::before {
        bottom: -73px;
      }

      &::after {
        right: 0;
      }
    }
    .footer {
      .footer_content {
        padding: 18px;
        p {
          font-size: 12px;
        }

        .social {
          gap: 10px;
        }
      }
    }
  }

  @include mediaMaxWidth(450px) {
    .header {
      height: 60px;

      .exit {
        font-size: 14px;
        padding: 15px 24px;
      }
    }
    .logo {
      padding: 15px;
    }
    .present_block {
      margin-top: 70px;

      .present_text {
        h1 {
          font-size: 25px;
        }

        p {
          font-size: 14px;
        }
      }

      .present_picture {
        width: 280px;
        height: 200px;
      }
    }
    .users_block {
      margin-top: 50px;

      .header_item {
        font-size: 16px;
        gap: 10px;

        &::before {
          min-width: 75px;
          max-width: 75px;
          min-height: 62px;
          max-height: 62px;
        }
      }

      .user_item {
        padding: 20px;
      }
    }
    .platform_info {
      .tab_content {
        padding: 30px;
      }
    }
    .carousel {
      .bottom_block {
        font-size: 12px;

        .dot {
          width: 6px;
          height: 6px;
        }

        .dots > *:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
    .feedback_block {
      padding: 24px;

      .feedback_hint {
        .feedback_picture {
          height: 180px;
        }
      }
    }
  }

  @include mediaMaxWidth(400px) {
    .platform_info {
      .tab_content {
        .picture {
          min-width: 200px;
          width: 200px;
          height: 160px;
        }
      }
    }
  }

  .test_label {
    padding-left: 10px;
  }

  @include mediaMaxHeightAndPortrait(900px) {
    .test_label {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
  }
}

.video_modal {
  .MuiPaper-root {
    max-height: fit-content;
    max-width: fit-content;
    margin: 0;

    background-color: transparent;
  }

  .MuiDialogContent-root {
    padding: 0;
    max-width: 100%;

    display: flex;
    box-sizing: border-box;
  }
}
