@import "variables";

@mixin pseudoIcon ($display, $width, $height, $image, $size, $position) {
  content: '';
  @include icon($display, $width, $height, $image, $size, $position);
}

@mixin icon ($display, $width, $height, $image, $size, $position) {
  display: $display;
  min-width: $width;
  max-width: $width;
  min-height: $height;
  max-height: $height;
  background-image: $image;
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
}

@mixin mediaMinWidth ($min_width) {
  @media (min-width: $min_width) {
    @content
  }
}

@mixin mediaMaxWidth ($max_width) {
  @media (max-width: $max_width) {
    @content
  }
}

@mixin mediaMaxWidthAndPortrait($max_width) {
  @media (max-width: $max_width) and (orientation: portrait) {
    @content
  }
}

@mixin mediaMaxHeightAndPortrait($max_height) {
  @media (max-height: $max_height) and (orientation: portrait) {
    @content
  }
}

@mixin tooltip() {
  .tooltip {
    width: 250px;
    background-color: $gray_background;
    color: $dark_gray;
    opacity: 1;
    font-size: 13px;
    border-radius: $radius;
    z-index: 100;
  }
}
