@import "../variables";

.loader {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;

  .loader_body {
    display: flex;
    align-items: center;
    gap: 0.7em;
    color: $gray_text;
    font-weight: 500;

    &::before {
      content: "";
      display: inline-block;
      border: 3px solid $turquoise;
      border-radius: 50%;
      border-top: 3px solid $white;
      width: 1.5em;
      height: 1.5em;
      animation: spin 2s linear infinite;
      opacity: 1;
      transition: opacity 2s ease;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loader2 {
  height: 60%;
  aspect-ratio: 1/1;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation2 1s linear infinite;
}

@keyframes rotation2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}