@import "../variables";
@import "../mixins";

.input_file {
  .label_block {
    display: block;
    margin-bottom: 5px;
  }

  .input-file .load_block {
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
    color: $dark_gray;
    border: 1px dashed $dark_gray;
    border-radius: 20px;
    padding: 15px 20px;
    cursor: pointer;
    transition: 0.2s all ease;

    .i-download::before {
      font-size: 1.3em;
      vertical-align: middle;
    }
  }

  .input-file:hover .load_block {
    opacity: 0.8;
  }

  .input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
}
