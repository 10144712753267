@import "../variables";
@import "../mixins";

.profile {
  .grid_item {
    padding: 20px 24px;
    border-radius: 16px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .tooltip_title {
    display: inline-block;
    cursor: pointer;

    .i-hint {
      font-size: 0.9em;
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  .line_block {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .align_center {
    align-items: center;
  }

  .align_bottom {
    align-items: flex-end;
  }

  .to_page {
    width: max-content;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    color: inherit;
    white-space: nowrap;

    .i-up_right {
      font-size: 0.7em;
      margin-left: 5px;
    }
  }

  .card_button {
    height: max-content;
    font-size: 12px;
    padding: 8px 16px;
    border: none;
    border-radius: $radius;
    font-weight: 700;
    background-color: $white;
    cursor: pointer;
  }

  .profile_card .top_block {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    &-buttons{
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .avatar {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .professions > * {
      margin-bottom: 5px;
      margin-right: 8px;
  }

  @include mediaMaxWidth(1200px) {

  }
}
