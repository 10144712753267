@import "../variables";
@import "../mixins";

.modal_custom {
  .modal_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 24px;

    .title {
      font-size: 25px;
      font-weight: 700;
      line-height: 1;
    }

    .close_button {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .modal_content {
    min-width: 80vw;
    max-width: 80vw;
    min-height: 30vh;
    overflow-x: hidden;
    padding: 24px;
  }

  @include mediaMaxWidth(600px) {
    .modal_title {
      .title {
        font-size: 18px;
      }
    }
  }

  @include mediaMaxHeightAndPortrait(900px) {
    .MuiPaper-root {
      width: 100%;
      height: 100%;
      max-height: calc(100% - 10px);
      max-width: calc(100% - 10px);
      margin: 0;
    }
    .modal_title {
      padding: 10px;
    }

    .modal_content {
      padding: 0;
      max-width: 100%;
    }
  }
}
