/*
----------------------
Styles for Tarif
----------------------
*/
.table-tarif, .table-tarif table {
  width: 100%;
}

.table-tarif table {border-collapse: collapse;}

.table-tarif thead tr {
  border-radius: 8px 8px 0px 0px;
  background: var(--Grayscale-Bg, #E9ECEF);
}

.table-tarif thead tr th {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.374px;
  padding: 25px 16px;
  text-align: center;
}

.table-tarif tbody td {
  border-bottom: 1px solid var(--Grayscale-Border, #CED4DA);
  width: auto !important;
  text-align: center;
  padding: 16px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.078px;
}

.tarif-block {
  gap: 24px;
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.tarif-blk {
  min-height: 450px;
  flex: 1;
  padding: 24px;
  border-radius: 16px;
  color: var(--Grayscale-White, #FFF);
  background: var(--Grayscale-Main-text, #333);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
}

.tarif-blk.active {
  border-radius: 16px;
  background: linear-gradient(192deg, rgba(25, 67, 118, 0.80) -85.83%, rgba(17, 124, 193, 0.80) -25.77%, rgba(40, 176, 154, 0.80) 101.39%);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
}

.tarif-title {
  color: var(--Grayscale-White, #FFF);
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.tarif-title p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.078px;
  color: var(--Grayscale-Bg, #E9ECEF);
}

.tarif-blk ul {
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 12px;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.tarif-blk ul li {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.078px;
  gap: 8px;
}

.tarif-blk ul li:before {
  content: "";
  background: url(../../newpages/img/tarif-icon.svg) no-repeat;
  width: 14px;
  height: 10px;
}

.tarif-price {margin-top: auto;}

.tarif-price span {
  color: var(--Grayscale-White, #FFF);
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.tarif-price p {
  color: var(--Grayscale-White, #FFF);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.tarif-blk button {
  border-radius: 8px;
  background: var(--Grayscale-White, #FFF);
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  border: none;
  width: 100%;
}

.table-tarif .question {display: none;}

/*
--------------------
Styles for Adaptive
--------------------
*/
@media (max-width: 1199px) {
  .tarif-block {flex-wrap: wrap;}
  .tarif-blk {min-width: 40%;}
}

@media (max-width: 991px) {
  .table-tarif {border-radius: 16px; padding: 16px 24px; background: var(--Grayscale-White, #FFF); box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);}
  .table-tarif:before {content: "Информация"; display: block; margin-bottom: 24px; font-size: 16px; font-weight: 700; line-height: normal; letter-spacing: 0.374px;}
  .table-tarif table thead {display: none;}
  .table-tarif tbody  {display: flex; flex-direction: column; gap: 16px;}
  .table-tarif tbody td {display: block; width: 100%; border: none; padding: 0;text-align: left!important;}
  .table-tarif td[data-label]:before {content: attr(data-label); font-weight: 500; margin-right: 5px;}

}

@media (max-width: 767px) {
  .tarif-blk {min-width: 100%; flex: 1; min-height: auto;}
  .content-block {padding: 24px 20px;}
  .tarif-blk ul {margin-bottom: 48px;}
  .tarif-title {font-size: 24px; margin-bottom: 32px; flex: none;}
  .content-block {width: 100%; border-radius: 0;}
  .modal-content {padding: 24px 20px; width: 100%; height: 100%; border-radius: 0;}
  .modal-content .close {display: none;}
  .modal {background: var(--Gradients-Gradient-mobile, linear-gradient(168deg, rgba(40, 176, 154, 0.80) 5.7%, rgba(17, 124, 193, 0.80) 27.75%, rgba(25, 67, 118, 0.80) 48.45%), #FFF);}
  .modal .table-btn {flex-direction: column-reverse; gap: 8px; margin-top: auto;}
  .modal {flex-direction: column; overflow: auto; align-items: flex-start;}
  .modal h2 {color: var(--Grayscale-White, #FFF); font-size: 16px; padding: 20px 24px; display: flex; align-items: center; gap: 10px;}
  .modal h2:before {content: ""; background: url(../../newpages/img/back-arrow.svg) center / contain no-repeat; width: 17px; height: 15px; filter: brightness(0) invert(1);}
  .modal-content {max-height: 100vh;  max-width: 100%;}
}

@media (max-width: 575px) {
  .tarif-price p, .tarif-blk button {font-size: 12px;}
  .tarif-blk button {padding: 8px;}
  .tab-question {display: none;}
  .table-tarif {position: relative;}
  .table-tarif .question {display: block; top: 16px; right: 24px; transform: none;}
  .table-tarif td[data-label]:before {display: block; margin: 0 0 4px;}
  .btn-back {padding: 22px 24px; color: #fff; text-decoration: none;}
  .btn-back:before {filter: brightness(0) invert(1);}
}

@media (max-width: 374px) {}
