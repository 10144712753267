.pdf-content{
  width: 100%;
  .download-file-pdf{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.pdf-container{
 width: 100%;
  overflow: auto;


}

.pdf{
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

  width: 400mm;
  min-height: 500mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: "ubuntu", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }

  .container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .flex-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .blks {
    border-radius: 16px;
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  .unstyled {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  p {margin: 0;}
  .blks p {font-weight: 300;}

  /*
  --------------------
  Styles for Header
  --------------------
  */
  header {padding: 30px 0;}

  .employer {
    border-radius: 8px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-right: auto;
  }

  .header-title {
    color: #333;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    max-width: 500px;
    margin-left: auto;
    margin-right: 50px;
  }

  .header-title span {font-weight: 500;}

  /*
  --------------------
  Styles for Content
  --------------------
  */
  main {margin-bottom: 50px;}

  .info-block {
    display: flex;
    align-items: flex-start;
    gap: 19px;
  }

  .info-block2 {align-items: stretch;}

  .indicator-user-block {
    gap: 27px;
    width: 33%;
  }

  .indicator-user-info {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .indicator-user-info .user-title {
    display: block;
    white-space: pre-line;
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin-bottom: 0;
  }

  .indicator-user-info ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .indicator-user-info ul li {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
    padding-bottom: 4px;
  }

  .indicator-user-info ul li span {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.374px;
  }

  .tag-blk {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .tag-blk span {
    display: block;
    color: var(--Grayscale-Main-text, #333);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .indicator-user-info .tag-choice {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px 8px
  }

  .indicator-user-info .tag-choice li {
    padding: 6px 16px;
    border-radius: 8px;
    color: #FFF;
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .btn-close {
    content: "";
    background: url(../img/btn-close.svg) center no-repeat;
    width: 10px;
    height: 9px;
    border: none;
  }

  .info-blk {
    width: 66%;
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
  }

  .info-blk .blks {width: calc(50% - 10px);}
  .info-blk .blks:last-child {width: 100%;}

  .achievement-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .achievement-blk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #CED4DA;
  }

  .achievement-blk .image {
    width: 50px;
    min-width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #49A8BF;
  }

  .achievement-blk .image img {max-width: 100%;}

  .achievement-blk p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
    max-width: 180px;
    margin-right: auto;
  }

  .achievement-blk span:last-child {
    text-align: right;
    font-size: 32px;
    font-weight: 700;
  }

  #progress-bars-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .progress-label {
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .progress-bar {
    width: 100%;
    padding: 5px 0;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .progress {
    height: 8px;
    border-radius: 4px;
    background: var(--Grayscale-Main-text, #333);
    text-align: right;
    color: white;
    padding-right: 8px;
    box-sizing: border-box;
  }

  .progress-value {
    min-width: 35px;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .tables {
    border-collapse: collapse;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
  }

  .tables thead tr {
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .tables thead th {
    padding: 9px 16px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.374px;
    text-align: left;
  }

  .tables thead th:first-child {border-radius: 8px 0 0 0;}
  .tables thead th:last-child {border-radius: 0 8px 0 0;}

  .tables tbody td {
    border-bottom: 1px solid #CED4DA;
    padding: 16px;
  }

  .tables thead th:last-child,
  .tables tbody td:last-child {text-align: right;}

  .dpo-table tr:last-child td {
    border-bottom: none;
    padding-bottom: 0;
  }

  .dpo-table {font-size: 12px;}
  .dpo-table thead th {padding: 8px;}
  .dpo-table tbody td {padding: 16px 8px;}
  .tables-competencies tbody td {padding: 9px 8px;}

  .table-blk {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .table-blk span {
    color: var(--Grayscale-Main-text, #333);
    font-feature-settings: 'liga' off, 'clig' off;
    font-weight: 500;
    letter-spacing: 0.374px;
    display: block;
  }

  .chart-blk {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .chart-blk span {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }



  .blks{
    border: 1px solid #AEAEAE;
  }
}