@import "../variables";
.search_line {
  position: relative;
  display: flex;
  align-items: center;

  .form_field {
    width: 100%;
    padding-left: 3em;
  }

  .search_icon {
    position: absolute;
    left: 1em;
    color: $gray_text;
  }
}
