@import "../variables";
@import "../mixins";

.profile_student {
  @include tooltip;
  .student-mess{
   display: flex;
    height: 50px;
  }
  .profile_content {
    display: grid;
  /*"activity competencies profile"
  "feedback feedback profile"*/
    grid-template-areas:
          "indicators indicators profile"
          "career career profile";
    grid-template-columns: 3fr 4fr 4fr;
    grid-template-rows: 0fr 0fr 0fr;

    gap: 24px;
    font-size: 14px;

    .indicators{
      grid-area: indicators;
      height: auto;
    }

    .wallet {
      min-width: 225px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $dark_gray;
      color: $white;
      min-height: 170px;
      grid-area: wallet;
      font-size: 12px;

      .to_page::after {
        background-position: -10.8em 0;
      }

      h2, p {
        margin: 0;
      }

      .all_points {
        display: inline-block;
        color: $gray_text;
      }

      p span, .all_points span {
        display: block;
        font-weight: 700;
        line-height: 1.1;
      }

      p span {
        font-size: 40px;
      }

      .all_points span {
        font-size: 16px;
      }
    }

    .activity {
      display: flex;
      flex-direction: column;
      grid-area: activity;

      .chart_block {
        .bar_chart {
          margin-top: 24px;
        }
      }
    }

    .competencies {
      min-width: 300px;
      min-height: 770px;
      display: flex;
      flex-direction: column;
      grid-area: competencies;

      .representation_competencies {
        margin-top: 16px;

        & > * {
          display: inline-block;
          padding-bottom: 8px;
          width: 50%;
          border-bottom: 2px solid $gray_border;
          cursor: pointer;
        }
      }

      .active_representation {
        font-weight: 500;
        border-bottom: 3px solid $dark_gray;
      }

      .chart_block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        flex-grow: 1;

        .chart {
          max-height: 400px;
          margin: 40px auto 0 auto;
        }

        canvas {
          max-width: 100%;
        }
      }

      .competencies_list {
        & > *:not(:last-child) {
          margin-bottom: 10px;
        }

        li {
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }

        li::before {
          content: attr(data-id);
          font-family: "Unbounded", sans-serif;
          font-weight: 700;
          min-width: 20px;
        }
      }

      .table_block {
        font-size: 12px;
        flex-grow: 1;
        margin-top: 20px;
      }

      .hidden {
        display: none;
      }
    }

    .profile_card {
      min-width: 300px;
      grid-area: profile;

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      h1 {
        font-size: 24px;
        margin-top: 24px;
      }

      .professions {
        margin-top: 5px;
      }

      .student_info {
        margin-top: 24px;

        & > *:not(:last-child) {
          margin-bottom: 20px;
        }

        b {
          font-weight: 500;
        }

        p {
          margin: 0;
          line-height: 1.7;
          font-size: 16px;
        }
      }

      .main_button {
        margin-top: 24px;
      }
    }

    .feedback {
      grid-area: feedback;

      .buttons {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        gap: 8px;

        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
        }
      }

      .main_button .i-up_right {
        font-size: 0.7em;
        margin-left: 7px;
      }
    }
    .career {
      grid-area: career;

      .buttons {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        gap: 8px;

        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
        }
      }

      .main_button .i-up_right {
        font-size: 0.7em;
        margin-left: 7px;
      }
    }
  }


  .to_login {
    p {
      font-size: 25px;
    }

    .main_button {
      color: $white;
      background-color: $turquoise;
    }
  }

  @include mediaMaxWidth(1200px) {
    h2 {
      font-size: 14px;
    }

    .grid_item {
      padding: 16px 20px;
    }

    .profile_content {
      .wallet {
        min-width: 190px;
      }

      .competencies {
        min-width: 250px;

        .chart_block {
          .chart {
            margin-bottom: 0;
          }
        }
      }

      .profile_card {
        min-width: 250px;
      }
    }
  }

  @include mediaMaxWidth(1100px) {
    .profile_content {
      grid-template-rows: 0fr 0fr 0fr;
      grid-template-areas:
          "indicators profile"
          "career profile";
      /*grid-template-areas:
          "wallet  profile"
          "competencies profile"
          "activity feedback";*/
      //grid-template-areas: none;
      grid-template-columns: 1fr 1fr;
    }
  }

  @include mediaMaxWidth(700px) {
    .profile_content {
      grid-template-rows: 0fr 0fr 0fr;
      grid-template-areas:
          "profile"
          "indicators"
          "career";
      /*grid-template-areas:
          "profile"
          "feedback"
          "competencies"
          "wallet"
          "activity";*/
      grid-template-columns: 1fr;
        .indicators{
          height: max-content;
        }
      .career{
        height: max-content;
      }
      .profile_card {
        box-shadow: none;
        padding: 0;
        border-radius: 0;
      }
    }
  }
}
