@import "../variables";

.competencies_table {
  font-size: 14px;

  .competence_item {
    padding: 24px;
    border: 1px solid $gray_border;
    border-radius: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .description {
    margin-top: 5px;
    line-height: 1.2;
  }

  .achievements {
    margin-top: 20px;

    li {
      padding-left: 15px;
    }

    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .more_link_wrapper {
    text-align: right;
  }
}
