.carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .window {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .items_container {
    height: 100%;
    display: flex;
    transition: 0.5s all ease-in-out;
  }

  .child_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .bottom_block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    color: #FFFFFF;

    &.hide {
      display: none;
    }
  }

  .dots {
    display: flex;
  }

  .dots > *:not(:last-child) {
    margin-right: 7px;
  }

  .dot {
    width: 12px;
    height: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: none !important;
    &.active {
      background-color: #FFFFFF;
    }
  }

  .move_button {
    display: flex;
    gap: 7px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;

    span {
      display: flex;
      align-self: center;
    }
  }
}
