@import "../variables";
@import "../mixins";

.messenger {
  .container {
    display: flex;
    border-radius: 16px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .left_block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 320px;
    max-width: 320px;
    border-right: 1px solid $gray_border;
    /*height: 100%;*/

    & > *:not(.message_list) {
      padding: 16px;
    }

  }

  .filter_block {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid $gray_border;

  }

  .form_field {
    height: 48px;
    width: 100%;
  }

  .button {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }

  .message_list {
    /*flex-grow: 1;
    height: 55vh;*/
    height: 100%;
    flex: 1;
    overflow-y: auto;

    ul > *:not(:last-child) {
      border-bottom: 1px solid $gray_border;
    }
  }

  .message {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    gap: 12px;
    padding: 12px 16px;
    cursor: pointer;

    .avatar {
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;
      border-radius: $radius;
      overflow: hidden;
      background-color: $gray_background;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .top_block {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .status {
      min-width: 8px;
      min-height: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $turquoise;
    }

    .time {
      display: block;
      position: absolute;
      bottom: 12px;
      background-color: inherit;
      color: $gray_text;
      padding: 2px;
      border-radius: $radius;
      right: 16px;
    }
  }

  .active_chat {
    background-color: $gray_background;
  }

  .message_info {
    width: 100%;

    p {
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .author {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      -webkit-line-clamp: 2;
    }

    .message_content {
      color: $gray_text;
      width: 60%;
      -webkit-line-clamp: 1;
    }
  }

  .empty_list {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $gray_text;
    height: 100%;
  }

  .admin_message {
    background-color: $dark_gray;
    color: $white;
    border-top-left-radius: 16px;
    padding: 16px;

    .avatar {
      background-color: $turquoise;
      background-image: url("../../images/logo.svg");
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .right_block {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .header_dialog {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 24px;
      height: 80px;

      p {
        margin: 0;
      }

      .name_to {
        font-weight: 500;
        font-size: 18px;
      }

      .hidden_menu {
        cursor: pointer;
      }
    }
  }



  .dialog_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $gray_background;
    padding: 24px;
    overflow-y: auto;
    height: 55vh;

    ul {
      margin-top: auto;
      display: flex;
      flex-direction: column-reverse;
    }

    ul > *:not(:last-child) {
      margin-top: 8px;
      //margin-bottom: 8px;
    }

    .message_item {
      display: flex;

      p {
        max-width: 80%;
        background-color: $white;
        padding: 12px 16px;
        border-radius: $radius;
        font-size: 14px;
        line-height: 1.2;
        margin: 0;
        word-wrap: break-word;
      }
    }

    .my_message {
      justify-content: flex-end;
      p {
        background-color: $turquoise;
        color: $white;
      }
    }

  }

  .input_message {
    display: flex;
    gap: 14px;
    padding: 16px 24px 16px 24px;
    textarea{
      max-height: 100px !important;
    }
  }

  .loading {
    display:inline-block;
    font-family: monospace;
    font-size: 16px;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
  }

  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }

  @media (max-width: 800px) {
    .message_list {
      height: auto;
      overflow-y: visible;

    }

    .container {
      display: flex;
      flex-direction: column;

      .left_block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 100%;


      }

      .right_block.hidden {
        display: none;
      }

      .admin_message {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
