
.newStyle {

  /*
--------------------
Connecting fonts
--------------------
*/
  @font-face {
    font-family: "ubuntu";
    src: local("Ubuntu"),
    url(fonts/ubuntu.woff2) format('woff2'),
    url(fonts/ubuntu.woff) format('woff');
  }

  @font-face {
    font-family: "ubuntu";
    src: local("Ubuntu"),
    url(fonts/ubuntu-medium.woff2) format('woff2'),
    url(fonts/ubuntu-medium.woff) format('woff');
    font-weight: 500;
  }

  @font-face {
    font-family: "ubuntu";
    src: local("Ubuntu"),
    url(fonts/ubuntu-bold.woff2) format('woff2'),
    url(fonts/ubuntu-bold.woff) format('woff');
    font-weight: 700;
  }

  /*
  --------------------
  Base styles
  --------------------
  */
  body {
    font-family: "ubuntu", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);
  }

  input:not([type=checkbox]):not([type=radio]),
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input, textarea, a:focus, input:focus, button:active, button:focus, select:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  button::-moz-focus-inner {
    border: 0 !important;
  }

  * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  /*
  ---------------------------
  General styles for Content
  ---------------------------
  */
  .content_block {
    border-radius: 16px 0px 0px 16px;
    background: var(--Grayscale-White, #FFF);
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    font-feature-settings: 'clig' off, 'liga' off;
    padding: 48px;
    width: 77%;
    font-size: 14px;
    color: var(--Grayscale-Main-text, #333);
    float: right;
  }

  .content_block h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin: 0 0 12px;
  }

  ul.unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .content_block h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin: 0 0 20px;
  }

  .content_block p {
    color: var(--Grayscale-Main-text, #333);
    line-height: 1.2;
    letter-spacing: -0.078px;
    margin: 0 0 15px;
  }

  .content_block a {
    text-decoration: none;
  }

  .btn-back {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: 500px) {
      padding: 0 !important;
    }

    &__white {
      filter: invert(48%) sepia(0%) saturate(2476%) hue-rotate(86deg) brightness(200%) contrast(100%);
    }
  }

  .btn-back:before {
    content: "";
    background: url(../../assets/newpages/img/back-arrow.svg) center no-repeat;
    width: 32px;
    height: 32px;
  }

  .row-block {
    width: 100%;
    align-items: flex-start;
    display: flex;
    gap: 16px;
  }

  .col-block {
    flex-basis: 49%;
    gap: 24px;
    display: flex;
    max-width: 48%;
    flex-direction: column;
  }

  .blks {
    border-radius: 16px;
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
  }

  .blks h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin: 0 0 16px;
  }

  .btn-block {
    margin-top: 64px;
    display: flex;
    align-items: stretch;
    gap: 16px;
  }

  .btn {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 14px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-grey {
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .btn-arrow {
    padding: 10px 15px;
  }

  .btn-arrow:after {
    content: "";
    background: url(../../assets/newpages/img/btn-arrow.svg) center no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }


  .btn-blue {
    color: #ffffff;
    border: 2px solid var(--Acents-Main-acent, #49A9BF);
    background: var(--Acents-Main-acent, #49A9BF);
    font-weight: 700;
  }

  .btn-red {
    background-color: rgba(189, 69, 103, 1);
    border: 2px solid rgba(189, 69, 103, 1);
  }

  .btn-black {
    color: #ffffff;
    border: 2px solid var(--Grayscale-Main-text, #333);
    background: var(--Grayscale-Main-text, #333);
  }

  /*
  ------------------------------
  Styles for Vacancy - Student
  ------------------------------
  */
  .vacancy-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    gap: 10px;

    &__student {
      flex-direction: column;

      h4 {
        margin: 0;
      }
    }
  }


  .vacancy-title p {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
    margin-bottom: 0;
  }

  .vacancy-title span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
    padding: 4px 12px;
    display: block;
    border-radius: 8px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .details ul {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .details ul li {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(74, 170, 191, 0.8);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .details ul li:before {
    content: "";
    width: 16px;
    height: 16px;
  }

  .time:before {
    background: url(../../assets/newpages/img/icon-time.svg) center no-repeat;
  }

  .city:before {
    background: url(../../assets/newpages/img/icon-city.svg) center no-repeat;
  }

  .place:before {
    background: url(../../assets/newpages/img/icon-place.svg) center no-repeat;
  }

  .btn-block a:first-child {
    width: 60%;
  }

  .btn-block a:last-child {
    width: 40%;
  }

  .salary p {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 500;
    line-height: normal;
  }

  .competencies {
    margin-top: auto;
  }

  .tag {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .filter-blk .tag {
    gap: 8px;
  }

  .tag li {
    border-radius: 8px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .tag li a, .filter-blk .tag li, .tags li {
    font-size: 14px;
    color: var(--Grayscale-Main-text, #333);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    display: block;
    padding: 6px 16px;
  }

  .vacancy-student .vacancy-content p {
    word-wrap: anywhere;

  }

  .vacancy-content {
    height: 100%;
  }

  .vacancy-content p:last-child {
    margin-bottom: 0;
  }

  .tags li {
    background: #E9ECEF;
  }

  .tags li.active {
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  /*
  ----------------------
  Styles for Internship
  ----------------------
  */
  .tab-block {
    width: 100%;
    margin-bottom: 20px
  }

  .tab-block ul {
    display: flex;
    align-items: center;
    gap: 32px;
    border-bottom: 2px solid #CED4DA;
  }

  .tab-block ul li {
    font-size: 16px;
    padding-bottom: 8px;
  }

  .tab-block ul li.active {
    border-bottom: 3px solid #333;
    margin-bottom: -2.5px;
    font-weight: 500;
  }

  .offers-list {
    gap: 40px;
  }

  .choise-close:after {
    transform: rotateZ(180deg);
  }

  .choise-open:after {
    transform: rotateY(0deg);
  }

  .offer-list-block {
    width: 64%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .filter-block {
    width: 32%;
    gap: 10px;

    .filter-content {
      display: flex;
      flex-direction: column;
      gap: 32px;

      input, .custom_select, .tabs {
        font-size: 14px !important;
      }
    }

    display: flex;
    flex-direction: column;
  }

  .filter-block > div, .filter-btn button {
    width: 100%;
  }

  .filter-block h2 {
    margin: 14px 0;
  }

  .filter-blk {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .filter-title {
    font-size: 25px;
  }

  .filter-blk .title {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .filter-blk .choise {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .filter-blk .choise:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .3s ease-in-out;
  }

  .search-block-offer .collapse:after {
    transition: transform .3s ease-in-out;
  }

  .collapse-close {
    overflow: hidden;
    height: 20px;
  }

  .collapse .collapse-choise-close:after {
    transform: rotateZ(180deg);
  }

  .collapse .collapse-choise:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .3s ease-in-out;
  }

  .collapse-close {
    padding: 0px 20px !important;
    box-sizing: border-box;
    overflow: hidden;
    height: 60px;
  }

  .collapse-close .collaspe-choise-close:after {
    transform: rotateZ(180deg);
  }


  .filter-blk .tag-choice {
    display: none;
  }

  .filter-blk .tag-choice.active {
    display: flex;
  }


  .filter-blk .tag-active li {
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .filter-blk .tag-active li.active {
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-start {
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }

    &-col {
      flex-direction: column;
    }

    &-row {
      flex-direction: row;
    }

    &-between {
      justify-content: space-between;
    }
  }


  /* checkbox */
  .checks {
    display: flex;
    align-items: center;
  }

  .checkbox {
    display: none;
  }

  .checkbox-custom {
    position: relative;
    min-width: 20px;
    border-radius: 4px;
    border: 2px solid var(--Grayscale-Secondary-text, #ADB5BD);
    width: 22px;
    height: 22px;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }

  .checkbox:checked + .checkbox-custom::before {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 12px;
  }

  .checks .label {
    margin-left: 13px;
    text-align: left;
    font-size: 16px;
  }

  .choise-date {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .choise-date .flex {
    gap: 16px;
  }

  .choise-date-blk {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 16px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 50%;
  }

  .choise-date-blk label {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 18px;
  }

  .choise-date-blk input {
    color: #333333;
    border: none;
    background: none;
  }

  .choise-date-blk input::-webkit-calendar-picker-indicator {
    background: none;
  }

  .search {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 8px;
    background: #E9ECEF;
    border-radius: 8px;
    width: 100%;
  }

  .search input {
    border: none;
    padding: 20px 16px;
    background: transparent;
    line-height: 1.2;
  }

  .search input::-webkit-input-placeholder {
    color: #ADB5BD;
  }

  .search input:-moz-placeholder {
    color: #ADB5BD;
  }

  .search input::-moz-placeholder {
    color: #ADB5BD;
  }

  .search input:-ms-input-placeholder {
    color: #ADB5BD;
  }

  .search button {
    border-radius: 0px 8px 8px 0px;
    background: var(--Acents-Main-acent, #49A9BF) url(../../assets/newpages/img/search.svg) center no-repeat;
    padding: 16px;
    border: none;
    min-height: 100%;
    width: 56px;
    line-height: 1.2;
  }

  .btn-close {
    content: "";
    background: url(../../assets/newpages/img/btn-close.svg) center no-repeat;
    width: 10px;
    height: 9px;
    margin-left: 10px;
    vertical-align: middle;
    border: none;
  }

  .filter-btn {
    gap: 8px;
    display: flex;
    flex-direction: row;
   height: 50px;
    width: 100%;
    button{
      flex:1;
    }
  }

  .sorting span {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .sorting span:after {
    content: "";
    background: url(../../assets/newpages/img/icon-sorting.svg) center no-repeat;
    width: 32px;
    height: 32px;
  }

  .sorting.active span:after {
    transform: scaleY(-1);
  }

  .offer-content_block {
    border-radius: 8px;
    padding: 16px 24px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .offer-content_block .title {
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0.374px;
    display: block;
    margin-bottom: 8px !important;
  }

  .offer-content_block h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 10px !important;
  }

  .offer-content_block .details {
    margin-top: auto;
  }

  .date:before {
    background: url(../../assets/newpages/img/icon-date.svg) center no-repeat;
  }

  .wrap-block {
    flex-wrap: wrap;
    margin: 0 -0px;
    gap: 16px 8px;
    justify-content: space-between;
    align-items: stretch;
  }

  .wrap-block .col-block {
    padding: 0 0px;
  }


  /*
  -----------------------------
  Styles for page - Edit Offer
  -----------------------------
  */
  .tariff {
    border-radius: 8px;
    background: var(--Grayscale-Main-text, #333);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
    color: var(--Grayscale-White, #FFF);
  }

  .tariff h1 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.374px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .tariff span {
    color: var(--Grayscale-Bg, #E9ECEF);
    font-size: 12px;
    letter-spacing: -0.078px;
  }

  .tariff .btn {
    padding: 14px 32px;
    font-weight: 700;
  }

  .edit-profile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .edit-profile .tag li {
    cursor: pointer;
  }

  .table {
    border-collapse: collapse;
    border-radius: 8px 8px 0px 0px;

    .mob-add {
      border: 1px solid red;
    }
  }

  .table thead tr {
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .table thead tr th:first-child {
    border-radius: 8px 0 0 0;
    padding-left: 16px;
  }

  .table thead tr th:last-child {
    border-radius: 0 8px 0 0;
    padding-right: 16px;
  }

  .table thead tr th {
    padding: 12px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
    text-align: left;
  }

  .table thead tr th.add span, .table span.add {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 2px solid var(--Grayscale-Main-text, #333);
    background: var(--Grayscale-Main-text, #333) url(../../assets/newpages/img/icon-add.svg) center no-repeat;
  }

  .table span.add {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .table span.add {
      z-index: 2;

      a {
        width: 100%;
        height: 100%;
        display: block;
      }

      display: block;
    }
  }

  .table tbody tr {
    display: none;
  }

  .table tbody tr.active {
    display: table-row;
  }

  .table tbody ul {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .table tbody ul li {
    display: flex;
    align-items: center;
  }

  .table .edit {
    background: url(../../assets/newpages/img/icon-edit.svg) center no-repeat;
    width: 14px;
    height: 14px;
  }

  .table .delete {
    background: url(../../assets/newpages/img/icon-delete.svg) center no-repeat;
    width: 12px;
    height: 14px;
  }

  .table td {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
    vertical-align: middle;
    padding: 16px 0;
    border-bottom: 1px solid #CED4DA;
  }

  .table td.status span {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .table td.status span:before {
    content: "";
    width: 14px;
    height: 12px;
  }

  .table td .type1:before {
    background: url(../../assets/newpages/img/icon-type1.svg) center no-repeat;
  }

  .table td .type2:before {
    background: url(../../assets/newpages/img/icon-type2.svg) center no-repeat;
  }

  .table td .type3:before {
    background: url(../../assets/newpages/img/icon-type3.svg) center no-repeat;
  }

  .td-tag span {
    color: var(--Grayscale-Main-text, #333);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.374px;
    display: inline-block;
    border-radius: 8px;
    padding: 4px 12px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .table td .btn {
    font-size: 12px;
    padding: 0;
  }

  .arhiv td:nth-child(n+2):nth-child(-n+4) {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
  }

  .table-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 32px;
  }

  .table-btn button {
    padding: 14px 32px
  }

  .create-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .create-blk {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .create-blk .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    margin-bottom: 8px;
    display: block;
  }

  .create-status .title {
    margin-bottom: 0;
  }

  .create-status {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .create-status span.status {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    padding-left: 10px !important;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
  }

  .create-status_ span.status {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
  }

  .create-status span.status:before {
    content: "";
    background: url(../../assets/newpages/img/icon-type2.svg) center no-repeat;
    width: 10px;
    height: 12px;
  }

  .create-block .table-btn {
    justify-content: flex-end;
    width: 100%;
  }

  .create-blk {
    max-width: 650px;
  }

  .create-blk textarea, .create-blk input {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 14px 16px;
    width: 100%;
    border: none;
  }

  .create-blk textarea {
    min-height: 130px;
    resize: none;
  }

  .input-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .input-blk {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .input-blk label {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .input-blk input {
    padding: 19px 16px;
  }

  .create-choise-blk {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .create-choise-blk .title {
    min-width: 115px;
  }

  .create-select {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 30px 10px 16px;
    min-height: 44px;
  }

  .select-block {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .create-select:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 14px;
    right: 16px;
    background-size: 12px;
    z-index: 1;
  }

  .create-choise-blk .tag-choice {
    order: 1;
  }

  .select-blk li {
    display: none;
  }

  .select-blk li:first-child {
    display: block;
  }

  .select-blk.active li {
    display: block;
  }

  .select-block {
    order: 2;
    position: relative;
  }

  .select-blk.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    z-index: 99;
    background: #fff;
  }

  .select-blk li {
    display: none;
    order: 2;
  }

  .select-blk.active li {
    padding: 13px 16px 14px;
  }

  .select-blk li:first-child {
    display: block;
    position: relative;
    order: 1;
    background: var(--Grayscale-Bg, #E9ECEF);
    border-bottom: none;
    border-radius: 8px;
  }

  .select-blk li:not(:last-child) {
    border-bottom: 1px solid #EBF1FF;
  }

  .create-all .select-blk {
    display: none;
  }

  .select-blk.active {
    display: flex;
    margin: -22px -16px;
    width: calc(100% + 32px);
  }

  .create-all .select-blk.active {
    margin: 8px -16px 0;
  }

  .create-select .tag li {
    padding: 4px 12px;
  }


  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (min-width: 1200px) and (max-width: 1399px) {
    .content_block {
      padding: 40px;
    }
    .blks {
      padding: 20px;
    }
    .btn {
      font-size: 14px;
    }
    .btn-arrow {
      padding: 10px;
    }
    .btn-arrow:after {
      margin-left: 0;
    }
    .offer-content_block {
      min-height: 180px;
    }
    .filter-blk .tag li {
      padding: 6px 10px;
    }
    .btn-close {
      margin-left: 0;
    }
    .choise-date-blk label {
      font-size: 14px;
    }
    .choise-date .flex {
      gap: 10px;
    }
    .choise-date-blk input::-webkit-calendar-picker-indicator {
      display: none;
    }
    .choise-date-blk {
      gap: 5px;
    }
  }

  @media (max-width: 1199px) {
    .content_block {
      padding: 30px;
    }
    .blks {
      padding: 20px;
    }
    .salary p {
      font-size: 32px;
    }
    .vacancy-title p, .blks h2 {
      font-size: 14px;
    }
    .content_block h1 {
      font-size: 18px;
    }
    .btn-block {
      flex-direction: column;
    }
    .btn-block a:first-child, .btn-block a:last-child {
      width: 100%;
    }
    .tag li a {
      padding: 6px;
      font-size: 12px;
    }
    .wrap-block .col-block {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%
    }
    .offers-list {
      gap: 20px;
    }
    .filter-blk .tag li {
      font-size: 12px;
      padding: 6px 10px;
    }
    .btn-close {
      margin-left: 5px;
    }
    .filter-block {
      width: 35%;
    }
    .search input {
      padding: 16px;
    }
  }

  @media (max-width: 991px) {
    .content_block {
      width: 70%;
      gap: 20px;
    }
    .row-block {
      flex-direction: column;
    }
    .col-block {
      width: 100%;
      max-width: 100%;
      flex: 1;
    }
    .btn-block {
      flex-direction: row;
    }
    .btn-block a:first-child {
      width: 60%;
    }
    .btn-block a:last-child {
      width: 40%;
    }
    .btn-back {
      font-size: 16px;
    }
    .offer-list-block, .filter-block {
      width: 100%;
    }
    .filter-block {
      order: 1;
    }
    .offer-list-block {
      order: 2;
    }
    .wrap-block {
      margin: 0;
    }
    .wrap-block .col-block {
      padding: 0;
    }
    .filter-blk, .filter-btn {
      display: none;
    }
    .filter-block.active .filter-blk, .filter-block.active .filter-btn, .filter_block.active .filter-blk {
      display: flex;
    }
    .filter-block h2 {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    .filter-block h2:after {
      content: "";
      background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
      width: 12px;
      height: 8px;
    }
    .tab-edit ul li {
      font-size: 12px;
    }
    .table thead {
      display: none;
    }
    .table tbody tr.active {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .table-profile td {
      border: none;
      font-size: 14px;
    }
    .table-profile td:nth-child(1) {
      width: 10%;
      order: 1;
    }
    .table-profile td:nth-child(2) {
      width: 45%;
      order: 2;
    }
    .table-profile td:nth-child(6) {
      width: 25%;
      order: 3;
    }
    .table-profile td:nth-child(7) {
      width: 20%;
      order: 4;
    }
    .table-profile td:nth-child(n+3):nth-child(-n+4) {
      order: 5;
      padding-top: 0;
    }
    .table-profile td:nth-child(n+3):nth-child(-n+4):before {
      content: attr(data-label);
      font-weight: 500;
      margin-right: 5px;
    }
    .table-profile td:nth-child(5) {
      width: 20%;
      order: 6;
      margin-left: auto;
      padding-top: 0;
    }
    .table-profile tr {
      border-bottom: 1px solid #CED4DA;
    }
    .table-profile td:nth-child(3) {
      margin-right: 20px;
    }
    .table-btn button {
      padding: 10px 20px;
    }
    .create-block .table-btn button {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    .content_block {
      width: 100%;
      border-radius: 0;
    }
    .btn-block {
      flex-direction: column;
    }
    .btn-block a:first-child, .btn-block a:last-child {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .content_block {
      padding: 20px;
    }
    .vacancy-title {
      flex-direction: column-reverse;
    }
    .details ul {
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }
    .offer-content_block .details ul {
      flex-direction: row;
    }
    .tab-block ul {
      gap: 20px;
      overflow: auto;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-bottom: none;
    }
    .tab-block:after {
      content: "";
      width: 100%;
      height: 2px;
      background: #CED4DA;
      position: absolute;
      bottom: 0.5px;
      z-index: 1;
    }
    .tab-block ul li {
      font-size: 14px;
    }
    .tab-block ul li.active {
      margin-bottom: 0;
      position: relative;
      z-index: 2;
    }
    .tab-block {
      position: relative;
    }
    .search input {
      padding: 12px;
    }
    .offer-content_block {
      min-height: 160px;
    }
    .table-profile td:nth-child(1) {
      width: 15%;
    }
    .table-profile td:nth-child(2) {
      width: 55%;
    }
    .table-profile td:nth-child(6) {
      width: 30%;
      order: 3;
    }
    .table-profile td:nth-child(7) {
      width: 100%;
      order: 6;
      padding-top: 0;
    }
    .table-profile td:nth-child(n+3):nth-child(-n+4) {
      order: 4;
      width: 32%;
    }
    .table-profile td:nth-child(5) {
      width: 30%;
      order: 5;
      margin-left: 0;
      padding-top: 0;
    }
    .table td .btn {
      justify-content: flex-start;
    }
    .tariff {
      padding: 15px;
    }
    .content_block h1 {
      font-size: 16px;
      padding-right: 20px;
    }
    .tariff .btn {
      padding: 10px 15px;
      font-size: 14px;
    }
    .create-choise-blk {
      align-items: flex-start;
      gap: 8px;
      flex-direction: column;
    }
    .create-block .table-btn {
      flex-direction: column;
    }
    .input-blk input {
      padding: 16px;
    }
  }

  @media (max-width: 374px) {
    .tab-block ul {
      gap: 15px;
    }
    .table-profile td:nth-child(1) {
      width: 20%;
    }
    .table-profile td:nth-child(2) {
      width: 80%;
    }
    .table-profile td:nth-child(6) {
      width: 45%;
      order: 4;
      padding-top: 0;
    }
    .table-profile td:nth-child(n+3):nth-child(-n+4) {
      order: 3;
      width: 45%;
    }
    .table-profile td:nth-child(5) {
      width: 50%;
    }
  }

  .tabs {
    user-select: none;

  }

  .vacancy-student {
    width: 100%;
  }


  body.lock {
    overflow: hidden;
  }

  .career-block {
    width: 100%;
  }

  .career-title {
    margin-bottom: 19px;
  }

  .career-title h2 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .career-block-offer {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .career-offer-title {
    display: flex;
    gap: 4px;
    flex-direction: column;
  }

  .career-offer-title p {
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    margin-bottom: 0;
  }

  .career-offer-title span {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    line-height: normal;
    letter-spacing: -0.078px;
  }

  .career-offer-title span {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .career-icon1:before {
    background: url(../../assets/newpages/img/career-icon1.svg) center no-repeat;
  }

  .career-icon2:before {
    background: url(../../assets/newpages/img/career-icon2.svg) center no-repeat;
  }

  .career-icon3:before {
    background: url(../../assets/newpages/img/career-icon3.svg) center no-repeat;
  }

  .career-icon4:before {
    background: url(../../assets/newpages/img/icon-type2.svg) center no-repeat;
  }

  .career-icon5:before {
    background: url(../../assets/newpages/img/icon-type1.svg) center no-repeat;
  }

  .career-offer-title span:before {
    content: "";
    width: 16px;
    height: 14px;
  }

  .career-tag, .tegs {
    display: inline-block;
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.374px;
    border-radius: 8px;
    padding: 4px 12px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  .table-responses td img {
    width: 64px;
    height: 64px;
    object-fit: cover;
  }

  .td-responses {
    transition: opacity 0.5s ease;
    display: flex;
    align-items: center;
    gap: 0px;

    &:hover {
      .number {
        max-width: 100px;
        opacity: 1;
        padding-right: 1px;
      }

    }

    img {
      margin-right: 16px;
    }

    .number {
      margin-right: 10px;
      white-space: nowrap;
      font-size: 30px;
      font-weight: 700;
      color: #ADB5BD;
      transition: max-width 2s, opacity 1s;
      max-width: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  .td-responses > div {
    flex-direction: column;
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }

  .td-responses span {
    overflow: hidden;
    color: var(--Grayscale-Main-text, #333);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.078px;
  }

  .td-responses p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }

  .types1 {
    background: url(../../assets/newpages/img/icon-types1.svg) no-repeat;
  }

  .types2 {
    background: url(../../assets/newpages/img/icon-types2.svg) no-repeat;
  }

  .types3 {
    background: url(../../assets/newpages/img/icon-types3.svg) no-repeat;
  }

  .types4 {
    background: url(../../assets/newpages/img/icon-types4.svg) no-repeat;
  }

  .td-more {
    background: url(../../assets/newpages/img/icon-more.svg) center no-repeat;
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
  }

  .table tbody ul.td-more-info {
    display: none;
  }

  .table tbody ul.td-more-info.active {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    border-radius: 8px;
    border: 1px solid var(--Grayscale-Border, #DDD);
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 16px 12px;
    z-index: 10;
    right: 0;
    top: 70px;
  }

  .table tbody ul.td-more-info a {
    color: var(--Grayscale-Main-text, #333);
    font-size: 13px;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: -0.078px;
  }

  .table-responses td.status {
    position: relative;
    padding-left: 20px;
  }

  .table td .btn-confirmed {
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    background: var(--Grayscale-Bg, #E9ECEF);
    color: var(--Grayscale-Border, #CED4DA);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
  }

  .table-responses .status .btn {
    padding: 8px 16px;
  }

  .table-responses td.status span {
    width: 125px;
  }

  .table-responses .status .flex {
    gap: 16px;
  }

  .table td p {
    margin-bottom: 0;
  }


  /*
  --------------------
  Styles for Modal
  --------------------
  */
  .modal-link {
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .modal-block {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 32px 40px;
    z-index: 1001;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
    gap: 32px;
    font-size: 18px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-header h3 {
    margin: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .modal-body p {
    margin: 0 0 15px;
  }

  .modal-body p:last-child {
    margin: 0;
  }

  .modal-footer {
    text-align: right;
  }

  .modal-block .btn-close {
    background: url(../../assets/newpages/img/modal-close.svg) center / contain no-repeat;
    width: 40px;
    height: 41px;
    padding: 0;
    cursor: pointer;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  .modal-footer a, .modal-footer button {
    padding: 14px 32px;
  }

  .modal-footer button {
    font-weight: 500;
    cursor: pointer;
  }

  .modal-footer a {
    text-decoration: none;
  }

  .responses-blk {
    background: #ffffff;
    color: #333333;
  }

  .responses-blk span {
    color: #333333;
  }

  .table {
    width: 100%;
  }

  .table-student .avatar {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 16px;
    border: 1px solid #EFEFEF;
  }

  .table-student td:nth-child(2) {
    color: var(--Grayscale-Main-text, #333);
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .table-student td.status {
    padding-left: 0;
  }

  .table-student .status a.btn {
    padding: 0;
  }

  .table-student td:nth-child(1) {
    width: 55%;
  }

  .table-student td:nth-child(2) {
    width: 15%;
  }

  .table-student td:nth-child(3) {
    width: 30%;
  }

  .table-prfl .btn {
    justify-content: flex-start;
  }

  .search-block-offer {
    width: 100%;
    border-radius: 16px;
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .search-block-offer h1 {
    margin-bottom: 0;
  }

  .search-block-offer form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .search-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px !important;
  }

  .search-btn button {
    padding: 14px 32px;
  }

  .search-btn .btn-reset {
    border: none;
    background: none;
    font-weight: 700;
  }

  .search-blk {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 48%;
  }

  .search-blk label {
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .search-blk input {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .search-block-offer .collapse {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .search-block-offer .collapse:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 12px;
    height: 8px;
  }

  .search-block-offer.hidden form {
    display: none;
  }

  .list-company-block {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .list-company-block .col-block {
    width: calc(50% - 1px);
    cursor: pointer;
    user-select: none;

    .list-company-blk {
      min-height: 180px;

      .avatar {
        border-radius: 12px;
      }
    }

    .list-company-content p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .list-company-blk {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 34px;

    span {
      margin: 0 !important;
    }
  }

  .list-company-blk img {
    min-width: 120px;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .list-company-content span {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.078px;
    display: block;
    margin-bottom: 4px;
  }

  .list-company-content p {
    color: var(--Grayscale-Main-text, #333);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }

  .list-company-content ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .list-company-content ul li {
    border-radius: 8px;
    border: 1px solid var(--Acents-Main-acent, #49A9BF);
    padding: 4px 12px;
  }

  .list-company-content ul li a {
    color: var(--Acents-Main-acent, #49A9BF);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .list-company-content ul li.li-more {
    background: var(--Grayscale-Bg, #E9ECEF);
    border: none;
  }

  .list-company {
    width: 100%;
    align-items: flex-start;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
  }

  .list-company h1 {
    width: 100%;
  }

  .list-company-content ul li.li-more a {
    color: var(--Grayscale-Main-text, #333);
  }

  .edit-profile .filters {
    background: url(../../assets/newpages/img/filter.svg) center / contain no-repeat;
    width: 24px;
    height: 22px;
    border: none;
    cursor: pointer;
  }

  .list-company.active .edit-profile {
    width: 63%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .list-company.active .edit-profile .list-company-block {
    flex-direction: column;
  }

  .list-company.active .edit-profile .list-company-block .col-block {
    width: 100%;
  }

  .list-company .filter-block {
    display: none;
  }

  .list-company.active .filter-block {
    width: 32%;
    gap: 10px;

    .filter-content {
      display: flex;
      flex-direction: column;
      gap: 32px;

      input, #react-select-2-placeholder {
        font-size: 14px !important;
      }
    }

    display: flex;
    flex-direction: column;
  }

  .filter-block .filters {
    border: none;
    background: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .filter-block .filters:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center no-repeat;
    width: 12px;
    height: 8px;
  }

  .check-block {
    border-radius: 8px;
    border: 1px solid var(--Grayscale-Border, #CED4DA);
    background: var(--Grayscale-White, #FFF);
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    padding: 8px 40px 8px 0;
    height: 248px;
    overflow: auto;
  }

  .filter-block .check-block .option-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .check-option-block {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

  .check-blk {
    border-radius: 8px;
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
    color: var(--Grayscale-Main-text, #333);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    padding: 6px 16px;
    display: inline-block;
  }


  .checkbox-ios {
    display: flex;
    position: relative;
    align-items: center;
    gap: 8px;
  }

  .checkbox-ios p {
    margin-bottom: 0;
  }

  .checkbox-ios .checkbox-ios-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 38px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 25%/50%;
    vertical-align: top;
    background: #eee;
    transition: .2s;
  }

  .checkbox-ios .checkbox-ios-switch:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    transition: .15s;
  }

  .checkbox-ios input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .checkbox-ios input[type=checkbox]:not(:disabled):active + .checkbox-ios-switch:before {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
  }

  .checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch {
    background: #49A9BF;
  }

  .checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch:before {
    transform: translateX(17px);
  }


  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (max-width: 1199px) {
    .table-student td:nth-child(2) {
      font-size: 30px;
    }
    .list-company-block .col-block {
      width: 100%;
    }
    .check-block {
      padding-right: 6px;
      height: 265px;
    }
    .list-company.active .edit-profile {
      width: 62%;
    }
    .list-company.active .list-company-blk {
      padding: 16px 24px;
    }
    .list-company.active .list-company-content p {
      font-size: 16px;
    }
    .check-block {
      height: 255px;
    }
    .table-prfl td:nth-child(3):before {
      content: none !important;
    }
    .table-prfl td:nth-child(5):before {
      content: attr(data-label);
      font-weight: 500;
      margin-right: 5px;
    }
    .table-prfl td:nth-child(2) {
      width: 60%;
    }
    .table-prfl .btn {
      justify-content: flex-end;
    }
    .table-prfl td:nth-child(3) {
      width: 40%;
    }
  }

  @media (max-width: 991px) {
    .table-responses tbody tr {
      border-bottom: 1px solid #CED4DA;
      justify-content: space-between;
      padding: 10px 0;
    }
    .table-responses tbody td {
      border: none;
      padding: 0;
    }
    .table-responses tbody td:first-child {
      width: 100%;
      margin-bottom: 10px;
    }
    .table-responses td.status {
      width: 70%;
    }
    .table-student td:nth-child(2):before {
      content: attr(data-label);
      font-weight: 500;
      margin-right: 5px;
    }
    .table-student td:nth-child(2) {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .list-company.active .edit-profile {
      display: none;
    }
    .list-company {
      flex-direction: column;
    }
    .list-company.active .filter-block {
      width: 100%;
    }
    .list-company.active .filter-blk, .list-company.active .filter-btn {
      display: flex;
    }
    .check-block {
      height: 240px;
    }
    .list-company-blk {
      padding: 16px 24px;
    }
    .list-company.active h1 {
      display: none;
    }
    .list-company .filter-block h2:after {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .modal-block {
      max-width: 90%;
      padding: 30px;
    }
    .table-responses tbody td.td-tag, .table-responses td.status .btn {
      display: none;
    }
    .table-responses td.status {
      width: 100%;
      padding-left: 0;
      position: static;
    }
    .td-responses p {
      font-size: 14px;
    }
    .table-responses tbody tr {
      position: relative;
    }
    .table-responses .td-more {
      position: absolute;
      top: 30px;
      right: 0;
    }
    .table tbody ul.td-more-info.active {
      top: 55px;
    }
    .table-student td.status {
      width: 40%;
    }
    .table-student tbody td.td-tag, .table-student td.status .btn {
      display: flex;
    }
    .list-company .flex-filter {
      display: none;
    }
    .list-company.active .filter-btn {
      flex-direction: row;
    }
    .filter-title {
      /*color: #ffffff;*/
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      padding: 10px 0px;
    }
    /* .filter-title:before {
       content: "";
       background: url(../../assets/newpages/img/back-arrow.svg) center / contain no-repeat;
       width: 17px;
       height: 15px;
       filter: brightness(0) invert(1);
     }*/
  }

  @media (max-width: 575px) {
    .career-block-offer {
      gap: 14px;
    }
    .career-offer-blk, .modal-footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
    .modal-header:before {
      content: "";
      background: url(../../assets/newpages/img/modal-sent.svg) center / contain no-repeat;
      width: 274px;
      height: 201px;
      margin-bottom: 64px;
    }
    .modal-block .btn-close {
      display: none;
    }
    .modal-block {
      max-width: 100%;
      font-size: 16px;
      height: calc(100% - 72px);
      overflow: auto;
      gap: 16px;
      padding: 24px 20px;
      transform: none;
      top: 72px;
      left: 0;
      border-radius: 24px 24px 0px 0px;
      box-shadow: none;
    }
    .modal-overlay {
      display: none !important;
    }
    .modal-header {
      padding: 32px 8px 0;
      flex-direction: column;
    }
    .modal-body {
      padding: 0 8px 32px;
    }
    .modal-footer a, .modal-footer button {
      width: 100%;
    }
    .modal-footer {
      margin-top: auto;
    }
    .lock .btn-back {
      margin-bottom: 20px;
    }
    .salary p {
      font-size: 30px;
    }
    .btn-block a {
      font-size: 14px;
    }
    .table-responses tbody td:first-child {
      width: auto;
      margin-bottom: 8px;
      padding-right: 20px;
    }
    .table-responses tbody td:first-child img {
      display: none;
    }
    .table-responses .td-more {
      top: 15px;
    }
    .table tbody ul.td-more-info.active {
      top: 40px;
    }
    .tag li a, .filter-blk .tag li, .tags li {
      padding: 4px 12px;
      font-size: 12px;
    }
    .btn-back {
      padding: 22px 24px;
      /*color: #fff;*/
      text-decoration: none;
    }
    .btn-back:before {
      //filter: brightness(0) invert(1);
    }
    .content-block {
      border-radius: 24px 24px 0 0;
    }
    .table-student td:nth-child(2) {
      width: 30%;
      font-size: 16px;
    }
    .table-student tbody td:first-child {
      width: 100%;
    }
    .table-student td.status {
      width: 60%;
    }
    .table-student td:nth-child(2), .list-company-content p {
      font-size: 14px;
    }
    .list-company-blk {
      padding: 16px 24px;
    }
    .list-company-blk img {
      /*display: none;*/
    }
    .list-company-content ul {
      margin-top: 24px;
    }
    .content-block h1 {
      text-transform: uppercase;
    }
    .list-company.active .filter-btn {
      flex-direction: column;
    }
    .table-prfl td:nth-child(n+3):nth-child(-n+4) {
      width: 40%;
    }
    .table-prfl td:nth-child(2) {
      width: 85%;
    }
    .table-prfl td:nth-child(3) {
      width: 100% !important;
      order: 6 !important;
      margin: 10px 0 0;
    }
    .table-prfl td:nth-child(6) {
      order: 3;
      padding: 0;
    }
    .table-prfl td:nth-child(4) {
      width: 40%;
      margin-left: auto;
      padding-bottom: 0;
    }
    .table-prfl td:nth-child(5) {
      width: 30%;
      padding-bottom: 0;
    }
    .search-block-offer {
      padding: 20px;
    }
    .search-block-offer form .flex {
      flex-direction: column;
      gap: 16px;
    }
    .search-blk {
      width: 100%;
    }
  }

  @media (max-width: 374px) {
    .table-student td.status {
      width: 70%;
    }
    .responses-blk {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .content-block h1 {
      padding-right: 0;
    }
    .table-student td.status {
      width: 100%;
    }
    .search-btn {
      flex-direction: column;
    }
    .table-prfl td:nth-child(2) {
      width: 80%;
    }
    .search-block-offer form {
      gap: 20px;
    }
    .table-prfl td:nth-child(n+3):nth-child(-n+4) {
      margin-left: 0;
      width: 50%;
    }
    .table-prfl td:nth-child(3) {
      width: 50% !important;
    }
    .table-prfl td:nth-child(6) {
      order: 6;
      padding-bottom: 16px;
      margin-top: 10px;
    }
  }

  #offers-table {
    max-height: 200px !important;
    overflow-y: scroll;
  }

  .td-more-info li {
    width: 100%;
  }

  .td-more-info li a {
    width: 100%;
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .btn_disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  input:disabled {
    opacity: .5;
  }

  .type-green-noicon {
    color: green !important;

  }

  .type-green {
    color: green !important;
  }

  .type-green::before {
    background: url(../../assets/newpages/img/icon-type1.svg) !important;
    background-repeat: no-repeat !important;
  }

  .sorting-asc span::after {
    transition: transform .5s ease;
    transform: rotateZ(180deg);
  }

  .offer-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #offers-table {
    margin-top: 20px;
  }

  .tab-block, .tariff {
    margin-top: 20px;
  }

  .table.table-profile {
    /*min-height: 500px;*/
  }

  .offer-list-block {
    .row-block.wrap-block {
      /*min-height: 500px;*/

    }
  }

  .block-archive {
    pointer-events: none !important;

    .offer-archive {
      background-color: rgba(220, 220, 220, 0.54);
      opacity: .8;
      pointer-events: none !important;
    }
  }

  .vacancy-name {
    .create-blk {
      gap: 5px;

      .status {
        padding-left: 0;
      }
    }
  }

  .response-block {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 10px;
  }

  .response-status {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    &::before {
      content: '';
      height: 15px;
      width: 15px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &_CONSIDERATION {
      &::before {
        background-image: url(../../assets/newpages/img/response-consideration.svg);
      }
    }
  }

  input, .select__single-value, .select__multi-value__label, textarea {
    font-size: 14px !important;
  }

  /*
-------------------------
New styles for Login page
-------------------------
*/
  .login-content {
    width: 580px;
    height: 100%;
    min-height: 100vh;
    border-radius: 16px 0px 0px 16px;
    background: var(--Grayscale-White, #FFF);
    padding: 64px;
    float: right;
    border-radius: 16px 0px 0px 16px;
    background: var(--Grayscale-Main-text, #333);
    color: #ffffff;
  }

  .login-content-block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    min-height: calc(100vh - 128px);
  }

  .login-content-title {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: auto;
  }

  .login-content-title h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
  }

  .login-content-title h1 span {
    display: block;
  }

  .login-popover {
    cursor: pointer;
    background: url(../../assets/newpages/img/icon-question-big.svg) center no-repeat;
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: block;
    background-size: 24px;
    position: relative;
  }

  .login-popover .popover-blk {
    display: none;
    position: absolute;
    border-radius: 8px;
    background: #ffffff;
    padding: 12px 16px;
    width: 254px;
    left: -45px;
    top: -70px;
    //bottom: calc(100% + 20px);
    @media screen and (max-width: 575px) {
      top: auto;
      bottom: -70px;
      z-index: 2;
    }
  }

  .login-popover .popover-blk p {
    color: #333;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: -0.078px;
    margin: 0;
  }

  .login-popover .popover-blk:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top: 17px solid #ffffff;
    top: 97%;
    left: 53px;
  }

  .login-popover:hover .popover-blk {
    display: block;
  }

  .popover-top {
    align-items: flex-start;
  }

  .tab-content > div {
    display: none;
  }

  .tab-content > div.active {
    display: block;
  }

  .tab-menu {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0 0 32px;
  }

  .tab-menu li {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    width: 50%;
    border-bottom: 2px solid #CED4DA;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .tab-menu li.active {
    color: var(--Grayscale-White, #FFF);
    font-weight: 500;
    letter-spacing: 0.374px;
    position: relative;
  }

  .tab-menu li.active:after {
    content: "";
    width: 100%;
    height: 2px;
    border-bottom: 3px solid #CED4DA;
    position: absolute;
    bottom: -2.5px;
    left: 0;
  }

  .form-name {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 45%;
  }

  .login-form input {
    color: white;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
    border: none;
    border-radius: 8px;
    background: #555;
  }

  .login-form input::-webkit-input-placeholder {
    color: #b8b8b8;
  }

  .login-form input:-moz-placeholder {
    color: #b8b8b8;
  }

  .login-form input::-moz-placeholder {
    color: #b8b8b8;
  }

  .login-form input:-ms-input-placeholder {
    color: #b8b8b8;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .login-form-blk {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .form-blk {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .login-form label {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .login-form .checks {
    margin-top: 4px;
  }

  .login-form button {
    margin-top: 36px;
    padding: 16px;
  }

  .login-form .create {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    line-height: 1.2;
    letter-spacing: -0.078px;
    text-decoration-line: underline;
    text-align: center;
    margin-top: 24px;
  }

  /* checkbox */
  .checks {
    width: 100%;
    margin: 12px 0;
    display: flex;
    align-items: center;
  }

  .checkbox {
    display: none;
  }

  .checkbox-custom {
    position: relative;
    border-radius: 4px;

    &:checked {

    }

    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    width: 22px;
    min-width: 22px;
    height: 22px;
  }

  .disabled {
    opacity: .4;
    pointer-events: none;
  }

  .checkbox:checked + .checkbox-custom {
    border: 2px solid var(--Acents-Main-acent, #49A9BF) !important;
    background: var(--Acents-Main-acent, #49A9BF) !important;
  }

  .checkbox:checked + .checkbox-custom::before {
    content: "";
    background: url(../../assets/newpages/img/login-check.svg) center no-repeat;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.078px;
    margin-left: 8px;
  }

  .label a {
    text-decoration: underline;
    color: var(--Grayscale-White, #FFF);
  }

  .help-block {
    margin-top: auto;
  }

  .help-block p {
    margin-bottom: 0;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .help-block p a {
    display: block;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    text-decoration: none;
  }

  .form-blk .flex a {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
    text-decoration-line: underline;
    color: var(--Grayscale-Secondary-text, #ADB5BD);
  }

  .form-error input {
    background: var(--Acents-Functional-Error-Secondary, #F5D2DC);
  }

  .form-error input::-webkit-input-placeholder {
    color: var(--Acents-Functional-Error, #BD4567);
  }

  .form-error input:-moz-placeholder {
    color: var(--Acents-Functional-Error, #BD4567);
  }

  .form-error input::-moz-placeholder {
    color: var(--Acents-Functional-Error, #BD4567);
  }

  .form-error input:-ms-input-placeholder {
    color: var(--Acents-Functional-Error, #BD4567);
  }

  label.error {
    color: var(--Acents-Functional-Error, #BD4567);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }

  .sent-message {
    margin-top: 32px;
  }

  .sent-message p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }

  .login-form .btn-white {
    border-radius: 8px;
    border: 2px solid var(--Grayscale-White, #FFF);
    background: var(--Grayscale-White, #FFF);
    font-weight: 700;
    font-size: 18px;
  }


  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (max-width: 1199px) {
    .login-content {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    .login-content {
      padding: 60px 40px;
      width: 100%
    }
    .login-content-title h1 {
      font-size: 26px;
    }
    .login-popover {
      height: 30px;
    }
    .sent-message p {
      font-size: 16px;
    }
    .login-content-block {
      min-height: calc(100vh - 120px);
    }
  }
  @media (max-width: 900px) {
    .login-content {
      width: 100%
    }
  }
  @media (max-width: 767px) {
    .login-content {
      padding: 50px 30px;
    }
    .login-content-block {
      gap: 24px;
    }
    .label {
      font-size: 10px;
    }
    .login-content-title h1 {
      font-size: 20px;
    }
    .login-form button {
      font-size: 14px;
      padding: 12px 14px;
      margin-top: 20px;
    }
    .help-block p {
      font-size: 13px;
    }
    .login-popover {
      height: 24px;
    }
    .login-popover .popover-blk {
      width: 225px;
      right: -15px;
    }
    .login-popover .popover-blk:before {
      right: 23px;
    }
    .login-content-block {
      min-height: calc(100vh - 100px);
    }
    .tab-menu {
      margin-bottom: 24px;
    }
    .login-form .btn-white {
      font-size: 13px;
    }
  }

  @media (max-width: 575px) {
    .login-content {
      width: 100%;
      border-radius: 0;
      height: auto;
      min-height: 0;
      border-radius: 24px 24px 0px 0px;
      padding: 32px 20px;
    }
    .help-block p {
      font-size: 14px;
    }
    .login-content-block {
      min-height: 0;
    }
    .login-form input {
      padding: 13px 15px;
    }
    .help-block {
      display: none;
    }
    .login-form .btn-white {
      font-size: 16px;
    }
    .login-form label {
      font-size: 14px;
    }
    .reg-form .login-form-blk {
      gap: 16px;
    }
  }

  @media (max-width: 374px) {
    .label {
      font-size: 10px;
    }
    .login-content-title h1 {
      font-size: 22px;
    }
    .login-content-block {
      gap: 30px;
    }
    label.error {
      font-size: 12px;
    }
    .login-form .btn-white {
      font-size: 14px;
    }
  }
  //NEW STYLE 2
  .btn {
    cursor: pointer;
  }

  .search-form .select-blk li:not(:last-child) {
    border-bottom: none;
  }

  .search-form .flex {
    gap: 24px 32px;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .search-form .search-blk {
    width: calc(33.3333% - 22px);
  }

  .content-block h1 {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .content-block h1:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: -8px;
    background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);
  }

  .create-select .tag li.tags-more {
    border-radius: 12px;
    background: var(--Grayscale-Main-text, #333);
    color: var(--Grayscale-White, #FFF);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  .search-form .create-select .tag li {
    max-width: 205px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .search-form .select-blk.active {
    width: calc(100% + 46px);
  }

  .table-status td:nth-child(3) span {
    border-radius: 8px;
    padding: 4px 12px;
    color: var(--Grayscale-White, #FFF);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
    display: inline-block;
  }

  .tables {
    border-collapse: collapse;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
  }

  .tables thead tr {
    background: var(--Grayscale-Bg, #E9ECEF);
  }

  .tables thead tr th {
    padding: 12px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
    text-align: left;
    height: 64px;
  }

  .tables thead tr th:first-child {
    border-radius: 8px 0 0 0;
    padding-left: 16px;
  }

  .tables thead tr th:last-child {
    border-radius: 0 8px 0 0;
    padding-right: 16px;
  }

  .tables thead tr th.add span, .tables thead tr th.add a {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 2px solid var(--Grayscale-Main-text, #333);
    background: var(--Grayscale-Main-text, #333) url(../../assets/newpages/img/icon-add.svg) center no-repeat;
  }

  .tables tbody ul {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tables tbody ul li {
    display: flex;
    align-items: center;
  }

  .tables .edit {
    background: url(../../assets/newpages/img/icon-edit.svg) center no-repeat;
    width: 14px;
    height: 14px;
  }

  .tables .delete {
    background: url(../../assets/newpages/img/icon-delete.svg) center no-repeat;
    width: 12px;
    height: 14px;
    display: block;
  }

  .tables td {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
    vertical-align: middle;
    padding: 16px 0;
    border-bottom: 1px solid #CED4DA;
  }


  .table-status .status1 span {
    background: var(--Acents-Main-acent, #49A9BF);
  }

  .table-status .status2 span {
    background: var(--Acents-Additional-Additional-acent-1, #1B4980);
  }

  .table-status .status3 span {
    background: var(--Acents-Additional-Additional-acent-2, #41B695);
  }

  .table-status thead tr th, .table-status tbody tr td {
    padding: 16px;
  }

  .table-status th:nth-child(1) {
    width: 70px;
  }

  .table-status th:nth-child(2) {
    width: 14%;
  }

  .table-status th:nth-child(3) {
    width: 10%;
  }

  .table-status th:nth-child(4) {
    width: 10%;
  }

  .table-status th:nth-child(5), .table-status th:nth-child(6) {
    width: 16%;
  }

  .table-status th:nth-child(7) {
    width: 13%;
  }

  .table-status th:nth-child(8) {
    width: 12%;
  }

  .table-status td:nth-child(7), .table-status td:nth-child(8) {
    word-break: break-word;
  }

  .table-status {
    min-width: 1010px;
  }

  .scroll {
    width: 100%;
    overflow-x: auto;
  }

  .scroll::-webkit-scrollbar {
    background: rgba(26, 26, 26, 0.08);
    height: 5px;
  }

  .scroll::-webkit-scrollbar-thumb {
    background: var(--Acents-Main-acent, #49A9BF);
  }


  .tabs .tab-content > div {
    display: none;
  }

  .tabs .tab-content > div.active {
    display: block;
  }

  /*.tabs {width: 100%;}*/

  .tabsmenu {
    margin: 0 0 32px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 2px solid var(--Grayscale-Border, #CED4DA);
  }

  .tabsmenu li {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
  }

  .tabsmenu li.active {
    font-weight: 500;
  }

  .tabsmenu li.active:after {
    content: "";
    width: 100%;
    height: 3px;
    background: var(--Grayscale-Main-text, #333);
    position: absolute;
    bottom: -2.5px;
    left: 0;
  }

  .modal-block .tab-content .adds {
    border-radius: 8px;
    border: 2px dashed var(--Grayscale-Main-text, #333);
    padding: 8px 32px;
    position: relative;
  }

  .modal-block .tab-content .adds input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
    margin: 0;
  }

  .modal-block .tab-content .adds span {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 90px;
  }

  .modal-block .tab-content .adds span:before {
    content: "";
    background: url(../../assets/newpages/img/add.svg) center no-repeat;
    width: 40px;
    height: 40px;
  }

  .big-modal {
    max-width: 822px;
  }

  .modal-block .tab-content input {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 14px 16px;
    width: 100%;
    border: none;
  }

  .modal-block .modal-footer {
    margin-top: 32px;
  }

  .big-modal h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

  .big-modal h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 12px;
  }

  .student-btn {
    width: 100%;
    margin-top: 42px;
  }

  .student-info-block {
    gap: 40px;

    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 777px) {
      .student-info-blk {
        width: 100%;
      }
      flex-direction: column;
      .search-blk {
        max-width: 100% !important;
      }
    }
  }

  .add-photo {
    width: 156px;
    min-width: 156px;
    height: 156px;
    border-radius: 8px;
    border: 2px dashed var(--Grayscale-Main-text, #333);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .avatar-photo {
    background: linear-gradient(139deg, rgba(25, 67, 118, 0.90) -44.75%, rgba(17, 124, 193, 0.90) 13.62%, rgba(40, 176, 154, 0.90) 137.18%), #FFF;
    width: 100%;
    height: 100%;
  }

  .add-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .add-photo span {
    color: var(--Grayscale-Main-text, #333);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 85%;
    gap: 10px;
  }

  .add-photo span:before {
    content: "";
    background: url(../../assets/newpages/img/add.svg) center no-repeat;
    width: 40px;
    height: 40px;
  }

  .add-photo input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
    margin: 0;
  }

  .student-info-blk .search-form .flex {
    align-items: flex-start;
  }

  .student-info-blk .search-form .search-blk {
    width: calc(50% - 16px);
  }

  .student-info-blk .search-form .create-select .tag li {
    max-width: 100%;
  }

  .avatar-modal {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }

  .avatar-preview {
    width: 320px;
    height: 320px;
    min-width: 320px;
    background: linear-gradient(139deg, rgba(25, 67, 118, 0.90) -44.75%, rgba(17, 124, 193, 0.90) 13.62%, rgba(40, 176, 154, 0.90) 137.18%), #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 8px;
  }

  .cropper-container, .cropper-canvas, .cropper-crop-box, .cropper-drag-box, .cropper-modal, .cropper-wrap-box {
    border-radius: 8px;
  }

  .avatar-preview img {
    width: 100%;
  }

  .character-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
    margin-bottom: 32px;
  }

  .character-btn {
    width: 64px;
    height: 64px;
    padding: 4px;
    border-radius: 8px;
    background: linear-gradient(139deg, rgba(25, 67, 118, 0.90) -44.75%, rgba(17, 124, 193, 0.90) 13.62%, rgba(40, 176, 154, 0.90) 137.18%), #FFF;
    border-radius: 8px;
    border: none;
  }

  .character-btn img {
    width: 100%;
  }

  .character-btn.active {
    border: 3px solid var(--Grayscale-Main-text, #333);
  }

  .upload-section {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 2px dashed var(--Grayscale-Main-text, #333);
    padding: 8px 32px;
  }

  .upload-section label {
    color: var(--Grayscale-Main-text, #333);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 60px;
  }

  .upload-section label:before {
    content: "";
    background: url(../../assets/newpages/img/add.svg) center no-repeat;
    width: 40px;
    height: 40px;
  }

  .upload-section input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
    margin: 0;
  }

  .upload-section label span {
    max-width: 60%;
    text-align: left;
  }

  #editModal p {
    margin: 0 auto 32px;
    text-align: center;
  }

  #editModal p span {
    display: block;
  }

  .edit-block {
    border-radius: 8px;
    background: var(--Grayscale-Main-text, #333);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .icon-edit {
    position: relative;
  }

  .edit-block button {
    display: block;
    border: none;
    padding: 0;
    height: 14px;
    cursor: pointer;
  }

  .icon-edit button {
    background: url(../../assets/newpages/img/icon-edit2.svg) no-repeat;
    width: 14px;
  }

  .edit-block .delete {
    background: url(../../assets/newpages/img/icon-delete2.svg) no-repeat;
    width: 12px;
  }

  .login-block, .login-blk {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .login-blk {
    gap: 8px;
  }

  .login-blk span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.374px;
  }

  .login-blk p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }

  .tabs .tabs .tabsmenu {
    border: none;
    gap: 8px;
  }

  .tabs .tabs .tabsmenu li.active:after {
    display: none;
  }

  .tabs .tabs .tabsmenu li {
    border-radius: 8px;
    padding: 6px 16px;
  }

  .tab-inside h3 {
    font-size: 16px;
    margin: 0 0 12px;
  }

  .table-blk:not(:last-child) {
    margin-bottom: 32px;
  }

  .tables-student thead tr th {
    padding: 12px;
  }

  .tables-student td {
    width: 20%;
    padding: 16px;
  }

  .tables-student td:first-child {
    width: 60%;
  }

  .td-edit input {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 8px 16px;
    line-height: normal;
    border: none;
    width: 100px;
    height: 32px;
  }

  .tables-achievements td {
    width: 25%;
  }

  .tables-achievements th:first-child, .tables-achievements td:first-child {
    width: 65px;
    min-width: 65px;
  }

  .tables-achievements td:first-child a {
    margin: 0 auto;
  }

  .tables-achievements td:nth-child(2) {
    width: 50%;
  }

  .add-achievements {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .add-achievements .search-blk {
    width: 100%;
    max-width: 100%;
  }

  .add-achievements input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    opacity: 1;
    background: url(../../assets/newpages/img/calendar.svg) center no-repeat;
    background-size: contain;
    float: left;
  }

  .tables-dpo td {
    width: 50%;
  }

  .tables-dpo td:first-child {
    width: 64px;
    min-width: 64px;
  }

  .tables-dpo td:first-child a {
    margin: 0 auto;
  }

  .slider-container {
    margin: 20px;
    position: relative;
    width: 300px;
  }

  .slider {
    width: 100%;
  }

  .value-display {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e0e4e8;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
  }

  .value-display::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e0e4e8 transparent;
  }

  .polikon-blk {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 320px;
  }

  input[type="range"] {
    width: 100%;
    height: 2px;
    background: transparent;
    outline: none;
    margin: 10px 0;
    background: linear-gradient(to right, #333 0%, #333 15%, #E9ECEF 15%, #E9ECEF 100%);
    position: relative;
  }

  input[type="range"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 8px;
    background: #333;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 2px;
    background: transparent;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -3px;
  }

  input[type="range"]::-moz-range-thumb {
    width: 8px;
    height: 8px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-track {
    height: 2px;
    background: transparent;
  }

  /* Firefox-specific track styling */
  input[type="range"]::-moz-range-progress {
    background-color: #333;
    height: 2px;
  }

  input[type="number"] {
    margin: 10px 0;
    width: 100px;
    text-align: center;
  }

  #sliderValue {
    padding: 16px;
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    color: var(--Grayscale-Main-text, #333);
    font-size: 18px;
    font-weight: 400;
    border: none;
    width: 151px;
    text-align: left;
  }

  .table-title {
    font-size: 16px;
    margin: 0 0 12px;
  }

  .table-grade td {
    width: 15%;
  }

  .table-grade td:first-child {
    width: 55%;
  }

  .table-block {
    position: relative;
  }

  .table-popover {
    position: absolute;
    right: 16px;
    top: 27px;
    cursor: pointer;
    background: url(../../assets/newpages/img/icon-question.svg) no-repeat;
    width: 13px;
    height: 14px;
    display: block;
  }

  .popover-blk {
    display: none;
    position: absolute;
    height: available;
    border-radius: 8px;
    background: var(--Grayscale-Main-text, #333);
    padding: 12px 16px;
    width: 365px;
    right: -32px;
    top: calc(100% + 15px);
  }

  .content-block .popover-blk p {
    color: var(--Grayscale-White, #FFF);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
    margin-bottom: 0;
  }

  .popover-blk:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom: 17px solid var(--Grayscale-Main-text, #333);
    top: -22px;
    right: 26px;
  }

  .table-popover:hover .popover-blk {
    display: block;
  }

  .table-tovar thead tr th {
    padding: 12px;
  }

  .table-tovar td:nth-child(2) {
    width: 20%;
  }

  .table-tovar td:nth-child(4) {
    width: 20%;
  }

  .table-tovar td {
    vertical-align: top;
    text-align: left;
    padding: 10px 0;
  }

  .table-tovar td a {
    justify-content: flex-start;
    padding: 0;
  }

  .table-tovar td:first-child {
    vertical-align: middle;
  }

  .tovar-descript {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .tovar-descript img {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    object-fit: cover;
  }

  .tovar-descript p {
    margin-bottom: 0;
  }

  .not-pay span {
    background: var(--Acents-Additional-Additional-acent-1, #1B4980);
    color: #FFF;
  }

  .tovar-block {
    display: flex;
    align-items: flex-start;
    gap: 40px;
  }

  .product-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 300px;
    min-width: 300px;
  }

  .product-container span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .main-image {
    width: 300px;
    height: 300px;
  }

  .main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .thumbnail-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .thumbnail-images img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }

  .btn-add-tovar {
    width: 48px;
    height: 48px;
    position: relative;
  }

  .btn-add-tovar button {
    border-radius: 8px;
    background: var(--Grayscale-Main-text, #333) url(../../assets/newpages/img/icon-add2.svg) center no-repeat;
    padding: 4px;
    border: none;
    width: 100%;
    height: 100%;
  }

  .btn-add-tovar input[type="file"] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    cursor: pointer;
  }

  .tovar-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .tovar-info .search-form .search-blk {
    width: calc(50% - 16px);
  }

  .tovar-info .search-form .search-blk-text {
    width: 100%;
    max-width: 100%;
  }

  .tovar-info .search-form .search-blk-text textarea {
    min-height: 44px;
    padding: 6px 16px;
  }

  .tovar-info-blk {
    border-radius: 16px;
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .tovar-info-blk h3 {
    margin: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .tovar-info-blk a.btn {
    font-size: 12px;
    padding: 0;
  }

  .tovar-blk {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .tovar-blk span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .tovar-blk {
    width: 33%;
  }

  .tovar-blk p, .tovar-blk a {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }

  .tovar-blk a {
    justify-content: flex-start;
  }

  .add-tovar {
    display: flex !important;
    width: 100%;
    align-items: flex-start;
    gap: 40px;
  }

  .add-tovar .add-photo {
    width: 284px;
    min-width: 284px;
    height: 284px;
  }

  .add-tovar .add-photo span {
    max-width: 75%;
  }

  .auction-block {
    gap: 32px;
  }

  .rates-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .rates-blk {
    padding: 8px 0;
    border-bottom: 1px solid #CED4DA;
  }

  .rates-value span {
    color: var(--Grayscale-Main-text, #333);
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .rates-title {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .rates-title img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }

  .rates-title-blk span {
    overflow: hidden;
    color: var(--Grayscale-Main-text, #333);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0.374px;
    margin-bottom: 4px;
    display: block;
  }

  .rates-title-blk p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .auction-row .thumbnail-images {
    justify-content: flex-start;
    gap: 20px;
  }

  .bet-block {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .bet-info {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
  }

  .bet-title {
    width: 40%;
  }

  .bet-title span {
    display: block;
    margin-bottom: 8px;
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .bet-title p {
    margin-bottom: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.078px;
  }

  .auction-block h3 {
    margin: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
  }

  .tovar-flx span {
    color: var(--Grayscale-White, #FFF);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    padding: 6px 16px;
    border-radius: 8px;
    background: var(--Acents-Functional-Error, #BD4567);
  }

  .auction-completed .product-container img {
    filter: grayscale(1);
  }

  .auction-active .tovar-flx span {
    background: var(--Acents-Main-Acent-Hover, linear-gradient(0deg, rgba(73, 169, 191, 0.80) 0%, rgba(73, 169, 191, 0.80) 100%), #FFF);
  }

  .tovar-info-blk a.btn-part {
    padding: 17px 40px;
    font-size: 18px;
    font-weight: 700;
  }

  .card-tovar-description {
    border-radius: 16px;
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 16px 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  .card-tovar-description h1 {
    margin-bottom: 0;
  }

  .card-tovar-description h1:after {
    content: none;
  }

  .card-tovar-description h3 {
    color: var(--Grayscale-Main-text, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
    margin: 0 0 4px;
  }

  .card-tovar-description p {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
  }

  .card-tovar-blk {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  .card-tovar-blk div {
    width: calc(50% - 12px);
  }

  .card-tovar-block {
    gap: 32px;
  }


  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (max-width: 1399px) {
    .search-form .search-blk {
      width: calc(50% - 16px);
    }
    .td-responses > div {
      width: 75%;
    }
    .auction-row .tovar-blk {
      width: auto;
    }
  }

  @media (max-width: 1199px) {
    .search-form .flex {
      gap: 20px;
    }
    .search-form .search-blk {
      width: calc(50% - 10px);
    }
    .table-status {
      min-width: 100%;
    }
    .table-status thead th:not(:first-child) {
      display: none;
    }
    .table-status tbody tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 12px;
      position: relative;
    }
    .table-status tbody tr td {
      border: none;
      padding: 5px;
      position: relative;
      z-index: 2;
    }
    .table-status td:nth-child(5) {
      margin-top: 10px;
    }
    .table-status td:nth-child(n+5) {
      width: 100%;
    }
    .table-status td:nth-child(n+5):before {
      content: attr(data-label);
      margin-right: 10px;
      font-weight: 500;
    }
    .table-status td:nth-child(2) {
      width: 50%;
      margin-right: auto;
    }
    .table-status td:nth-child(3) {
      width: 15%;
    }
    .table-status tbody tr:before {
      content: "";
      background: #f2f2f2;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .tabs-scroll .tabsmenu {
      overflow: auto;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-bottom: none;
    }
    .tabs-scroll {
      position: relative;
      width: 100%;
    }
    .tabs-scroll:after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      background: #CED4DA;
      left: 0;
      top: 24px;
      z-index: 1;
    }
    .tabs .tabs-scroll:after {
      display: none;
    }
    .tabs-scroll .tabsmenu li.active:after {
      bottom: 0;
      z-index: 2;
    }
    .student-info-blk .search-form .search-blk {
      width: 100%;
    }
    .tables-dpo td:nth-child(2) {
      width: 70%;
    }
    .table-responses td:first-child {
      width: 50%;
    }
    .table-responses td p {
      font-size: 16px;
    }
    .table-tovar td {
      padding: 10px;
      font-size: 14px;
    }
    .table-tovar td a {
      font-size: 12px;
    }
    .table-tovar td:nth-child(4) {
      width: 23%;
    }
    .tovar-descript {
      gap: 10px;
    }
    .tovar-descript img {
      width: 55px;
      height: 55px;
    }
    .tovar-info .search-form .search-blk, .tovar-blk {
      width: 100%;
    }
    .tovar-flex, .tovar-info-blk {
      flex-wrap: wrap;
      gap: 12px;
    }
    .tovar-blk {
      flex-direction: row;
      align-items: center;
    }
    .add-tovar .add-photo {
      width: 200px;
      min-width: 200px;
      height: 200px;
    }
    .tovar-block {
      flex-direction: column;
    }
  }

  @media (max-width: 991px) {
    .search-form .search-blk {
      width: 100%;
    }
    .table-status tbody tr {
      padding: 10px;
      margin-bottom: 10px;
    }
    .table-status tbody tr td {
      font-size: 14px;
      padding: 5px 0;
    }
    .table-status td:nth-child(1) {
      padding-right: 10px;
    }
    .table-status td:nth-child(2) {
      width: 50%;
    }
    .table-status td:nth-child(3) {
      width: 18%;
    }
    .table-status td:nth-child(3) span {
      font-size: 10px;
    }
    .table-status td:nth-child(4) {
      font-size: 12px;
    }
    .table-status td:nth-child(5) {
      margin-top: 15px;
    }
    .big-modal {
      max-width: 90%;
      right: 0;
      margin: 0 auto;
    }
    .add-photo {
      width: 120px;
      min-width: 120px;
      height: 120px;
    }
    .add-photo span {
      font-size: 12px;
    }
    .student-info-block {
      gap: 20px;
    }
    .tabs-scroll .tabsmenu {
      gap: 15px;
    }
    .tabsmenu li {
      font-size: 14px;
    }
    .tabs-scroll:after {
      top: 21.5px;
    }
    .avatar-preview {
      width: 270px;
      min-width: 270px;
      height: 270px;
    }
    .tables-student td, .table-grade td {
      font-size: 14px;
    }
    .tables-student td:first-child {
      padding-right: 10px;
    }
    .tables-student thead tr th:nth-child(2), .tables-student tbody tr td:nth-child(2) {
      text-align: center;
    }
    .tables-dpo td:nth-child(2) {
      width: 80%;
    }
    .tables-dpo td {
      font-size: 14px;
    }
    .table-grade td {
      width: 17%;
    }
    .table-grade td:first-child {
      width: 49%;
    }
    .table-popover {
      top: 25px;
    }
    .content-block .popover-blk p {
      font-size: 14px;
    }
    .popover-blk {
      width: 330px;
    }
    .table-tovar thead th:not(:first-child) {
      display: none;
    }
    .table-tovar tr {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      border-bottom: 1px solid #CED4DA;
      padding: 10px 0;
    }
    .table-tovar td {
      border: none;
      padding: 0;
    }
    .table-tovar td:first-child {
      width: 7%;
      align-items: center;
      display: flex;
      order: 1;
    }
    .table-tovar td:nth-child(2) {
      order: 2;
      width: 35%;
    }
    .table-tovar td:nth-child(3) {
      order: 3;
    }
    .table-tovar td:nth-child(4), .table-tovar td:nth-child(5) {
      order: 6;
      width: 100%;
      margin-left: 13%;
    }
    .table-tovar td:nth-child(5) {
      order: 5;
    }
    .table-tovar td:nth-child(6) {
      order: 4;
      margin-left: auto;
    }
    .tovar-block {
      flex-direction: column;
    }
    .tovar-info .search-form .search-blk {
      width: calc(50% - 16px);
    }
    .tovar-blk {
      flex-direction: column;
      align-items: flex-start;
      width: 33%;
    }
    .tovar-flex {
      gap: 0;
    }
    .product-container {
      margin: 0 auto;
    }
    .add-tovar .add-photo {
      width: 150px;
      min-width: 150px;
      height: 150px;
    }
    .add-tovar .search-form .flex {
      flex-direction: column;
      align-items: flex-start;
    }
    .add-tovar .search-form .search-blk {
      width: 100%;
    }
    .add-tovar .add-photo span {
      max-width: 80%;
    }
    .bet-title {
      width: 55%;
    }
    .auction-row .tovar-blk {
      width: auto;
    }
  }

  @media (max-width: 767px) {
    .table-status td:nth-child(2) {
      width: 53%;
    }
    .tabsmenu li {
      font-size: 14px;
    }
    .modal-block .tab-content .adds {
      padding: 8px 20px;
    }
    .modal-block .tab-content .adds span {
      font-size: 14px;
    }
    #modal-add .modal-footer a, #modal-add .modal-footer button {
      padding: 10px 32px;
    }
    .tabs-scroll:after {
      top: 21.5px;
    }
    .avatar-modal {
      flex-direction: column;
    }
    .character-btn {
      width: 50px;
      height: 50px;
    }
    #avatarModal {
      gap: 20px;
    }
    .tables-student td {
      width: 25%;
    }
  }

  @media (max-width: 575px) {
    #modal-add, #modal-add2, #modal-add3 {
      height: auto;
    }
    .search-form .create-select .tag li {
      max-width: 180px;
    }
    .table-status td:nth-child(1) {
      width: 15%;
    }
    .table-status td:nth-child(2) {
      width: 85%;
    }
    .table-status td:nth-child(3) {
      width: 30%;
      margin-left: 15%;
    }
    .table-status tbody tr:before {
      height: 75px;
    }
    .big-modal .modal-header {
      padding-top: 0;
    }
    .big-modal .modal-header:before {
      display: none;
    }
    body.std .modal-overlay {
      display: block !important;
    }
    .big-modal {
      max-width: 90%;
      right: 0;
      gap: 32px;
      margin: 0 auto;
      border-radius: 16px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    .student-info-block {
      flex-direction: column;
    }
    .tabs-scroll .tabsmenu {
      width: 100%;
    }
    .student-info-block {
      align-items: center;
    }
    #avatarModal .modal-footer {
      margin-top: 0;
    }
    .upload-section {
      padding: 8px 20px;
    }
    .td-edit input {
      width: 70px;
      padding: 8px;
      text-align: center;
    }
    .tables-student td, .tables-student thead tr th {
      padding: 10px;
    }
    .tables-student thead tr th:first-child {
      padding-left: 10px;
    }
    .tables-student thead tr th:last-child {
      padding-right: 10px;
      overflow: hidden;
      font-size: 0;
      text-align: center;
    }
    .tables-student thead tr th:last-child:before {
      content: "Редакт.";
      visibility: visible;
      font-size: 12px;
    }
    .tables-student td:first-child {
      padding: 10px 0;
    }
    .tables-student td {
      font-size: 13px;
    }
    .tables-achievements th:not(:first-child) {
      display: none;
    }
    .tables-achievements tbody tr {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #CED4DA;
      padding-bottom: 10px;
    }
    .tables-achievements td[data-label] {
      display: flex;
      align-items: center;
    }
    .tables-achievements td[data-label]::before {
      content: attr(data-label) ':';
      display: block;
      margin-right: 5px;
      min-width: 70%;
    }
    .tables-achievements td {
      border: none;
      font-size: 14px;
    }
    .tables-achievements td:first-child {
      width: 10%;
      min-width: 10%;
    }
    .tables-achievements td:nth-child(2) {
      width: 60%;
    }
    .tables-achievements td:nth-child(3) {
      width: 30%;
    }
    .tables-achievements td:nth-child(4) {
      width: 100%;
      padding: 0 0 0 10px;
    }
    .tables-achievements td input {
      width: 30%;
      text-align: center;
    }
    .tables-dpo thead tr th, .tables-dpo thead tr td {
      padding: 10px;
    }
    .tables-dpo thead tr th:first-child {
      padding-left: 10px;
    }
    .tables-dpo thead tr th:last-child {
      padding-right: 10px;
    }
    .tables-dpo td:first-child {
      width: 50px;
      min-width: 50px;
    }
    .tables-dpo td:last-child {
      padding-right: 10px;
    }
    .tables-achievements thead tr th:first-child {
      border-radius: 16px 16px 0 0;
      padding: 10px;
    }
    .tables-dpo td {
      padding: 10px 0;
    }
    .table-popover {
      top: -28px;
    }
    .table-grade thead {
      display: none;
    }
    .table-grade tbody tr {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #CED4DA;
      padding: 10px 0;
    }
    .table-grade tbody td {
      border: none;
      padding: 0;
    }
    .table-grade td {
      width: 33%;
    }
    .table-grade td:first-child {
      width: 100%;
      margin-bottom: 10px;
    }
    .table-grade td[data-label] {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table-grade td[data-label]:last-child {
      justify-content: flex-end;
    }
    .table-grade td[data-label]:nth-child(2) {
      justify-content: flex-start;
    }
    .table-grade td[data-label]::before {
      content: attr(data-label) ':';
      display: block;
      margin-right: 5px;
    }
    .td-responses > div {
      width: 100%;
    }
    .table-tovar td, .tovar-descript {
      align-items: center;
      display: flex;
    }
    .table-tovar td:nth-child(4), .table-tovar td:nth-child(5) {
      margin-left: 0;
    }
    .tovar-descript img {
      width: 50px;
      height: 50px;
    }
    .table-tovar td:nth-child(1) {
      width: 10%;
    }
    .table-tovar td:nth-child(2) {
      width: 85%;
    }
    .table-tovar td:nth-child(3) {
      width: 40%;
      margin-left: auto;
      order: 6;
      justify-content: flex-end;
    }
    .table-tovar td:nth-child(4) {
      order: 4;
    }
    .table-tovar td:nth-child(5) {
      width: 60%;
      order: 3;
    }
    .table-tovar td:nth-child(6) {
      order: 5;
      justify-content: flex-start;
      width: 50%;
      margin-left: 0;
    }
    .tovar-info .search-form .search-blk, .tovar-blk {
      width: 100%;
    }
    .tovar-flex, .tovar-info-blk {
      flex-wrap: wrap;
      gap: 12px;
    }
    .tovar-blk {
      flex-direction: row;
      align-items: center;
    }
    .add-tovar, .tovar-flx, .bet-info, .auction-flex, .card-tovar-blk {
      flex-direction: column;
    }
    .add-tovar .add-photo {
      margin: 0 auto;
    }
    .bet-title {
      width: 100%;
    }
    .auction-row .polikon-blk, .card-tovar-blk div {
      width: 100%;
    }
    .auction-flex {
      margin-bottom: 20px;
    }
    .rates-title img {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
    .rates-value span {
      font-size: 28px;
    }
    .rates-title-blk p {
      font-size: 14px;
    }
    .tovar-flx h3 {
      text-align: center;
      margin-bottom: 10px;
    }
    .tovar-info-blk a.btn-part {
      padding: 12px 40px;
      font-size: 16px;
    }
  }

  @media (max-width: 374px) {
    .search-form .create-select .tag li {
      max-width: 140px;
    }
    .table-status td:nth-child(n+5):before {
      display: block;
    }
    .tabsmenu li {
      font-size: 12px;
    }
    .avatar-preview {
      width: 230px;
      min-width: 230px;
      height: 230px;
    }
    .character-btn {
      width: calc(25% - 6px);
    }
    .character-selection {
      gap: 8px;
    }
    .upload-section {
      padding: 8px 0 8px 20px;
    }
    .upload-section label {
      font-size: 13px;
    }
    #editModal p {
      font-size: 14px;
    }
    #editModal p span {
      display: inline;
    }
    .tables-achievements td:nth-child(2) {
      width: 55%;
    }
    .tables-achievements td:nth-child(3) {
      width: 35%;
    }
    .tables-student td:first-child {
      word-break: break-word;
    }
    .tables-dpo td {
      font-size: 12px;
    }
    .polikon-blk, .main-image {
      width: 100%;
    }
    .tabs-scroll:after {
      top: 19.5px;
    }
    .product-container {
      width: 100%;
      min-width: 100%;
    }
    .rates-blk {
      flex-direction: column;
      align-items: flex-start;
    }
    .rates-value {
      margin-left: 56px;
      margin-top: 10px;
    }
  }
  //NEW STYLE 2
  .stat span {
    border-radius: 8px;
    padding: 4px 12px;
    color: var(--Grayscale-White, #FFF);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.374px;
    display: inline-block;
  }

  .stat.status1 span {
    background: var(--Acents-Main-acent, #49A9BF);
  }

  .stat.status2 span {
    background: var(--Acents-Additional-Additional-acent-1, #1B4980);
  }

  .stat.status3 span {
    background: var(--Acents-Additional-Additional-acent-2, #41B695);
  }

  .tables td.status span {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .tables td.status span:before {
    content: "";
    width: 14px;
    height: 12px;
  }

  .tables td .type1:before {
    background: url(../../assets/newpages/img/icon-type1.svg) center no-repeat;
  }

  .tables td .type2:before {
    background: url(../../assets/newpages/img/icon-type2.svg) center no-repeat;
  }

  .tables td .type3:before {
    background: url(../../assets/newpages/img/icon-type3.svg) center no-repeat;
  }

  .tables td .type4:before {
    background: url(../../assets/newpages/img/icon-type4.svg) center no-repeat;
  }

  .td-info {
    max-width: 140px;
  }

  .td-info span, .td-info a {
    display: block;
    color: var(--Grayscale-Main-text, #333);
    line-height: 1.2;
    letter-spacing: -0.078px;
    text-wrap: normal;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-list th span {
    display: block;
  }

  .table-list thead tr th:nth-child(1) {
    padding-right: 10px;
    width: 60px;
  }

  .table-list thead tr th:nth-child(2) {
    width: 300px;
  }

  .table-list thead tr th:nth-child(3) {
    width: 35%;
  }

  .table-list tbody tr td {
    padding-right: 10px;
  }

  .modal-block input {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 14px 16px;
    width: 100%;
    border: none;
  }

  .search-blk .filter-blk .tag li {
    padding: 3px 12px;
  }

  .organization-content .add-photo img {
    border-radius: 8px;
  }

  .organization-content .add-photo {
    border: none;
  }

  .organization-content .avatar-photo {
    border-radius: 8px;
  }

  .custom-select {
    position: relative;
    border-radius: 4px;
    background: #FFF;
    margin-right: 28px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
  }

  .custom-select ul {
    display: none;
  }

  .selected-option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 16px;
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    color: #767676;
  }

  .selected-option:after {
    content: "";
    background: url(../../assets/newpages/img/icon-choise.svg) center / contain no-repeat;
    width: 15px;
    height: 9px;
    margin-left: auto;
  }

  .custom-select ul {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    background: #FFF;
    padding: 15px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }

  .custom-select ul li:not(:last-child) {
    margin-bottom: 15px;
  }

  .custom-select ul li:last-child {
    border-bottom: none;
  }

  .table-tarif td:nth-child(1) {
    width: 60px;
    text-align: center;
  }

  .table-tarif td:nth-child(1) a {
    margin: 0 auto;
  }

  .table-tarif td:nth-child(2) {
    width: 30%;
  }

  .table-md th:first-child {
    width: 60px;
  }

  .table-md td:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-md td {
    padding-right: 10px;
  }

  .arhiv .td-tag span {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
  }

  table td .btn {
    justify-content: flex-start;
  }

  .tariff-title h1:after {
    content: none;
  }

  .tables tbody ul.td-more-info {
    display: none;
  }

  .tables tbody ul.td-more-info.active {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    border-radius: 8px;
    border: 1px solid var(--Grayscale-Border, #DDD);
    background: var(--Grayscale-White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 16px 12px;
    z-index: 10;
    right: 0;
    top: 70px;
  }

  .tables tbody ul.td-more-info a {
    color: var(--Grayscale-Main-text, #333);
    font-size: 13px;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: -0.078px;
  }

  .tables td .btn-confirmed {
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    background: var(--Grayscale-Bg, #E9ECEF);
    color: var(--Grayscale-Border, #CED4DA);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: -0.078px;
  }

  .tables td .btn {
    font-size: 12px;
    padding: 0;
  }

  .edit-table .status .flex {
    justify-content: flex-end;
  }

  .edit-table .td-more {
    margin-left: 40px;
  }

  .create-status span.status2:before {
    background: url(../../assets/newpages/img/icon-type1.svg) center no-repeat;
  }


  .edit-create-block .create-blk textarea, .edit-create-block .create-blk input,
  .edit-create-block .create-select, .edit-create-block .create-blk .title,
  .edit-create-block .tag, .edit-create-block .input-blk, .edit-create-block .checks {
    opacity: 0.6;
  }

  .create-block .select-blk.active {
    width: calc(100% + 47px);
  }

  .create-blk {
    width: 100%;
  }

  .sozd .tabsmenu {
    border: none;
  }

  .sozd .tabsmenu li.active:after {
    content: none;
  }

  .sozd .tab-content .create-blk {
    gap: 0;
    margin-bottom: 24px;
  }

  .sozd .tab-content .create-choise-blk {
    margin: 8px 0;
  }

  .sozd .tab-content .create-choise-blk:last-child {
    margin-bottom: 0;
  }

  /*
  --------------------
  Styles for Adaptive
  --------------------
  */
  @media (max-width: 1399px) {
    .table-list thead tr th:nth-child(3) {
      width: 25%;
    }
    .table-otv td:nth-child(2) {
      width: 20%;
    }
  }

  @media (max-width: 1199px) {
    .table-tarif td:nth-child(2) {
      width: 15%;
    }
    .table-lg thead th:not(:first-child) {
      display: none;
    }
    .table-lg tbody tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 10px;
    }
    .table-lg tbody tr:not(:last-child) {
      border-bottom: 1px solid var(--Grayscale-Bg, #E9ECEF);
    }
    .table-lg tbody tr td {
      border: none;
      padding: 5px;
      position: relative;
      z-index: 2;
    }
    .table-lg td[data-label]:before {
      content: attr(data-label);
      margin-right: 10px;
      font-weight: 500;
    }
    .table-otv td {
      width: 50%;
    }
    .table-otv td:nth-child(1) {
      width: auto;
    }
    .table-otv td:nth-child(2) {
      width: 80%;
    }
  }

  @media (max-width: 991px) {
    .table-list thead th:not(:first-child) {
      display: none;
    }
    .table-list tbody tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 10px;
    }
    .table-list tbody tr:not(:last-child) {
      border-bottom: 1px solid var(--Grayscale-Bg, #E9ECEF);
    }
    .table-list tbody tr td {
      border: none;
      padding: 5px;
      position: relative;
      z-index: 2;
    }
    .table-list tbody tr td:nth-child(1) {
      order: 1;
    }
    .table-list tbody tr td:nth-child(2) {
      width: 65%;
      order: 2;
    }
    .table-list tbody tr td:nth-child(3) {
      width: 50%;
      min-width: 50%;
      order: 4;
    }
    .table-list td[data-label]:before {
      content: attr(data-label);
      margin-right: 10px;
      font-weight: 500;
    }
    .table-list tbody tr td:nth-child(4), .table-list tbody tr td:nth-child(5) {
      order: 5;
    }
    .table-list tbody tr td:nth-child(5) {
      margin-left: auto;
    }
    .table-list tbody tr td.status {
      order: 3;
      width: 25%;
    }
    .tables td.status span {
      justify-content: flex-end;
    }
    .search-blk .filter-blk {
      display: block;
    }
    .table-md thead th:not(:first-child) {
      display: none;
    }
    .table-md tbody tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 10px;
    }
    .table-md tbody tr:not(:last-child) {
      border-bottom: 1px solid var(--Grayscale-Bg, #E9ECEF);
    }
    .table-md tbody tr td {
      border: none;
      padding: 5px;
      position: relative;
      z-index: 2;
    }
    .table-md td[data-label]:before {
      content: attr(data-label);
      margin-right: 10px;
      font-weight: 500;
    }
    .table-tarif tbody tr td {
      width: 50%;
      padding: 5px 0;
    }
    .table-tarif td:nth-child(1) {
      width: 40px;
    }
    .table-tarif td:nth-child(2) {
      width: 90%;
    }
    .table-md td[data-label]:before {
      width: 100px;
    }
    .table-otv td {
      width: 100%;
    }
    table td .btn-arrow:after {
      width: 12px;
      height: 12px;
      background-size: contain;
    }
    .table-responses tbody tr {
      display: flex;
      flex-wrap: wrap;
    }
    .edit-table thead {
      display: none;
    }
  }

  @media (max-width: 575px) {
    .table-list tbody tr td:nth-child(2) {
      width: 85%;
    }
    .table-list tbody tr td:nth-child(3) {
      width: 70%;
      min-width: 70%;
      order: 3;
    }
    .table-list tbody tr td:nth-child(4) {
      width: 50%;
    }
    .table-list tbody tr td:nth-child(5) {
      order: 4;
    }
    .table-list tbody tr td.status {
      width: 50%;
      order: 7;
    }
    .table-tarif tbody tr td {
      width: 100%;
    }
    .table-tarif td:nth-child(2) {
      width: 85%;
    }
    .create-date .input-block {
      flex-wrap: wrap;
    }
    .create-date .input-blk {
      width: 100%;
    }
    .create-date .input-blk label {
      width: 80px;
    }
  }

  @media (max-width: 374px) {
    .table-list tbody tr td:nth-child(2), .table-tarif td:nth-child(2) {
      width: 80%;
    }
    .table-list tbody tr td:nth-child(3) {
      width: 100%;
      min-width: 100%;
    }
    .table-list tbody tr td:nth-child(4) {
      order: 4;
      width: 100%;
    }
    .table-list tbody tr td:nth-child(5) {
      margin: 0 auto 0 0;
    }
    .table-otv td:nth-child(2) {
      width: 75%;
    }
    .table-otv td[data-label]:before {
      display: block;
      width: 100%;
    }
    .create-block .tabsmenu {
      gap: 10px;
    }
  }
  //NEW styler 4
  .add-photo-org {
    .chosen_avatar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .logs-table .success,
  .logs-table .error,
  .logs-table .processed {
    color: white;
    padding: 5px;
    font-size: 12px;
    border-radius: 12px;
  }

  .logs-table .success {
    background: rgba(60, 182, 74, 1);

  }

  .logs-table .error {
    background: #b63c4a;

  }

  .logs-table .processed {
    background: #49A9BF;

  }
  .logs{
    display: flex;
    flex-direction: column;
    gap: 15px;
    p.log{
      border-bottom: dashed 2px #737373;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .status{
        font-weight: bold;
      }
      span:not(.status){
        background: rgba(182, 60, 74, 0.51);
        padding: 10px;
        border-radius: 12px;
        color: white;
      }
    }
  }

}


.tariffstl {
  @import "../styles/components/tariff.scss";
}

//MODAL TARIFF

//END MODAL TARIFF
.tags li{
  cursor: pointer;
}
.autoWidth {
  .modal_content {
    min-width: auto !important;
    max-width: available !important;
    min-height: auto !important;
  }

  @media screen and (max-width: 824px) {
    .MuiPaper-root {
      padding: 50px 20px;
    }
  }
}

.delete_career .MuiPaper-root {
  .modal_content {
    min-width: auto !important;
    max-width: available !important;
    min-height: auto !important;

    .delete_modal {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 90px;
      justify-content: space-between;
      .button_block {
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }
  }

}

.vacancy-info {
  .vacancy-name {
    max-width: 70%;
  }

  .details {
    margin-top: 20px;
  }
}

.vacancy-name-view, .vacancy-name-list {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  h3 {

  }
}

.vacancy-name-table {
  max-width: 200px;

}

.offer-content-block, .vacancy-name-table {
  .name {

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.student-vacancy-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
}

.student-vacancy-block {
  .vacancy-info {

    justify-content: space-between;
    height: 70px;
    .create-blk{
      white-space: nowrap;
    }
  }
}

.td-more-info {
  z-index: 2;
}

.gap-5 {
  gap: 5px;
}

.gap-15 {
  gap: 15px;
}
.gap-25 {
  gap: 15px;
}

.list-company {
  .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 740px) {
      flex-direction: column-reverse;
    }

    .edit-profile {
      flex: 1;
    }
  }

}

.change-tarif {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 500px;

  &__success {
    max-width: 200px;
    gap: 25px;
  }

  &__inp {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  textarea {
    border-radius: 20px;
    padding: 16px;
    background: var(--Grayscale-Bg, #E9ECEF);
    border: none;
    width: 100%;
    resize: none;
    min-height: 184px;
  }

  table th {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
  }

  table td {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.078px;
    padding: 10px 0;
  }

  .table-btn {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .btn {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 14px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-grey {
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    background: var(--Grayscale-Bg, #E9ECEF);
    color: var(--Grayscale-Main-text, #333);
  }

  .btn-blue {
    color: #ffffff;
    border: 2px solid var(--Acents-Main-acent, #49A9BF);
    background: var(--Acents-Main-acent, #49A9BF);
    font-weight: 700;
  }


  .btn {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 14px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-blue {
    color: #ffffff;
    border: 2px solid var(--Acents-Main-acent, #49A9BF);
    background: var(--Acents-Main-acent, #49A9BF);
    font-weight: 700;
  }

}

.change-tarif span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.374px;
  margin-bottom: 8px;
  display: block;
}

.change-img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.change-info p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.change-info span {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.374px;
  display: block;
  margin-bottom: 12px;
}

.pass-input {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  input {
    width: 100%;
  }

  .hidePass {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 15px;
  }
}

@import "student_profile";


.crateStudentModal {
  .mail-student {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  }

  .modal-footer {
    margin-top: 10px !important;

    a {
      padding: 0;
    }
  }

  /*
------------------------
NEW STYLE FOR MODAL
-----------------------
*/
  .big-modal {
    max-height: 90%;
    overflow: auto;
  }

  .modal-block .modal-footer button {
    font-weight: bold;
  }

  .logi-blk h3 {
    margin: 24px 0;
  }

  .logi-blk img {
    width: 100%;
  }

  .modal-add-blk {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .modal-block .tab-content .adds p {
    margin-bottom: 0;
  }

  .modal-block .tab-content .adds p span {
    display: inline;
    font-weight: bold;
    min-height: 0;
  }

  .modal-block .tab-content .modal-add-blk .adds {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 106px;
  }

  .modal-block .tab-content .modal-add-blk .adds:before {
    content: "";
    background: url(img/add.svg) center no-repeat;
    width: 40px;
    height: 40px;
  }

  .modal-block .tab-content .adds p span:before {
    content: none;
  }


  /*
  ------------------------
  NEW Styles for Adaptive
  -----------------------
  */
  @media (max-width: 575px) {
    .modal-block .tab-content .adds span {
      min-height: 60px;
    }
    .modal-block .tab-content .modal-add-blk .adds {
      min-height: 76px;
    }
  }
}
.edit-table{
  margin-top: 20px;
  tr th{
    text-align: center !important;
  }
  tbody tr td{
    vertical-align: baseline !important;
  }
  .search-blk{
    padding: 5px;
    height: 100%;
    .error{

    }
  }
  @media (max-width: 1150px) {
    .search-blk:before{
      content: attr(aria-label);
    }

    thead{
      display: none;
    }
    max-height: max-content !important;
    height: max-content !important;
    display: block;
    tbody{
      gap: 15px;
      height: max-content !important;
      max-height: max-content !important;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    tbody tr td:first-child{
      flex-basis: 100%;
    }
    tbody tr td:not(:first-child){
      flex-basis: 50%;
      @media (max-width: 500px) {
        flex-basis: 100%;
      }
    }
    tbody tr{
      padding: 10px;
      border: 1px solid #b2b2b2;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
.flex{
  display: flex !important;
  &-col{
    flex-direction: column !important;
  }
}
.space-between{
  justify-content: space-between;
}

.careen-load {
  display: block;
  position: relative;
  background: #FFF;
  box-sizing: border-box;
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image:
    linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%),
    linear-gradient(#DDD 56px, transparent 0),
    linear-gradient(#DDD 24px, transparent 0),
    linear-gradient(#DDD 18px, transparent 0),
    linear-gradient(#DDD 66px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: career-animloader 1s linear infinite;
  }
}
@keyframes career-animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}


.loader-product {
  background:
          linear-gradient(0.25turn, transparent, #FFF, transparent),
          linear-gradient(#DDD, #DDD),
          linear-gradient(#DDD, #DDD);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%, 90% 80%, 100px 15px;
  background-position: -215px 0, 50% 60%, 20px 20px;
  animation: loader-product 1.5s infinite;
  .image{
    background: transparent !important;
    border: none !important;
    width: 100%;
    aspect-ratio: 1/1;
  }
}
.load-company{
  display: block;
  position: relative;
  background: #FFF;
  box-sizing: border-box;
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image:
            linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%),
            linear-gradient(#DDD 56px, transparent 0),
            linear-gradient(#DDD 24px, transparent 0),
            linear-gradient(#DDD 18px, transparent 0),
            linear-gradient(#DDD 66px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: career-animloader 1s linear infinite;
  }
}
@keyframes loader-product {
  to {
    background-position: 215px 0, 50% 60%, 20px 20px;
  }
}
.user-loader {
  display: block;
  margin: auto;
  height: 90px;
  li{
    height: 100%;
  }
  position: relative;
  background: #FFF;
  box-sizing: border-box;
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image:
            linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%),
            linear-gradient(#DDD 55px, transparent 0),
            linear-gradient(#DDD 24px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 55px 56px, 260px 30px, 260px 20px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: user-loader 1s linear infinite;
  }
}

@keyframes user-loader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}

.products-table{
  th:first-child,td:first-child{
    min-width: 0 !important;
  }
}
.td-tag{
  position: relative;
}
.td-tag  button{
  color: var(--Grayscale-Main-text, #333);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.374px;
  display: inline-block;
  border-radius: 8px;
  padding: 4px 12px;
  background: var(--Acents-Tags, linear-gradient(0deg, rgba(73, 169, 191, 0.40) 0%, rgba(73, 169, 191, 0.40) 100%), #FFF);
}
.td-tag.needReview button{
  background: var(--Acents-Additional-Additional-acent-1, #1B4980) !important;
  color: #FFF !important;
}
.td-tag.disable button{
  background: var(--Grayscale-Main-text, #333) !important;
  color: #FFF !important;
}
.modules-tab-2 .modul-image{
  padding-bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  img{
    margin-bottom: -20px;
    max-height: max-content;
  }
}


.mess-load{
    width: 100%;
  height: 75px;
    display: block;
    margin: auto;
    position: relative;
    background: #FFF;
    box-sizing: border-box;
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image:
          linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%),
          radial-gradient(#DDD 45px, transparent 0),
          linear-gradient(#DDD 12px, transparent 0),
          linear-gradient(#DDD 18px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 45px 45px, 160px 20px, 260px 50px, 290px 46px;
    background-position: 0% 0, 0 0, 55px 5px, 55px 20px, 0px 66px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }

  @keyframes animloader {
    0% {
      background-position: 0% 0, 0 0, 55px 5px, 55px 20px, 0px 66px;
    }
    100% {
      background-position: 150% 0, 0 0, 55px 5px, 55px 20px, 0px 66px;
    }
  }
}
.customTable{
  @media (min-width: 1200px) {
    min-width: auto !important;
    table{
      min-width: auto !important;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    thead tr{
      display: flex;
      flex-direction: row;
      th{
        text-align: center !important;
        flex: 1;
        width: auto;
        flex-basis: 100% !important;
      }
      th:first-child{
        width:50px;
        max-width: 50px;
      }
    }
    tr,thead{
      width: 100%;
    }
    tbody{
      display: flex;
      flex-direction: column;
      tr{
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      tr td:first-child{
        max-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      tr td{
        text-align: center !important;
        flex: 1;
        width: auto;
        flex-basis: 100% !important;
      }
    }

  }

}
.buttons_block-right{
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 15px;
}
.product.add-photo img{
  position: absolute;
}
.create-auction-modal{
  display: flex;
  flex-direction: column;
  gap: 15px;
  .search-blk{
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
  .dates{
    display: flex;
    flex-direction: row;
    gap: 15px;
    .input-blk{

    }
  }
  .buttons_block{
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 15px;
  }
  .input-block{
    width:100%;
  }
}
.career_list{
  display: flex;
  flex-direction: column !important;
  a{
    width: 100%;
  }
  div.list_info {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .vacancy-info{
      display: flex;
      flex-direction: row !important;
      width: 100%;
      justify-content: space-between !important;
    }
  }
}
.products-list-modal{
  flex-direction: row !important;
  flex-wrap: wrap;
  .list_item{
    width: 100px;
    height: 220px;
    justify-content: space-between;
    p.description{
      max-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      line-clamp: 1;
      text-overflow: ellipsis;
    }
    button{
      width: 100%;
      max-width: 100%;
      font-size: 12px !important;
    }
  }
}
.student-career-list{
  display: flex;
  flex-direction: column !important;
  .list_item{
    width: 100% !important;
    a{
      width: 100% !important;
      display: flex;
      flex-direction: column !important;
      align-items: center;
      .vacancy-info {
        display: flex;
        width: 100%;
        flex-direction: row !important;
      }
      .list_info ,.image,img{
        width: 100%;
      }

    }
  }
}
.products-list{
  .list_item{

    a{

      display: flex;
      flex-direction: column !important;
      align-items: center;
      gap: 10px;
      .image,img{
        width: 140px;
        height: 140px;
      }

    }
  }
}
.student-rsv{
 @media (max-width: 510px) {
   .y-axis{
     position: relative !important;
     flex-direction: row-reverse !important;
     height: 50px !important;
     .level{
       flex:.2;
     }
   }
   .chart-block{
     padding-left: 0 !important;
     display: flex !important;
     flex-direction: column;
   }
   .chart-description{
     width: 100% !important;
     margin-left: 0 !important;
   }
   .chart-blk{
     width: 100% !important;
     margin:  0 !important;
   }
   .chart-blk #myChart3{
     width: 100%;
     flex-direction: column !important;
   }
   #myChart3{
     height: auto;
     div{
       position: relative;
       width: 100%;
     }
     .bar-progress{
       height: 100%;
       width: 100%;
     }
     .bar {
       height: 20px;
     }
   }
 }
}
#my-tooltip{
  z-index: 20;
}
.filtered_page .sort_menu{
  @media (max-width: 400px) {
    position: relative;
    overflow: visible;
    margin-bottom: 60px;
    .custom_select{
      min-width: auto;
    }
    .sorts{
      width: 100%;
    }
    flex-direction: column;
    .filter_button{
      bottom: -50px;
      right: 0;
      position: absolute;
    }
  }
}
.student-career-tr{
  @media (max-width: 575px) {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    td{
      flex-basis: 30%;
    }
    td.create-status_{
      margin-right: 0 !important;
    }
  }

}
.bid_place{
  @media (max-width: 460px) {
    .bid_info{
      display: flex;
      flex-direction: column !important;
      gap:15px !important;
    }
  }
}
.footer-apps{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}