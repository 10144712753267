@import "../variables";

.checkbox {
  width: 100%;
  .checkbox_input {
    display: none;
  }

  .checkbox_input:checked + .checkbox_label::after {
    transform: scale(1);
  }

  .checkbox_input:checked + .checkbox_label::before {
    background-color: $turquoise;
    transition: 0.2s background-color ease;
  }

  .checkbox_label {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 130%;
    width: 100%;
    cursor: pointer;

    &::before {
      content: "";
      flex-shrink: 0;
      flex-basis: 16px;
      height: 16px;
      align-self: flex-start;
      background-color: $white;
      border: 1px solid $gray_border;
      border-radius: 4px;
      margin-right: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url("../../images/checkbox.svg");
      background-position: 0 0;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 3px;
      transform: scale(0);
      transition: .2s transform ease;
    }
  }
}
