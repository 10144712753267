@import "../variables";

.list_from_company_profile {
  .add_vacancy_block {
    border: 1px solid $gray_border;
    border-radius: 16px;
    padding: 25px;
    font-size: 14px;

    .buttons_block {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      margin-top: 20px;
      &-right{
        border:1px solid red;
      }
    }

    .new_vacancy_info {
      display: flex;
      gap: 20px;

      .load_block {
        width: 120px;
        height: 120px;
        flex-direction: column;
        justify-content: center;
      }

      .vacancy_info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;

        .form_field, .textarea {
          padding: 10px 15px;
        }

        .textarea {
          height: 50px;
        }
      }
    }
  }


  .list_items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;
  }

  .list_item {
    display: flex;
    gap: 16px;

    .image {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      border-radius: $radius;
      overflow: hidden;
      background-color: $gray_background;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .list_info {
      font-size: 14px;

      & > *:not(:last-child) {
        margin-bottom: 8px;
      }

      .name {
        font-weight: 700;
      }

      .status {
        padding: 4px 12px;
        border-radius: $radius;
        color: $gray_text;
        font-weight: 500;
        font-size: inherit;
        background-color: $gray_background;
      }
    }
  }
}
