@import "../variables";
@import "../mixins";

.profile_editor {
  @include tooltip;

  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .shadow_block {
    padding: 40px;
    border-radius: 16px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .change_avatar_block {
    .change_avatar {
      display: flex;
      gap: 32px;
    }

    .chosen_avatar {
      position: relative;
      flex-shrink: 0;
      width: 250px;
      height: 250px;
      background-color: $gray_background;
      border-radius: $radius;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;
      }
    }

    .change_form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .form_block {
      h3 {
        margin-bottom: 10px;
      }
    }

    .personage_list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .avatar {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: $radius;
      overflow: hidden;
      background-color: $gray_background;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.selected {
        border: 3px solid $dark_gray;
      }
    }
  }

  .choice_profession {
    font-size: 14px;

    .i-hint {
      vertical-align: middle;
      margin-left: 8px;
      cursor: pointer;
    }

    .tooltip {
      line-height: 1.2;
    }

    .bubble_container {
      .bubble {
        padding: 10px 20px;
        font-size: 14px;

        .i-close {
          vertical-align: middle;
          margin-left: 15px;
          cursor: pointer;
        }
      }

      .bubble_unchanged {
        background-color: $gray_background;
        color: $gray_text;
      }
    }

    .search_line {
      margin-top: 25px;
    }
  }

  .change_description {
    .textarea {
      width: 100%;
    }

    .field:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .responsible_person {
    .fieldset {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    .field_item:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .buttons_block {
    &-right{
      border:1px solid red;
    }
    display: flex;
    gap: 20px;
    justify-content: flex-end;

  }

  @include mediaMaxHeightAndPortrait(900px) {
    .buttons_block {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 10px;
      padding-bottom: 20px;
    }

    .shadow_block {
      padding: 10px;
    }

    .change_avatar {
      flex-direction: column;
      align-items: center;
    }

    .chosen_avatar {
    /*  width: 90vw !important;
      height: 90vw !important;*/
    }
  }
}
