@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.add_product {
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .product_block {
    display: flex;
    gap: 40px;
  }

  .image {
    min-width: 300px;
    width: 300px;
    height: 300px;
    border-radius: $radius;
    background-color: $gray_background;
    border: 1px solid $gray_background;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info_block {
    flex-grow: 1;

    & > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @include mediaMaxWidth(800px) {
    .product_block {
      flex-direction: column;
    }
  }

  @include mediaMaxWidth(600px) {
    .image {
      min-width: 270px;
      width: 270px;
      height: 270px;
    }

    .form_field, .textarea {
      padding: 12px;
      font-size: 14px;
    }

    .inline_buttons {
      flex-direction: column-reverse;
    }
  }

  @include mediaMaxWidth(400px) {
    .image {
      min-width: 200px;
      width: 200px;
      height: 200px;
    }
  }
}
