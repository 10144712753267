@import "../variables";
@import "../mixins";

.product_item {

  .tab_list {
    margin-bottom: 32px;
  }

  .image {
    min-width: 300px;
    width: 300px;
    height: 300px;
    border-radius: $radius;
    overflow: hidden;
    background-color: $gray_background;
    border: 1px solid $gray_background;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .flex_block {
    display: flex;
    gap: 40px;
  }

  @media (max-width: 800px) {
    .flex_block {
      flex-direction: column;
    }

    .image {
      width: 100%;
      height: auto;

      display: flex;
      justify-content: center;

      img {
        max-width: 550px;
        object-fit: contain;
      }
    }
  }

  .main_info {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;

    h2 {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  .product_info {
    display: flex;
    gap: 24px;

    div {
      width: 100%;
    }

    .info_title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .shadow_block {
    padding: 16px 24px;
    border-radius: 16px;
  }

  .flex_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
  }

  .auction_item {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .bubble {
      font-size: 14px;
      padding: 6px 16px;
      opacity: 0.8;


    }

    .auction_info {
      display: flex;
      justify-content: flex-end;
      gap: 40px;

      div:first-child {
        margin-right: auto;
      }

      .title {
        font-weight: 500;
        margin-bottom: 4px;
      }

      @media (max-width: 500px) {
        flex-direction: column;
        gap: 30px;

        .title {
          margin-bottom: 10px;
        }
      }
    }
  }

  .product_name {
    font-size: 24px;
    margin-top: 24px;
  }

  .bid_list {
    margin-bottom: 24px;

    .rating_list {
      margin-top: 16px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 20px;
    }
  }

  .bid_place {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .bid_info {
      display: flex;
      gap: 60px;
      justify-content: space-between;
      font-size: 14px;

      p:first-child {
        margin-bottom: 8px;
      }
    }
  }

  .field {
    margin-top: 10px;
  }

  .submit_button {
    margin-top: 20px;
  }
}

//.product_item {
//  & > *:not(:last-child) {
//    margin-bottom: 30px;
//  }
//
//  .product_block {
//    display: flex;
//    gap: 40px;
//  }
//
//  .image {
//    min-width: 300px;
//    width: 300px;
//    height: 300px;
//    border-radius: $radius;
//    overflow: hidden;
//    background-color: $gray_background;
//    border: 1px solid $gray_background;
//
//    img {
//      width: 100%;
//      height: 100%;
//      object-fit: cover;
//    }
//  }
//
//  .info_block {
//    flex-grow: 1;
//
//    & > *:not(:last-child) {
//      margin-bottom: 24px;
//    }
//  }
//
//  .auctions {
//    h2 {
//      margin-bottom: 30px;
//      cursor: pointer;
//
//      &::after {
//        margin-left: 4px;
//        font-size: 0.6em;
//        vertical-align: middle;
//      }
//    }
//  }
//
//  .auction_list {
//    overflow: hidden;
//    transition: 5s all ease;
//
//    &.hide {
//      height: 0;
//      transition: 5s all ease;
//    }
//
//    & > *:not(:last-child) {
//      margin-bottom: 16px;
//    }
//  }
//
//  .auction_item {
//    p {
//      font-weight: 500;
//
//      span {
//        margin-left: 4px;
//      }
//    }
//
//    & > *:not(:last-child) {
//      margin-bottom: 10px;
//    }
//
//    .rate_value {
//      font-size: 14px;
//      font-weight: 500;
//
//      &::after {
//        margin-left: 4px;
//        font-size: 0.8em;
//      }
//    }
//
//    &.closed {
//      opacity: 0.5;
//      color: $gray_text;
//
//      .bubble {
//        background-color: $gray_background;
//        color: $gray_text;
//      }
//
//      .secondary_button {
//        background-color: $gray_background;
//        color: $gray_text;
//      }
//    }
//  }
//
//  .auction_title {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    gap: 20px;
//    flex-wrap: wrap;
//
//    .number {
//      font-size: 16px;
//
//      span {
//        font-weight: 700;
//        font-size: 22px;
//      }
//    }
//  }
//
//  @include mediaMaxWidth(800px) {
//    .product_block {
//      flex-direction: column;
//    }
//
//    .inline_buttons > * {
//      padding: 12px 20px;
//    }
//
//    .auction_item {
//      .form_field {
//        padding: 12px;
//      }
//
//      .secondary_button {
//        font-size: 14px;
//        padding: 12px 20px;
//      }
//    }
//  }
//
//  @include mediaMaxWidth(600px) {
//    .image {
//      min-width: 270px;
//      width: 270px;
//      height: 270px;
//    }
//
//    .form_field, .textarea {
//      padding: 12px;
//      font-size: 14px;
//    }
//
//    .inline_buttons {
//      flex-direction: column-reverse;
//    }
//
//    .auction_item {
//      .form_field {
//        width: 100%;
//      }
//
//      .field.horizontal {
//        flex-wrap: wrap;
//      }
//    }
//
//  }
//
//  @include mediaMaxWidth(400px) {
//    .image {
//      min-width: 200px;
//      width: 200px;
//      height: 200px;
//    }
//  }
//}
