@import "mixins";
@import "variables";

@font-face {
  font-family: 'Unbounded';
  src: url("../fonts/unbounded/unbounded-regular.woff2"),
  url("../fonts/unbounded/unbounded-regular.woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Unbounded';
  src: url("../fonts/unbounded/unbounded-medium.woff2"),
  url("../fonts/unbounded/unbounded-medium.woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Unbounded';
  src: url("../fonts/unbounded/unbounded-bold.woff2"),
  url("../fonts/unbounded/unbounded-bold.woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url("../fonts/ubuntu/ubuntu-regular.woff"),
  url("../fonts/ubuntu/ubuntu-regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url("../fonts/ubuntu/ubuntu-medium.woff"),
  url("../fonts/ubuntu/ubuntu-medium.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url("../fonts/ubuntu/ubuntu-bold.woff"),
  url("../fonts/ubuntu/ubuntu-bold.woff2");
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  max-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

button {
  background-color: inherit;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

h1, h2, h3 {
  margin: 0;
}

h1 {
  font-weight: 700;
}

h2 {
  font-size: 16px;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  font-weight: 500;
}

input, select, textarea {
  outline: none;
  border: none;
  font-family: inherit;
  background-color: inherit;
}

table {
  border-collapse: collapse;
}

::selection {
  background-color: $turquoise_transparent;
  color: $white;
}

.flex-all-center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;

  &.horizontal {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.checkbox_field {
  display: flex;
  flex-direction: row;
}

.form_field {
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  background-color: $gray_background;
  color: $dark_field_gray;
  padding: 16px;
  border-radius: $radius;

  &::placeholder {
    color: $gray_text;
  }

  &:focus {
    border-bottom-width: 2px;
  }
}

.textarea {
  background-color: $gray_background;
  resize: none;
  color: $dark_field_gray;
  border-radius: $radius;
  height: 160px;
  padding: 16px;
  font-size: inherit;

  &::placeholder {
    color: $gray_text;
  }
}

.scroll_element {
  &::-webkit-scrollbar {
    padding: 10px;
    background-color: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #DDDDDD;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ADADAD;
  }
}

.main_button {
  display: inline-block;
  border-radius: $radius;
  padding: 12px 40px;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.secondary_button {
  display: inline-block;
  border-radius: $radius;
  padding: 12px 40px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  white-space: normal;

  &::before {
    vertical-align: middle;
    margin-right: 0.4em;
  }

  &::after {
    vertical-align: middle;
    margin-left: 0.4em;
  }
}

.disabled_button {
  color: $gray_border;
  background-color: $gray_background;
}

.submit_button {
  font-family: inherit;
  background-color: $dark_gray;
  color: $white;
  border-radius: $radius;
  padding: 10px 20px;
  cursor: pointer;
}

.reset_button {
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.rating_item {
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  border-bottom: 1px solid $gray_border;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .number {
    margin-right: 10px;
    white-space: nowrap;
    font-size: 30px;
    font-weight: 700;
    color: $gray_text;
    transition: max-width 2s, opacity 1s;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
  }

  &:hover {
    .number {
      max-width: 100px;
      opacity: 1;
      padding-right: 1px;
    }
  }

  .student_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0;
    gap: 16px;
  }

  .avatar {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 16px;
    border: 1px solid $light_gray;
  }

  .student_info {
    align-self: flex-start;
    margin-top: 0.5em;

    p {
      margin: 0;
    }

    p:first-child {
      font-size: 17px;
      font-weight: 600;
    }
  }

  .points {
    margin-left: auto;
    font-size: 36px;
    font-weight: 700;
  }
}

.list {
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  overflow: hidden;

  th {
    background-color: #EFEFEF;
    font-weight: 500;
  }

  td, th {
    text-align: left;
    padding: 10px 20px;
  }

  .empty_list {
    text-align: center;
  }

  tbody .line:not(:first-child) {
    border-top: 1px solid #DDDDDD;
  }

  tbody .line:last-child {
    border-bottom: 1px solid #DDDDDD;
  }
}

.bubble {
  display: inline-block;
  color: $bubble_border !important;
  background-color: $bubble_back !important;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: $radius;
  border: 1px solid $bubble_border;
}

.bubble_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    display: inline-block;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  border: none;
}

.dark_gray_button {
  color: $white;
  background-color: $dark_gray;
}
.positive_button {
  color: $white;
  background-color: $green;
}
.turquoise_button {
  color: $white;
  background-color: $turquoise;
  &:hover{
    background-color: $turquoise_hover;
  }
}

.gray_button {
  background-color: $gray_background;
  color: $gray_text;
}

.white_button {
  color: $dark_gray;
  background-color: $white;
}

.tab_list {
  display: flex;
  position: relative;
  gap: 16px;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    height: 2px;
    width: 100%;
    background-color: $gray_border;
    border-radius: 1px;
  }

  li {
    position: relative;
    padding-right: 50px;
    cursor: pointer;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 3px;
      width: 0;
      background-color: $dark_gray;
      border-radius: 2px;
      z-index: 2;
      opacity: 0;
    }
  }

  li.selected {
    &::after {
      opacity: 1;
      width: 100%;
      transition: 0.2s all ease;
    }
  }
}

.shadow_block {
  border-radius: $radius;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
}

.menu_button {
  position: relative;
  cursor: pointer;

}

.menu_button:hover {
  .hidden_menu {
    display: block;
  }
}

.hidden_menu {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1.5em;
  display: none;
  z-index: 100;

  .hidden_menu_content {
    background-color: $white;
    min-width: 180px;
    width: max-content;
    max-width: 250px;
    padding: 16px;
    border: 1px solid $gray_border;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: $radius;
    font-size: 12px;


    li {
      display: flex;
      cursor: pointer;
    }

    li:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.inline_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $gray_text;
}

.icon_text {
  font-size: inherit;
  display: flex;
  gap: 0.5em;
  align-items: center;

  &.icon_small {
    &::before, &::after {
      font-size: 0.8em;
    }
  }

  &.icon_large {
    &::before, &::after {
      font-size: 1.2em;
    }
  }

  &.bold {
    font-weight: 500;
  }
}

.variants_block, .bordered_block {
  padding: 16px;
  border: 1px solid $gray_border;
  border-radius: $radius;
  font-size: 14px;
}

.variants {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 5px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.no_wrap {
  white-space: nowrap;
}

.filtered_page {
  .custom_select {
    font-size: 14px;
  }

  .filtered_page_block {
    display: flex;
    gap: 64px;
  }

  .search_line {
    margin-top: 30px;
  }

  .filtered_page_content {
    flex-grow: 1;
    font-size: 13px;
  }

  .sort_menu {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 15px;
    margin-bottom: 20px;

    .sorts {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      gap: 20px;
    }

    .custom_select {
      width: calc((100% - 20px)/2);
      min-width: 150px;
    }

    .sort_item {
      min-width: 150px;
      display: flex;
      gap: 7px;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .filter_button {
    margin-top: 0.5em;
    font-size: 1.5em;
    display: none;
  }

  .filter_block {
    min-width: 280px;
    width: 32%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: $white;

    h3 {
      margin-bottom: 10px;
    }

    & > *:not(:last-child) {
      margin-bottom: 0px;
    }

    .filter_header {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: flex-end;

      p {
        display: none;
        font-size: 14px;
        cursor: pointer;

        & > * {
          font-weight: 500;
        }

        &::after {
          vertical-align: middle;
          font-size: 0.8em;
          margin-left: 4px;
        }
      }
    }

    h2 {
      font-size: 25px;
    }

    .button_block {


      input {
        cursor: pointer;
      }

      & > input:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.page {
  h1 {
    font-size: 36px;
    margin: 0;
  }

  .search_line {
    .form_field {
      font-weight: 500;
    }
  }


}


@include mediaMaxWidth(1200px) {
  .page {
    h1 {
      font-size: 30px;
    }
  }

  .filtered_page {
    .filtered_page_block {
      gap: 45px;
    }

    .filter_block {
      min-width: 220px;
      width: 220px;
    }

    .search_line {
      margin-top: 24px;
    }
  }
}

@include mediaMaxWidth(1000px) {
  .filtered_page {
    .search_line {
      margin-top: 20px;

      .form_field {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .filter_block {
      & > *:not(:last-child) {
        margin-bottom: 0px;
      }

      .filter_header {
        h2 {
          font-size: 22px;
        }
      }
    }

    .main_button {
      font-size: 16px;
      padding: 10px 40px;
    }
  }
}

@include mediaMaxWidth(800px) {
  .filtered_page {
    .filter_button {
      display: block;
    }

    .filter_block {
      position: absolute;
      right: -100%;
      width: 100%;
      height: 100vh;
      padding: 32px;
      transition: 0.5s all ease;

      .filter_header {
        h2 {
          font-size: 20px;
        }

        p {
          display: block;
        }
      }
    }

    .filter_block.opened {
      right: 0;
      border-top-left-radius: 24px;
    }
  }
}

@include mediaMaxWidth(600px) {
  .page {
    h1 {
      font-size: 24px;
    }
  }

  .filtered_page {
    .search_line {
      margin-top: 15px;

      .form_field {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 12px;
      }
    }


    .sort_menu {
      margin: 10px 0 15px 0;
      gap: 12px;

      .sorts {
        flex-direction: column;
        gap: 12px;
      }

      .custom_select {
        width: 100%;
        font-size: 12px;
      }

      .sort_item {
        font-size: 10px;
        min-width: 100px;

        &::before {
          display: none;
        }
      }
    }

    .filter_block {
      padding: 24px;
      height: auto;

      &.opened {
        border-bottom-left-radius: 24px;

        box-shadow: 0 0 10px #00000060;
      }

      .filter_header {
        h2 {
          font-size: 18px;
        }

        p {
          font-size: 12px;
        }
      }

      .custom_select {
        font-size: 12px;
      }

      .main_button {
        font-size: 14px;
      }
    }
  }
}
.offer-content-block {
  border-radius: 8px;
  padding: 16px 24px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.offer-content-block .title {
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0.374px;
  display: block;
  margin-bottom: 8px;
}

.offer-content-block h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 10px;
}

.offer-content-block .details {margin-top: auto;}

.wrap-block {
  flex-wrap: wrap;
  margin: 0 -8px;
  gap: 16px 0;
  align-items: stretch;
}

.wrap-block .col-block {
  padding: 0 8px;
}
@include mediaMaxWidth(400px) {
  .filtered_page {
    .sort_menu {
      .sorts {
        flex-direction: column;
      }
    }
  }
}


