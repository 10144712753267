@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.catalog {
  h1 {
    margin-bottom: 30px;
  }

  .catalog_list > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .catalog_block {
    border: 1px solid #DDDDDD;
    border-radius: 24px;
    padding: 25px;
  }

  .top_block {
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 25px;
  }

  .collapse_block {
    h3 {
      margin: 0;
    }
    .list {
      display: none;
    }
  }

  .delete_modal {
    display: flex;
    flex-direction: column;
    max-width: 300px;

    h2, p {
      margin-top: 0;
    }

    .button_block {
      justify-content: center;
    }
  }
}
