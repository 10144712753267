/*
--------------------
Base styles
--------------------
*/
.error-page {
  font-family: "ubuntu", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*
---------------------------
styles for 404-505
---------------------------
*/
.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}

.error-page h1 {
  font-size: 250px;
  margin: 0;
}

.error-image {width: 50%;}
.error-image img {max-width: 100%;}

.error-content {
  width: 44%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

.error-content span {
  font-size: 32px;
}

.back-home {
  background: #FFFFFF;
  color: #333333;
  padding: 17px 41px;
  text-decoration: none;
  font-weight: 600;
  margin-top: 50px;
  border-radius: 8px;
  font-size: 18px;
}



/*
--------------------
Styles for Adaptive
--------------------
*/
@media (max-width: 1199px) {
  .error-page h1 {font-size: 150px;}
  .error-content span {font-size: 24px;}
}

@media (max-width: 991px) {
  .error-page h1 {font-size: 100px;}
  .error-content span {font-size: 20px;}
  .back-home {margin-top: 30px; padding: 14px 30px; font-size: 16px;}
  .error-content {font-size: 15px;}
  .error-block {padding: 20px;}
}

@media (max-width: 767px) {
  .error-page {height: calc(100vh - 64px);}
  .error-page h1 {font-size: 70px;}
  .error-content span {font-size: 18px;}
  .error-content, .back-home {font-size: 14px;}
}

@media (max-width: 575px) {
  .error-block {flex-direction: column; align-items: center;}
  .error-page h1 {font-size: 150px;}
  .error-content span {font-size: 24px;}
  .error-content {font-size: 14px;}
  .back-home {padding: 15px 30px; width: 100%; max-width: 320px;}
  .error-block > div {width: 100%;}
  .error-content {max-width: 400px;}
  .error-image img {width: 100%; max-width: 300px; margin: 0 auto; display: block;}
  .error-page {height: auto; min-height: calc(100vh - 64px);}
  .error-block {padding: 20px; gap: 20px;}
}

@media (max-width: 374px) {
  .error-page h1 {font-size: 100px;}
  .error-content span {font-size: 20px;}
  .error-content {font-size: 13px;}
}