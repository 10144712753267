@import "../variables";

.custom_select {
  &.disabled {
    opacity: 0.4;
  }



  .select__control {
    min-height: 40px;
    border: none;
    background-color: $gray_background;
    border-radius: $radius;
    padding: 2px 10px;
  }

  .select__control:hover {
    border-color: inherit;
  }

  .select__control--is-focused {
    box-shadow: none;
  }

  .select__placeholder {
    color: $gray_text;
  }

  .select__value-container {
    padding-left: 0;
  }

  .select__multi-value__label {
    color: inherit;
  }

  .select__multi-value {
    border-radius: 10px;
    font-size: inherit;
  }

  .select__multi-value__remove:hover {
    background-color: inherit;
    border-radius: inherit;
    color: inherit;
    cursor: pointer;
  }

  .select__indicators {
    cursor: pointer;
  }

  .select__indicator {
    padding: 3px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu-list {
    &::-webkit-scrollbar {
      padding: 10px;
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #DDDDDD;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #ADADAD;
    }

    .select__option:hover {
      background-color: $turquoise;
    }
  }
}
