@import "../variables";

.feedback {
  font-size: 14px;

  h1 {
    font-size: 25px;
  }

  .table_block {
    .table_headers td {
      font-size: 12px;
      font-weight: 500;
    }

    tr td:not(:last-child) {
      padding-right: 20px;
    }

    .table_main_info td {
      padding-top: 8px;
    }
  }

  .student_info {
    margin-top: 8px;
    padding: 24px;
  }

  .student_name {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 24px;
  }

  .company_info {
    margin-top: 32px;
  }

  .all_competencies_mark {
    margin-top: 32px;
    display: flex;
    gap: 24px;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      padding: 4px 0;

      &::before {
        font-size: 1.5em;
        margin-right: 8px;
      }
    }
  }

  .competencies_mark {
    margin-top: 32px;
    font-size: 12px;

    .list {
      td {
        font-weight: 700;
      }

      tbody td:not(:first-child) {
        font-size: 16px;
      }
    }

    .mark_block {
      display: flex;
      gap: 16px;

      .bubble_label {
        padding: 10px;
      }

      .icon {
        display: flex;
        align-items: center;
        font-size: 1.5em;
      }
    }
  }

  .button_block {

    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .main_button {
      width: auto;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
