@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.rating {
  .rating_list {
    padding: 0;
  }

  @include mediaMaxWidth(1200px) {
    .rating_item {
      .points {
        font-size: 32px;
      }
    }
  }

  @include mediaMaxWidth(1000px) {
    .rating_item {
      margin-top: 5px;

      .points {
        font-size: 28px;
      }

      .avatar {
        width: 55px;
        height: 55px;
      }

      .student_info {
        p:first-child {
          font-size: 15px;
        }
      }
    }
  }

  @include mediaMaxWidth(600px) {
    .rating_item {
      .student_item {
        gap: 10px;
      }

      .avatar {
        width: 48px;
        height: 48px;
      }

      .student_info {
        p {
          font-size: 11px;
        }

        p:first-child {
          font-size: 13px;
        }
      }

      .points {
        font-size: 22px;
      }
    }
  }
}


