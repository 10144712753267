@import "../variables";
@import "../mixins";

.company_list {
  font-size: 13px;

  h1 {
    margin-bottom: 30px;
  }

  ul > *:not(:last-child) {
    border-bottom: 1px solid $gray_border;
  }

  .company_item {
    display: flex;
    gap: 20px;
    padding: 20px 0;
    cursor: pointer;
  }

  .company_logo {
    position: relative;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    border-radius: $radius;
    overflow: hidden;
    border: 1px solid $gray_background;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
  }

  .gray_text {
    font-size: 12px;
    color: $gray_text;
  }

  h2 {
    font-size: 18px;
  }

  .description {
    margin-top: 10px;
  }

  @include mediaMaxWidth(600px) {
    .company_item {
      flex-direction: column;
    }
  }

  @include mediaMaxWidth(400px) {
    h2 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }
}
