$icomoon-font-family: "iconfont" !default;

$i-add-circle: "\e914";
$i-add: "\e915";
$i-like: "\e91c";
$i-delete: "\e91d";
$i-rating: "\e90d";
$i-basket: "\e90e";
$i-logo: "\e927";
$i-right_xs: "\e923";
$i-left_xs: "\e924";
$i-up_xs: "\e925";
$i-down_xs: "\e926";
$i-up: "\e91e";
$i-down: "\e91f";
$i-left: "\e920";
$i-right: "\e921";
$i-up_right: "\e922";
$i-vk: "\e900";
$i-email: "\e901";
$i-smile_ok: "\e902";
$i-smile_sad: "\e903";
$i-smile_joy: "\e904";
$i-hidden_menu: "\e905";
$i-sort: "\e906";
$i-auction: "\e907";
$i-rates: "\e908";
$i-person: "\e909";
$i-messenger: "\e90a";
$i-star: "\e90b";
$i-hint: "\e90c";
$i-filter: "\e90f";
$i-file: "\e910";
$i-emoji: "\e911";
$i-add_files: "\e912";
$i-telegram: "\e913";
$i-edit: "\e916";
$i-menu: "\e917";
$i-search: "\e918";
$i-close: "\e919";
$i-download: "\e91a";
$i-upload: "\e91b";
