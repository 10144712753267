.start .header{
    z-index: 3;
    background: white !important;
    color: rgba(51, 51, 51, 1) !important;
    border-color: rgba(51, 51, 51, 1) !important;
    .logo,.exit{
        border-color: rgba(51, 51, 51, 1) !important;
    }
    .logo_icon{
        filter: invert(20%) sepia(100%) saturate(1352%) hue-rotate(0deg) brightness(0%) contrast(119%);

    }
}

.tabsmenu {
    margin: 0 0 32px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 2px solid var(--Grayscale-Border, #CED4DA);
}
.modulsSwiper .swiper-slide{
    overflow: hidden;
}

.tabsmenu li {
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
}

.tabsmenu li.active {font-weight: 500;}

.tabsmenu li.active:after {
    content: "";
    width: 100%;
    height: 3px;
    background: var(--Grayscale-Main-text, #333);
    position: absolute;
    bottom: -2.5px;
    left: 0;
}

/* checkbox */
.checks {
    display: flex;
    align-items: center;
}

.checkbox {display: none;}

.checkbox-custom {
    position: relative;
    min-width: 20px;
    border-radius: 4px;
    border: 2px solid var(--Grayscale-Secondary-text, #ADB5BD);
    width: 22px;
    height: 22px;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
}



.checks .label {
    margin-left: 13px;
    text-align: left;
    font-size: 16px;
}

.modal-link {cursor: pointer;}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-block {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 32px 40px;
    z-index: 1001;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
    gap: 32px;
    font-size: 18px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h3 {
    margin: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.374px;
}

.modal-body p {margin: 0 0 15px;}
.modal-body p:last-child {margin: 0;}
.modal-footer {text-align: right;}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.modal-footer a, .modal-footer button {padding: 14px 32px;}
.modal-footer button {font-weight: 500; cursor: pointer;}
.modal-footer a {text-decoration: none;}


.big-modal {
    max-width: 822px;
}

.big-modal h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

@media (max-width: 991px) {
    .big-modal {max-width: 90%; right: 0; margin: 0 auto;}
    .tabsmenu li {font-size: 14px;}
}

@media (max-width: 767px) {
    .tabsmenu li {font-size: 14px;}
    .modal-block {max-width: 90%; padding: 30px;}
    .modal-block .tab-content .adds {padding: 8px 20px;}
    .modal-block .tab-content .adds span {font-size: 14px;}
    #modal-add .modal-footer a, #modal-add .modal-footer button {padding: 10px 32px;}
    .modal-block .tab-content .adds {padding: 8px 20px;}
    .modal-block .tab-content .adds span {font-size: 14px;}
}



@media (max-width: 374px) {
    .tabsmenu li {font-size: 12px;}
}


.start{
    background: white !important;
}
/*
---------------------------
New styles for Content
---------------------------
*/
.containers {
    width: 100%;
    padding: 0 32px;
}

.btn-white {
    color: var(--Grayscale-Main-text, #333);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    padding: 16px 32px;
    border-radius: 8px;
    border: 2px solid var(--Grayscale-White, #FFF);
    background: var(--Grayscale-White, #FFF);
    text-decoration: none;
}

.btn-white:hover {
    background: transparent;
    border: 2px solid var(--Grayscale-White, #FFF);
    color: var(--Grayscale-White, #FFF);
}

.btn-outline-white {
    color: var(--Grayscale-White, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    padding: 16px 32px;
    border-radius: 8px;
    border: 2px solid var(--Grayscale-White, #FFF);
    text-decoration: none;
}

.btn-outline-white:hover {
    color: var(--Grayscale-Main-text, #333);
    background: var(--Grayscale-White, #FFF);
}

.section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.section-content > div {width: 100%;}

.index-page {
    display: flex;
    flex-direction: column;
    gap: 100px;
    color: var(--Grayscale-Main-text, #333);
    margin: 120px 0 100px;
    @media (max-width: 600px) {
        margin-top: 90px;
    }
}

.index-page p {margin: 0 0 16px;}

.index-page h2 {
    color: var(--Grayscale-Main-text, #333);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Unbounded";
    font-size: 48px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.offer-content {
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    padding: 32px;
    align-items: flex-start;
    color: var(--Grayscale-White, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
    background: url(../assets/images/offerBgnorm.jpg) top center / cover no-repeat;
    position: relative;
}

.offer-content:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(261deg, rgba(0, 0, 0, 0.00) 25.7%, rgba(0, 0, 0, 0.20) 98.74%);
}

.offer-content > div {
    position: relative;
    z-index: 2;
    width: 100%;
}

.offer-title {
    margin-left: auto;
    text-align: left;
}

.offer-title span {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}
.section-content span{
    font-size: 48px;
    font-family: "Unbounded";
    font-weight: 500;
    font-feature-settings:'liga' off, 'clig' off;
    span{
        @media (max-width: 420px) {
            font-size: 40px;
        }
    }
}
.offer-title h1 {
    font-family: "Unbounded";
    font-size: 80px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.offer-title p {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.078px;
    max-width: 415px;
    margin: 0 0 0;
}

.offer-footer {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 12px;
    a,button{
        text-align: center;
    }
}

/* moduls block */
.moduls .tabs {width: auto;}
.moduls .tabsmenu li.active:after {content: none;}

.moduls .tabsmenu {
    border: none;
    gap: 8px;
    margin: 0;
}

.moduls .tabsmenu li {
    padding: 6px 16px;
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.374px;
}

.moduls .tabsmenu li.active {
    background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);
    color: var(--Grayscale-White, #FFF);
}

.modul-blk {
    border-radius: 16px;
    border: 1px solid var(--Grayscale-Main-text, #333);
    display: flex;
    gap: 20px;
    height: 100%;
}

.modul-info {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 33%;
}

.modul-info span.titles {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    max-width: 90%;
    margin-bottom: 24px;
}

.modul-info p:last-child {margin-bottom: 0;}

.modul-descript {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 52px;
}

.modul-descript span {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.078px;
    color: rgba(51, 51, 51, 0.70);
    max-width: 250px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.modul-descript span:before {
    content: "";
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    background: url(../assets/images/baza.svg) center no-repeat, var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #4e8fc4);
}

.modul-descript span.md2:before {background: url(../assets/images/baza2.svg) center no-repeat, var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #4e8fc4);}
.modul-title li{
    list-style: disc;
}
.modul-image {
    width: 67%;
    padding: 55px;
    background: linear-gradient(110deg, rgba(25, 67, 118, 0.80) -10.97%, rgba(17, 124, 193, 0.80) 24.83%, rgba(40, 176, 154, 0.80) 100.62%);
}
.modul-image span{
    width: 100%;
}
.modul-image img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.modul-title ul {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.moduls .swiper-wrapper {align-items: stretch;}

.moduls .swiper-slide {
    height: auto;
    border-radius: 16px;
}

.tab-content > div {position: relative;}

.tab2 .modul-image, .tab3 .modul-image {
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.tab2 .modul-image img, .tab3 .modul-image img {margin-bottom: -20px;}

.modul-controls {
    position: absolute;
    bottom: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 29%;
}

.index-page .swiper-pagination {
    position: relative;
    display: flex;
    width: auto;
    gap: 4px;
    bottom: 0;
}

.index-page .swiper-pagination-bullet {
    margin: 0 !important;
    width: 12px;
    height: 12px;
    border: 1px solid #333333;
    background: none;
}

.index-page .swiper-pagination-bullet-active {background: #333333;}

.index-page .swiper-button-prev,
.index-page .swiper-button-next {
    width: auto;
    height: auto;
    position: relative;
    margin: 0;
    top: 0;
    right: 0;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    align-items: center;
    gap: 8px;
}

.index-page .swiper-button-next:after, .index-page .swiper-button-prev:before {
    content: "";
    background: url(../assets/images/modul-arrow.svg) center no-repeat;
    width: 16px;
    height: 16px;
}

.index-page .swiper-button-prev:before {transform: rotate(180deg);}
.index-page .swiper-button-prev:after {content: none;}

/* partner-block */
.partner-block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .partner-blk{
        max-height: 130px;
        img{
            filter: invert(20%) sepia(100%) saturate(1352%) hue-rotate(0deg) brightness(0%) contrast(119%);
            height: 100%;
            max-width: 50%;
            @media (max-width: 1000px) {
                max-width: 70%;
            }
        }
    }
}

.partner-blk {
    width: calc(33.3333% - 14px);
    border-radius: 16px;
    border: 1px solid var(--Grayscale-Main-text, #333);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 150px;
}

.partner-blk img {max-width: 100%;}

.show-more-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 0 auto;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--Grayscale-Main-text, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
}

.show-more-btn:after {
    content: "";
    background: url(../assets/images/modul-arrow.svg) no-repeat;
    width: 8px;
    height: 13px;
    transform: rotate(90deg);
}

.show-more-btn:hover {
    text-decoration: underline;
}

.btn-grdnt {
    border-radius: 8px;
    background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);
    color: var(--Grayscale-White, #FFF);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 16px 32px;
    text-decoration: none;
}

.btn-grdnt:hover {background: rgba(17, 124, 193, 0.80);}

.info-block h2 span {display: block;}

.info-block h2 span span {
    display: inline;
    background: linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}


/* styles for review */
.review-blk {
    border-radius: 16px;
    border: 1px solid var(--Grayscale-Main-text, #333);
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.review-header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.review-header img {
    width: 88px;
    height: 88px;
    object-fit: cover;
    align-self: flex-start;
}

.review-header span {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
}

.swiper-btn {margin-top: 12px;}

.reviews .swiper-slide {height: auto;}




.footer-block {
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    color: var(--Grayscale-White, #FFF);
    font-feature-settings: 'liga' off, 'clig' off;
}

.footer-block .flex {
    width: 100%;
    gap: 20px;
    align-items: stretch;
}

.fblk {
    border-radius: 16px;
    background: var(--Grayscale-Main-text, #333);
    padding: 32px;
}

.fnav a {
    color: var(--Grayscale-White, #FFF);
    font-family: "Unbounded";
    font-size: 20px;
    text-decoration: none;
}

.fnav ul, .ffaq ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 0;
}

.fnav ul li {width: 50%;}

.fnav {width: 32%;}

.ffaq {
    width: 67%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ffaq span {
    display: block;
    color: var(--Grayscale-White, #FFF);
    font-family: "Unbounded";
    font-size: 36px;
    font-weight: 500;
}

.ffaq ul {gap: 8px;}
.ffaq ul li {width: auto;}

.ffaq ul li a {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flogo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.flogo img {width: 100%;}

.flogo span, .flogo a {
    color: var(--Grayscale-Secondary-text, #ADB5BD);
    font-size: 12px;
    letter-spacing: -0.078px;
    text-decoration: none;
}



/* question modal */
.form-flex {
    display: flex;
}

.question-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 32px;
    flex: 1;
}

.question-title h2 {
    font-family: "Unbounded";
    font-size: 48px;
    font-weight: 500;
    margin: 0;
}

.question-title img {
    max-width: 100%;
    margin-top: auto;
}

.question-form {
    background: linear-gradient(110deg, rgba(25, 67, 118, 0.80) -10.97%, rgba(17, 124, 193, 0.80) 24.83%, rgba(40, 176, 154, 0.80) 100.62%);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1;
    border-radius: 16px;
}

.question-form form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--Grayscale-White, #FFF);
}

.question-form form label {
    font-weight: 500;
    letter-spacing: 0.374px;
    display: block;
}

.big-modal2 {
    width: 90%;
    max-width: 1200px;
    padding: 0;
    height: auto;
    overflow: auto;
    max-height: 90%;
}

.invalid-feedback {
    display: none;
    color: var(--Acents-Functional-Error, #BD4567);
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.question-form input, .question-form textarea {
    border-radius: 8px;
    background: var(--Grayscale-Bg, #E9ECEF);
    padding: 14px 16px;
    width: 100%;
    border: none;
}

.question-form input.is-invalid {
    background: var(--Acents-Functional-Error-Secondary, #F5D2DC);
}

.question-form input.is-invalid::-webkit-input-placeholder { color: #BD4567;}
.question-form input.is-invalid:-moz-placeholder { color: #BD4567;}
.question-form input.is-invalid::-moz-placeholder { color: #BD4567;}
.question-form input.is-invalid:-ms-input-placeholder { color: #BD4567;}

.question-form textarea {
    resize: none;
    height: 165px;
}

.question-form .checks .label a {color: #fff;}
.question-form form label.checks {display: flex;}

.question-form .checkbox-custom {
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    min-width: 22px;
}

.question-form .checkbox:checked + .checkbox-custom::before {
    background: url(../assets/images/modal-check.svg) center no-repeat;
    background-size: 13px;
}

.big-modal2 .btn-close {
    filter: brightness(0) invert(1);
    margin-left: auto;
}

.question-form h2 {
    color: var(--Grayscale-White, #FFF);
    font-family: "Unbounded";
    font-size: 48px;
    font-weight: 500;
    margin: 0;
}

.question-form p {
    color: var(--Grayscale-White, #FFF);
    line-height: 1.2;
    letter-spacing: -0.078px;
    max-width: 70%;
}

#sent .form-flex {min-height: 626px;}

#sent .question-title {
    align-items: center;
    justify-content: center;
}

#sent .question-title img {margin: auto;}

.question-form ul {
    list-style: none;
    padding: 0;
    margin: auto 0 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

body.lock {overflow: hidden;}

.form-modal-btn .btn-close {
    border-radius: 8px;
    border: 2px solid var(--Grayscale-Bg, #E9ECEF);
    background: var(--Grayscale-Bg, #E9ECEF);
    filter: none;
    width: 100%;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    display: none;
}

.form-modal-btn button {width: 100%;}
#sent h2.mtl, #sent .modal-body a.btn-blue  {display: none;}
.qf-icon2 {display: none;}


/*
--------------------
Styles for Adaptive
--------------------
*/
@media (max-width: 1199px) {
    .offer-content {min-height: 450px;}
    .modul-info span.titles {max-width: 100%;}
    .fnav {width: 38%;}
    .ffaq {width: 60%;}
}

@media (max-width: 991px) {
    .offer-title h1 {font-size: 50px;}
    .offer-content {min-height: 400px; background-position: top left;}
    .moduls .tabs .flex {flex-direction: column; align-items: flex-start; gap: 24px;}
    .modul-blk {flex-direction: column; gap: 24px; border: none; color: var(--Grayscale-White, #FFF); background: var(--Gradients-Gradient-Web, linear-gradient(90deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF);}
    .modul-info {width: 100%;}
    .modul-info span.titles {margin-bottom: 12px;}
    .modul-descript span {color: rgba(255,255,255,0.7);}
    .modul-descript {margin-bottom: 0; padding: 32px 0px 0px;}
    .modul-image {width: 100%; background: none; padding: 0 32px; margin: 0 0;}
    .modul-image img {width: 100%;}
    .moduls .swiper-slide {height: auto;}
    .modul-blk {padding-bottom: 50px;}
    .modul-controls {width: 100%; padding: 0 32px; left: 0;}
    .moduls .swiper-pagination, .moduls .swiper-button-next {filter: brightness(0) invert(1);}
    .index-page h2 {font-size: 36px;}
    .index-page {gap: 56px;}
    .index-page {margin-bottom: 56px;}
    .footer-block > .flex {flex-direction: column; gap: 8px;}
    .footer-block > .flex > div {width: 100%;}
    .footer-block {gap: 0;}
    .ffaq {order: 1;}
    .fnav {order: 2; border-radius: 16px 16px 0 0;}
    .footer-block .flex {align-items: center;}
    .flogo {border-radius: 0 0 16px 16px; margin-top: -1px;}
    .tab2 .modul-image img, .tab3 .modul-image img {margin-bottom: 0;}
    .section-content > div {gap: 12px;}
    .partner-blk {width: calc(33.3333% - 8px);}
    .review-blk {padding: 16px;}
    .question-title h2 {font-size: 30px;}
    .form-flex {font-size: 14px;}
    .form-flex .checks .label {font-size: 9px;}
    .question-form h2 {font-size: 30px;}
    #sent .form-flex {min-height: auto;}
}

@media (max-width: 767px) {
    .offer-content {padding: 16px;}
    .offer-title span {font-size: 18px;}
    .offer-title h1 {font-size: 30px;}
    .offer-title p {font-size: 14px; max-width: 70%;}
    .btn-white, .btn-outline-white {font-size: 16px; padding: 14px 20px;}
    .offer-content {min-height: 300px;}
    .modul-info, .modul-image {padding: 16px;}
    .modul-blk {padding-bottom: 40px; gap: 0;}
    .modul-controls {bottom: 16px;}
    .modul-controls {padding: 0 16px;}
    .index-page .swiper-button-prev, .index-page .swiper-button-next {font-size: 12px;}
    .tab2 .modul-image img, .tab3 .modul-image img {margin-bottom: 10px;}
    .index-page h2, .ffaq span {font-size: 30px;}
    .containers {padding: 0 12px;}
    .fblk {padding: 16px;}
    .fnav a {font-size: 12px;}
    .footer-block {padding-bottom: 16px;}
    .modul-descript {padding: 16px 0px 6px;}
    .index-page {font-size: 14px;}
    .modul-info span.titles {font-size: 18px;}
    .partner-blk {width: calc(50% - 6px);}
    .form-modal-btn {display: flex; flex-direction: column; gap: 10px;}
    .form-modal-btn .btn-close {display: block;}
    .question-title, .question-form {padding: 16px;}
    .question-form {gap: 16px;}
    #sent .question-title img {max-width: 200px;}
    .question-form h2 {font-size: 20px;}
}

@media (max-width: 575px) {
    .moduls .tabsmenu li {padding: 6px 8px;}
    .offer-content {min-height: 550px; background: url(../assets/images/offer-bg-sm.jpg) top center / cover no-repeat; gap: 24px;}
    .offer-title {text-align: left; flex: 1; display: flex; flex-direction: column;}
    .offer-footer {flex-direction: column; gap: 8px;}
    .offer-footer > * {width: 100%;}
    .offer-title p {max-width: 100%; margin-top: auto;}
    .partner-blk {min-height: 0; height: 100px; padding: 30px 10px; display: block;}
    .partner-blk img {height: 100%; width: 100%; object-fit: contain; max-width: none;}
    .ffaq ul {position: absolute; top: 16px; right: 16px;}
    .ffaq {position: relative;}
    .ffaq span {padding-right: 70px;}
    .ffaq .flex, .ffaq button {width: 100%;}
    .flogo span, .flogo a {font-size: 10px;}
    .form-flex {flex-direction: column;}
    .big-modal2 {width: 100%; gap: 0; background: var(--Gradients-Gradient-Web, linear-gradient(270deg, rgba(25, 67, 118, 0.80) -6.99%, rgba(17, 124, 193, 0.80) 27.27%, rgba(40, 176, 154, 0.80) 99.79%), #FFF); height: 100%; max-height: 100%; top: 0; border-radius: 0;}
    .question-form {background: none; gap: 12px;}
    .question-form h2, .question-form p {color: #333333; max-width: 100%;}
    .question-form h2 {font-size: 24px; font-family: "Ubuntu";}
    .big-modal2 .modal-body {padding: 0; border-radius: 24px 24px 0px 0px; background: #ffffff;}
    .question-form form {color: #333333;}
    .question-form form .checks .label, .question-form form .checks .label a {color: #ADB5BD;}
    .question-form .checkbox-custom {border-radius: 4px; border: 2px solid var(--Acents-Main-acent, #49A9BF);  background: var(--Acents-Main-acent, #49A9BF);}
    .form-modal-btn .btn-white {border: 2px solid var(--Acents-Main-acent, #49A9BF); color: #fff;; background: var(--Acents-Main-acent, #49A9BF);}
    #sent .modal-body {padding: 24px 20px; height: calc(100% - 62px); display: flex; flex-direction: column;}
    #sent .form-flex {min-height: auto;}
    .question-title {gap: 0; padding-bottom: 0;}
    .question-title img {display: none;}
    #sent .question-title img {display: block;}
    #sent .modal-body a.btn-blue {margin-top: auto; text-align: center; text-decoration: none; display: block; text-decoration: none;}
    .big-modal2 h2.mtl {display: flex; padding: 0 24px; align-items: center; color: #fff; gap: 10px; font-size: 18px; font-weight: 700;}
    .big-modal2 h2.mtl:before {content: ""; background: url(../assets/images/back-arrow.svg) center no-repeat; width: 32px; height: 32px; filter: brightness(0) invert(1);}
    #sent h2.mtl {display: flex;}
    #sent .question-title {padding: 0;}
    #sent .question-form h2 {color: #333333;}
    .qf-icon1 {display: none;}
    .qf-icon2 {display: block;}
    .question-form ul li a {width: 48px; height: 48px; background: #49A9BF; display: flex; align-items: center; justify-content: center; border-radius: 8px;}
}

@media (max-width: 374px) {
    .moduls .tabsmenu {flex-wrap: wrap;}
    .partner-blk {padding: 20px;}
    .index-page h2, .ffaq span {font-size: 26px;}
    .flogo span, .flogo a {font-size: 9px;}
}
.formModal{
    .question-form{
        position: relative;
        .btn-close {
            background: url(./images/modal-close.svg) center / contain no-repeat;
            z-index: 3;
            filter: invert(100%) sepia(0%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
            right: -95%;
            position: relative;
            width: 40px;
            height: 41px;
            padding: 0;
            cursor: pointer;
        }
    }

    .modal_title{
        display: none !important;
    }
    .MuiPaper-root{
        border-radius: 20px !important;
    }
    .form-flex{
    }
    .modal_content{
        border-radius: 12px !important;
        padding: 0 !important;
    }
}
.modulsSwiper .swiper-initialized{
    border-radius: 16px !important;
    overflow: hidden;
}
.modulsSwiper img{
    max-height: 440px;
}
.modul-image img{
}
.reviewsSwiper{
    .swiper-button-prev,.swiper-button-next{
        visibility: hidden !important;
    }
    @media (max-width: 1199px) {
        .swiper-button-prev,.swiper-button-next{
            visibility: visible !important;
        }
    }

}