$white: #FFFFFF;
$bubble_border: #0b5dc4;
$bubble_back:#fff;
$dark_gray: #333333;
$dark_field_gray: #555555;
$gray_border: #CED4DA;
$gray_background: #E9ECEF;
$gray_text: #ADB5BD;
$turquoise: #49A9BF;
$turquoise_hover: #31889b;
$turquoise_transparent: rgba(73, 169, 191, 0.5);
$light_gray: #EFEFEF;
$green: #41B695;
$radius: 8px;

