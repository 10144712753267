@import "../variables";
@import "../mixins";

.profile_company {
  @include tooltip;

  .profile_company_content {
    display: grid;
    /*TODO тут после polyexchange (вместо него) для возврата магазина добавить три раза shop*/
    grid-template-areas:
          "rate rate contact_person contact_person contact_person contact_person profile_card profile_card profile_card"
          /*team*/"polyexchange polyexchange polyexchange polyexchange polyexchange polyexchange profile_card profile_card profile_card"
          "shop shop shop shop shop shop profile_card profile_card profile_card"
          "events events events competencies competencies competencies profile_card profile_card profile_card";
    @media screen and (max-width: 800px) {

    }
    grid-template-columns: repeat(9, 1fr);
    gap: 24px;
    font-size: 12px;

    & > .grid_item:not(.profile_card) {
      padding: 16px 25px;
    }

    @media (max-width: 800px) {
      /*display: flex;
      flex-direction: column-reverse;*/
      grid-template-columns: repeat(1, 100%);
      grid-template-areas:
          "profile_card"
          "rate"
          "polyexchange"
          "contact_person"
          "contact_person"
          ;
    }
  }

  .profile_company_content_for_student {
    display: grid;
    /*TODO тут после polyexchange (вместо него) для возврата магазина добавить три раза shop*/
    grid-template-areas:
          "polyexchange polyexchange polyexchange polyexchange polyexchange polyexchange profile_card profile_card profile_card"
          "shop shop shop shop shop shop profile_card profile_card profile_card"
          ". . . . . . profile_card profile_card profile_card"
          ". . . . . . profile_card profile_card profile_card";
    grid-template-columns: repeat(9, 1fr);
    gap: 24px;
    font-size: 12px;

    & > .grid_item:not(.profile_card) {
      padding: 16px 25px;
    }

    @media (max-width: 900px) {
      display: block;
    }
  }

  .rate {
    user-select: none;
    grid-area: rate;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $dark_gray;
    color: $white;

    @media (max-width: 800px) {
      margin-bottom: 10px;
    }

    .tooltip_title{
      cursor: auto !important;
    }
    .rate_name {
      text-align: center;
      font-size: 2vmax;
      font-weight: 700;
      text-transform: uppercase;
      margin: 30px 0;
      align-self: center;
      @media (max-width: 800px) {
        font-size: 42px;
      }
    }

    .rate_term {
      color: $gray_text;
      margin-right: 20px;
    }
  }

  .team {
    grid-area: team;
    .tooltip_title{
      cursor: auto !important;
    }
    .students_team {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 20px 0;
    }

    .student {
      cursor: pointer;
      position: relative;
      width: 88px;
      height: 88px;
      border-radius: $radius;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .need_feedback {
        position: absolute;
        right: 3px;
        top: 3px;
      }
    }

    .feedback_info {
      font-size: 14px;
    }

    .need_feedback {
      color: $turquoise;
      font-size: 0.8em;
      margin-right: 5px;
    }

    .card_button {
      background-color: $turquoise;
      color: $white;
    }
  }

  .competencies {
    grid-area: competencies;
  }

  .shop {
    grid-area: shop;
  }

  .contact_person {
    grid-area: contact_person;
    font-size: 14px;

    p {
      margin: 0;
    }

    .person_info {
      display: flex;
      flex-direction: column;
      line-height: 1.2;
    }

    .name {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 700;
    }

    .profession {
      margin-top: 8px;
      color: $gray_text;
    }

    .phone {
      margin-top: 16px;
    }

    .email {
      margin-top: 4px;
    }
  }

  .events {
    grid-area: events;
  }

  .polyexchange {
    grid-area: polyexchange;
  }

  .list_block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .list_items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  .list_item {
    display: flex;
    gap: 16px;

    .image {
      flex-shrink: 0;
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: $radius;
      overflow: hidden;
      background-color: $gray_background;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .list_info {
      font-size: 12px;
      width: 100%;
      .vacancy-info{
       /* display: flex;
        flex-direction: row;
        justify-content: space-between;*/
        width: 100%;
      }

      .name {
        font-weight: 700;
        margin-bottom: 4px;
      }

      .description {
        display: none;
      }

      .status {
        padding: 4px 12px 4px 0 !important;
        border-radius: $radius;
        color: $gray_text;
        font-weight: 500;
        font-size: inherit;
        /*background-color: $gray_background;*/
      }
    }
  }

  .show_all {
    margin-top: auto;
    background-color: $turquoise;
    color: $white;
    padding: 8px 16px;
    border-radius: $radius;
    font-weight: 700;
    width: 100%;
  }

  .profile_card {
    grid-area: profile_card;
    padding: 24px;

    p {
      margin: 0;
    }

    a {
      display: inline-block;
    }

    .subtitle {
      font-weight: 700;
      margin-top: 25px;
    }

    .activity {
      margin-top: 32px;
      margin-bottom: 4px;
      color: $gray_text;
    }

    .full_name {
      margin-top: 8px;
      font-size: 11px;
      text-wrap: pretty;
    }

    .to_page{
      display: contents;
    }
    .company_name{
      margin-bottom: 25px;
    }

    .inn {
      margin-top: 4px;
      font-size: 11px;
      color: $gray_text;
      text-transform: uppercase;
    }

    .professions, .description {
      margin-top: 9px;
    }

    .main_button {
      margin-top: 24px;
      width: 100%;
    }
  }
}
