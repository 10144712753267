@import "../variables";
@import "../mixins";

.team_company {
  padding: 15px;

  .tab_list {
    font-size: 14px;

    @include mediaMaxHeightAndPortrait(900px) {
      li {
        padding-right: 0;
      }
    }
  }

  .tab_content {
    margin-top: 15px;
  }

  .my_team, .applications, .outgoings {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .student {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 270px;
    padding: 10px;

    .student_info {
      display: flex;
      gap: 10px;

      .user_picture {
        position: relative;
        width: 88px;
        height: 88px;
        min-width: 88px;
        min-height: 88px;
        border-radius: $radius;
        overflow: hidden;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.2;
          margin: 0;
        }

        .additional {
          -webkit-box-orient: vertical;
          font-weight: 400;
          color: $gray_text;
          margin-top: 5px;
          -webkit-line-clamp: 4;
        }
      }

      .menu_actions {
        margin-left: auto;
      }
    }

    .submit_button {
      width: 100%;
      margin-top: 10px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 12px;

      background-color: $white;
      color: $dark_gray;

      border: 1px solid green;

      &:hover {
        opacity: 0.5;
      }

      &.active {
        color: $turquoise;
        border: 1px solid $turquoise;
      }
    }
  }
  .empty {
    font-size: 14px;
  }
}
