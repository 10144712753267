@import "../variables";
@import "../mixins";

.shop {
  .buttons_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 20px 0;

    .bubble {
      padding: 8px 16px;
    }

    .filter_button {
      font-size: 20px;
    }
  }

  .shop_list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .product_element {
      width: calc(100% / 3 - 11px);
    }

    @include mediaMaxWidth(950px) {
      .product_element {
        width: calc(100% / 2 - 8px);
      }
    }

    @include mediaMaxWidth(600px) {
      .product_element {
        width: 100%;
      }
    }

    .empty {
      min-height: 200px;
    }
  }

  .product_element {
    padding: 16px 24px;
    width: calc(100% / 2 - 8px);

    & > *:not(:last-child) {
      margin-bottom: 12px;
    }

    .product_title {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      line-height: 1;
    }

    .image {
      width: 100%;
      position: relative;
      border-radius: $radius;
      overflow: hidden;
      background-color: $gray_background;
      border: 1px solid $gray_background;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @include mediaMaxWidth(600px) {
    .product_element {
      width: 100%;
    }
  }
}
