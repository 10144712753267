@import "../variables";
@import "../mixins";

.login_form {
  @include tooltip;

  width: 100%;
  height: 100%;

  .login_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding: 64px;

    .login_form {
      display: flex;
      flex-direction: column;
      font-size: 15px;
    }

    h1 {
      font-family: "Unbounded", sans-serif;
      font-size: 36px;
      line-height: 1.25;
      margin: 64px 0;
      text-transform: uppercase;

      .i-hint {
        font-size: 0.7em;
        margin-left: 10px;
      }
    }

    label {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;

      margin-bottom: 8px;
    }

    .field {
      display: flex;
      flex-direction: column;
    }

    .field:not(:last-child) {
      margin-bottom: 32px;
    }

    .submit_button {
      height: 52px;
      font-family: inherit;
      font-size: 17px;
      font-weight: 700;
      color: $white;
      border-radius: 8px;
      background-color: $turquoise;
      margin-top: 48px;
      cursor: pointer;
      transition: 0.2s all ease;

      &:hover {
        color: $dark_gray;
        border: 1px solid $dark_gray;
        background-color: $white;
      }

      &:disabled {
        opacity: 0.4;
      }
    }

    .bottom_links {
      font-size: 13px;
      text-align: center;
      margin-top: 20px;
      color: $gray_text;

      p {
        margin: 0;
      }

      p:not(:last-child) {
        margin-bottom: 8px;
      }

      .policy_link {
        display: inline-block;
        position: relative;
        border-bottom: 1px solid $gray_text;
        color: inherit;

        span {
          font-size: 0.7em;
          margin-left: 5px;
        }
      }
    }

    &.light {
      color: $dark_gray;
      background-color: $white;
    }

    &.dark {
      color: $white;
      background-color: $dark_gray;

      label {
        color: $white;
      }

      .form_field {
        background-color: $dark_field_gray;
        color: $gray_text;
        border-bottom-color: $white;
      }

      .submit_button {
        background-color: $white;
        color: $dark_gray;

        &:hover {
          background-color: $dark_gray;
          color: $white;
          border-color: $white;
        }
      }

      .bottom_links .policy {
        border-color: $white;

        &::after {
          background-position: -9em 0;
        }
      }
    }
  }

  @include mediaMaxWidth(1350px) {
    .login_block {
      h1 {
        font-size: 30px;
      }
    }
  }

  @include mediaMaxWidth(1150px) {
    .login_block {
      padding: 45px;

      h1 {
        font-size: 24px;
      }
    }
  }

  @include mediaMaxWidth(900px) {
    .login_block {
      h1 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 32px;
      }

      .field:not(:last-child) {
        margin-bottom: 16px;
      }

      .submit_button {
        margin-top: 30px;
      }

      .bottom_links {
        display: none;
      }
    }
  }

  @include mediaMaxWidthAndPortrait(900px) {
    .login_block {
      h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
  }

  @include mediaMaxWidth(600px) {
    .login_block {
      h1 {
        font-size: 25px;
      }

      .submit_button {
        font-size: 15px;
        height: 40px;
      }
    }
  }

  @include mediaMaxWidth(500px) {
    .login_block {
      h1 {
        font-size: 20px;
      }
    }
  }
}


