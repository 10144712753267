@import "../variables";

.bar_chart {
  width: 100%;
  .bar_item_with_label{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
  }
  .bar_item:not(:last-child) {
    margin-bottom: 25px;
  }

  .bar_label {
    font-weight: 500;
  }

  .bar_body {
    position: relative;
    min-width: min-content;
    margin-top: 8px;
    background-color: $dark_gray;
    color: $white;
    font-weight: 700;
    padding: 4px 8px;
    border-radius: 16px;

    &::after {
      position: absolute;
      content: attr(data-width);
      right: -30px;
      top: -5px;
    }
  }
}
