@import "../variables";
@import "../mixins";

.show_feedback {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  .feedback_button{
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .shadow_block {
    padding: 16px 24px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
    &:hover{
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1)
    }
  }

  @include mediaMaxHeightAndPortrait(900px) {
    padding: 10px;

    .shadow_block {
      width: 100%;
    }
  }

  .short_feedback {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-top: 16px;

    .mark {
      font-size: 24px;
      font-weight: 700;
    }

    .icon_mark {
      display: inline-block;
      line-height: 1;
      background-color: $turquoise;
      color: $white;
      padding: 10px;
      border-radius: $radius;
      margin-right: 10px;

    }

  }

  .show_full_feedback {
    display: block;
    margin-top: 16px;
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    span {
      font-size: 0.7em;
      margin-left: 4px;
    }

  }
}

.feedback_full {
  .list {
    font-size: 14px;

    td {
      font-weight: 700;
    }
  }

  .icon_mark {
    display: inline-block;
    line-height: 1;
    background-color: $turquoise;
    color: $white;
    padding: 10px;
    border-radius: $radius;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    &.inactive{
      opacity: .5;
    }
    &::before {
      font-size: 1.8em;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
