@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.page_from_admin {
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .loaders {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .load_block {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #DDDDDD;
    border-radius: 24px;
    padding: 32px;

    h2 {
      font-size: 18px;
      margin-top: 0;
    }
  }

  .load_form > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .add_button {
    @include pseudoIcon(block, 32px, 32px, url("../../images/icons.svg"), cover, -320px 0);
    cursor: pointer;
  }

  .control_button {
    @include pseudoIcon(inline-block, 25px, 25px, url("../../images/icons.svg"), cover, 0);
    cursor: pointer;
  }

  .buttons {
    white-space: nowrap;
  }

  .edit {
    background-position: -200px 0;
  }

  .delete {
    background-position: -225px 0;
  }

  .object_info {
    display: grid;
    gap: 15px;
    margin-top: 20px;

    .field {
      min-width: 200px;
    }

    &.two_fraction {
      grid-template-columns: repeat(2, 1fr);
    }

    &.three_fraction {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .button_block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}
